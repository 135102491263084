import React, { useEffect, useState } from 'react';
import { VideoConference } from '../../video_conference/VideoConference';
import { Button} from 'react-admin';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import * as apiClient from "../../common/apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";

export const VC = (props: any) => {
    let history = useHistory();
    let { id } = useParams();
    const [meeting, setMeeting] = useState<any>({});

    const getMeetingSchedule = () => {
        apiClient.getMeetingSchedule(id).then(res => {
            console.log(res);
            if(res.status == "success"){
                setMeeting(res.result);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    useEffect(() => {
        getMeetingSchedule();
    }, []);
    let display_name = localStorage.getItem("displayName");
    const StopMeeting = () => {
        apiClient.stopMeeting(id).then(res => {
            if(res.status == "success"){
                if(res.result == "stopped"){
                    history.push("/meeting_schedule");
                }
            }else{
                alert("Please relogin in the application.");
            }
        }).catch(err => {
            console.log(err);
        }); 
    }
    const role = localStorage.getItem('role');
    return (
        <div className="video-conference-parent">
            {meeting.guid && meeting.room_code && <VideoConference 
            guid={meeting.guid} role={role}
            display_name={display_name} room_code={meeting.room_code} /> }
        </div>
    )
}