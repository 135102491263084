import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import { MyDataProvider } from "./providers/MyDataProvider";
import VSDataProvider from "./providers/VSDataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { MainHome } from "./layout/Home";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import roletypes from './screens/roletypes';
import country from "./screens/country";
import state from "./screens/state";
import city from "./screens/city";
import currency from "./screens/currency";
import timezone from "./screens/timezone";
import StatusGroup from "./screens/statusgroup";
import serviceCategory from "./screens/service-category";
import areas from './screens/areas';
import users from './screens/users';
import employee from './screens/employee';
import tpa from './screens/tpa';
import insurer from './screens/insurer';
import corporate from './screens/corporate';
import hospital from './screens/hospital';
import realizeorder from './screens/realize-order';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';
import uoms from "./screens/uoms";
import TaxHeads from "./screens/taxhead";
import usertype from "./screens/usertype";
import account from "./screens/account";
import groups from "./screens/group";
import Industry from "./screens/industries";
import industries from "./screens/industries";
import faqs from "./screens/faqs";
import profile from './screens/profile';
import AppPartner from "./screens/app-partners";
import Partners from "./screens/partners";
import AdvisoryPanel from "./screens/advisory-panel";
import Joborder from "./screens/joborder";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource name="countries" {...country} />
        <Resource name="states" {...state} />
        <Resource name="cities" {...city} />
        <Resource name="currency" {...currency} />
        <Resource name="TimeZones"  {...timezone} />
        <Resource name="service-categories" {...serviceCategory} />
        <Resource name="uoms"  {...uoms} />
        <Resource name="status-groups"  {...StatusGroup} />
        <Resource name="tax-heads"  {...TaxHeads} />
        <Resource name="UserTypes"  {...usertype} />
        <Resource name="account-key-members" list={ListGuesser} edit={EditGuesser} />
        <Resource name="accounts" {...account} />
        <Resource name="profileupdate" {...profile} />
        <Resource name="groups" list={ListGuesser} edit={EditGuesser} />
        <Resource name="service-categories" list={ListGuesser} edit={EditGuesser} />
        <Resource name="account-image-maps" list={ListGuesser} edit={EditGuesser} />
        <Resource name="content-page-configs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="faq-dets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="faqs"  {...faqs} />
        <Resource name="industries" {...industries} />
        <Resource name="news-settings" list={ListGuesser} edit={EditGuesser} />
        <Resource name="partners" {...Partners} />
        <Resource name="app-partners" {...AppPartner} />
        <Resource name="advisory-panels" {...AdvisoryPanel} />
        <Resource name="service-request-headers" list={ListGuesser} edit={EditGuesser} />
        <Resource name="subscription-features" list={ListGuesser} edit={EditGuesser} />
        <Resource name="subscription-prices" list={ListGuesser} edit={EditGuesser} />
        <Resource name="subscriptions" list={ListGuesser} edit={EditGuesser} />
        <Resource name="testi-monials" list={ListGuesser} edit={EditGuesser} />
        <Resource title="Service Provider Categories map" name="account-servicecat-maps" list={ListGuesser} edit={EditGuesser} />
        <Resource title="Area" name="areas" {...areas} />
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        <Resource title="TPA" name="tpas" {...tpa} />
        <Resource title="Insurer" name="insurers" {...insurer} />
        <Resource title="Corporate" name="corporates" {...corporate} />
        <Resource title="Hospital" name="hospitals" {...hospital} />
        <Resource title="Reliaze order" name="realiz-ords"   {...realizeorder} />
        <Resource name="lookups" list={ListGuesser} edit={EditGuesser} />
        <Resource name="realiz-ord-dets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="realiz-ord-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource title="RoleTypes" name="roletypes" {...roletypes} />
        <Resource title="Hospital" name="hospital-dets" {...roletypes} />
        <Resource name="hospital-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="account-industries" list={ListGuesser} edit={EditGuesser} />
        <Resource name="account-cust-locations" list={ListGuesser} edit={EditGuesser} />
        <Resource name="account-clients" list={ListGuesser} edit={EditGuesser} />   
        <Resource name="configs" {...configs} />
        <Resource name="account-image-maps" list={ListGuesser} edit={EditGuesser} />   
        <Resource name="account-office-addresses" list={ListGuesser} edit={EditGuesser} />   
        <Resource name="account-recognitions" list={ListGuesser} edit={EditGuesser} />   
        <Resource name="account-rating-maps" list={ListGuesser} edit={EditGuesser} />   
        <Resource name="account-primary-segments" list={ListGuesser} edit={EditGuesser} />   
        <Resource name="joborders" {...Joborder} />   
        <Resource name="job-order-details" list={ListGuesser} edit={EditGuesser} />   
        
        
        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;
