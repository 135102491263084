import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    full_inline_input:{
        display:'inline-block',
        width:'100%',
        
    }, 
    first_inline_input:{
        display:'inline-block',
        width:'48%'
    }, 
    last_inline_input: {
        display:'inline-block',
        marginLeft: '4%',
        width:'48%'
    },
    one_three_input:{
        display:'inline-block',
        width:'30%'
    },
    two_three_input:{
        display:'inline-block',
        marginLeft: '5%',
        width:'30%'
    },
    last_three_input:{
        display:'inline-block',
        marginLeft: '5%',
        width:'30%'
    },
     
    start_twice_4_input:{
        display:'inline-block',
        marginRight: '1%',
        width:'48%'
    },
    start_thrice_4_input:{
        display:'inline-block',
        marginRight: '1%',
        width:'73%'
    },
    one_4_input:{
        display:'inline-block',
        width:'24%'
    },
    two_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'24%'
    },
    twice_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'49%'
    },
    thrice_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'75%'
    },
    three_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'24%'
    },
    last_4_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'24%'
    },
    richtexteditor_input:
    {
        
        height:'223%!important'
    },
    one_four_input:{
        display:'inline-block',
        width:'30%',
        marginLeft: '5%',
    },
    two_four_input:{
        display:'inline-block',
        marginLeft: '500%',
        width:'30%'
    },
    three_four_input:{
        display:'inline-block',
        marginLeft: '5%',
        width:'30%'
    },
    last_four_input:{
        display:'inline-block',
        marginLeft: '5%',
        width:'30%'
    },
    one_5_input:{
        display:'inline-block',
        width:'19%'
    },
    two_5_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'19%'
    },
    three_5_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'19%'
    },
    four_5_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'19%'
    },
    last_5_input:{
        display:'inline-block',
        marginLeft: '1%',
        width:'19%'
    },
}));