import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    FormDataConsumer,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { LocationAddressfields } from './LocationAddressfields';


export const AccountCustomerLocation = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    const handleSubmit = async (formdata) => {
        let data = { 
            //id:0,
            accountId: parseInt(props.accountid),
            
            countryId: formdata.countryId,
            stateId:  formdata.stateId,
            cityId: formdata.cityId,
            zipCode: formdata.pincode,
            insertedBy: 0,
            insertedAt: "2021-12-19T08:57:11.110Z",
            updatedBy: 0,
            updatedAt: "2021-12-19T08:57:11.110Z",
            isActive: true,
            isDefault: true
          }
          
        apiClient.addEntiyRecord("account-cust-locations",data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

        
    }
    return (
        <div>
        
        
         {<Create {...props}  resource="account-industries" redirect="false">
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <FormDataConsumer  >
              {formDataProps => (
                <LocationAddressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput source="pincode" label="Pincode" validate={[required()]}  fullWidth={true}/> 
            
            </SimpleForm>
        </Create>
        
            }
            </div>
    );
}
