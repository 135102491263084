import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, SearchInput,EditButton } from 'react-admin';

const MyFilters = props => (
    <Filter {...props} variant="outlined" >
        <SearchInput label="Name" source="name" alwaysOn />
        
    </Filter>
);

export const EmployeeList = props => {
    return (
        <React.Fragment>
            <List title="List of Employees"
                {...props} filters={<MyFilters />}>
                <Datagrid rowClick="edit">
                    <EditButton />
                    <TextField source="code" label="Code" />
                    <TextField source="name" label="Employee Name" />
                    <BooleanField source="is_active" label="Is Active" />
                </Datagrid>
            </List>
        </React.Fragment>
    );
}