import React, { useState, useEffect } from 'react';
import { Edit, Button, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton } from 'react-admin';
import { useForm } from 'react-final-form';
import * as apiClient from "../../common/apiClient";
import queryString from 'query-string';
import { CircularProgress, Link } from '@material-ui/core';
const ChangePasswordCreateToolbar = props => {
    const form = useForm();

    const saveData = (formdata) => {
        const query = new URLSearchParams(window.location.href);
        if(formdata.newpassword!=formdata.confirmpassword)
        {
            alert("Confirm Password and New password not matching")
            return;
        }
        //console.log(formdata);
        let data = { 
            username: query.get('token'),
            password: formdata.newpassword,
            usertype: 'admin'
        };
        
        props.setMessage("");
        apiClient.ChangePassword(data).then((res) => {
            if (res[0].result == true) {
                props.setMessage("Password changed successfully");
                form.change("oldpassword", "");
                form.change("newpassword", "");
                form.change("confirmpassword", "");
            } else {
                props.setMessage(res.message);
            }
        });
    }

    return (
        <Toolbar {...props} >
            <div>
            <SaveButton  {...props} label="Change password" onSave={saveData} />
            <br/>
            <br/>
            <a href='#login'  >Back To Login  </a>
            </div>
        </Toolbar>
    )
};

export const ChangePassword = props => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [invalidLink, setInvalidLink] = useState(false);

    const query = new URLSearchParams(window.location.href);
    //alert(query.get('token'))
    useEffect(() => {
        const query = new URLSearchParams(window.location.href);
        validateToken(query.get('token'));
    }, []);


    const validateToken = (token) => {
        setLoading(true);
        let data = {
            username: token,
            password: "",
            usertype: 'admin'

        };

        apiClient.ValidateToken(data).then((res) => { 
            setLoading(false);
            if(res[0].result===false)
            {
                setInvalidLink(true)
            }
            
        });
    }
    return (
        <React.Fragment>
            {!loading && !invalidLink && <Create title="Change Password" {...props} basePath="changepassword" resource="changepassword">
                <SimpleForm
                    toolbar={<ChangePasswordCreateToolbar setMessage={setMessage} />}
                    variant="outlined"> 
                    <TextInput source="newpassword" label="New Password" type="password" validate={[required()]} />
                    <TextInput source="confirmpassword" label="Confirm Password" type="password" validate={[required()]} />
                </SimpleForm>
            </Create>
            }
            {message && <div className="change-password-result">{message}</div>}

            {loading &&
                <CircularProgress />

            }
            {invalidLink && 
            <div>
            Invalid Request 

            </div>
            }
        </React.Fragment>
    );


}