import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const RealizationOrderOtherFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});


    const onAmountChangejDisAllAmt = (e) => {
        let jDisAllAmt = parseInt(e.target.value);
        let { claimAmt, disAllAmt, uDisAllAmt, reclRecAmt } = formData;

        let varuDisAllAmt = disAllAmt - jDisAllAmt;
        let per = Math.round((varuDisAllAmt / (claimAmt + jDisAllAmt)) * 100);
        form.change("uDisAllAmt", varuDisAllAmt);
        form.change("uDisAllAmtPer", per);
    }


    return (
        <div className="payment-field-parent">

            <TextInput variant="outlined" label="HDC. Internal Query" multiline={true} source="hdcqDet" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" label="HDC Internal Query Dt " source="hdcqDt" fullWidth={true} className={classes.last_4_input} />
            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.hdcQrDt == null) {
                        form.change("hdcQrDt", new Date());
                    }
                }}
                label="HDC Query Received ?" source="isHdqConf" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.isHdqConf == true) ? false : true} label="HDC Query Rcvd. Dt" source="hdcQrDt" fullWidth={true} className={classes.last_4_input} />



            <TextInput variant="outlined" label="Insurance Remarks" multiline source="hospqDet" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" label="Insurance Query Dt" source="hospqDt" fullWidth={true} className={classes.last_4_input} />
            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.hospqsDt == null) {
                        form.change("hospqsDt", new Date());
                    }
                }}
                label="Query Submitted by Hospital?" source="isHqConf" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.isHqConf == true) ? false : true} label="Query Submitted Date" source="hospqsDt" fullWidth={true} className={classes.last_4_input} />



            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.billRDt == null) {
                        form.change("billRDt", new Date());
                    }
                }}
                label="Bill Raised ?" source="billRaised" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.billRaised == true) ? false : true} label="Bill Raised Date" source="billRDt" fullWidth={true} className={classes.last_4_input} />

            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.rInvDt == null) {
                        form.change("rInvDt", new Date());
                    }
                }}
                label="Invoice Raised ?" source="invRaised" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.invRaised == true) ? false : true} label="Invoice Raised Date" source="rInvDt" fullWidth={true} className={classes.last_4_input} />

            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.rBillRDt == null) {
                        form.change("rBillRDt", new Date());
                    }
                }}
                label="Reclaim Bill Raised ?" source="rBilRaised" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.rBilRaised == true) ? false : true} label="Reclaim Bill Raised Date" source="rBillRDt" fullWidth={true} className={classes.last_4_input} />

            <BooleanInput variant="outlined" label="Disputed In-Conclusive " source="isDspInCon" fullWidth={true} className={classes.start_thrice_4_input} />


            {/* <NumberInput variant="outlined" label="Hospital bill amount" source="hBillAmt"
                onChange={(e) => {
                    onAmountChangehBillAmt(e);
                }}
                fullWidth={true} className={classes.one_4_input} /> */}


        </div>
    )
}
