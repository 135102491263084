import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const CurrencyCreate = (props) => {
  return (
    <Create title="Currency Edit" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
        <TextInput
          source="currencyCode"
          fullWidth={true}
          validate={[required()]}
        />
        
        <TextInput
          source="currencyName"
          fullWidth={true}
          validate={[required()]}
        />
        
        <TextInput
          source="currencySymbol"
          fullWidth={true}
          validate={[required()]}
        />
        <NumberInput
          source="currencyExchangeRate"
          fullWidth={true}
          validate={[required()]}
        />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
