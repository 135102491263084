import React from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  SimpleForm,
  NumberInput,
  FormTab, ImageInput,
  ImageField
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";

export const CustomLogoInputDisplay = (props) => {
  return (
      <span>
          <div>Existing Image</div>
          {props.record.clientLogo && <img src={props.record.clientLogo}/>}
      </span>
  )
};

export const CustomImageInputDisplay = (props) => {
  return (
      <span className="existing-image">
          <div>Existing Image</div>
          {props.record.image && <img src={props.record.image}/>}
          {/* <div className="remove-image">X</div> */}
      </span>
  )
};
export const PartnerEdit = (props) => {
  const classes = useStyles({});
  return (
    <Edit title="Partner Edit" {...props} undoable={false} >

      <SimpleForm variant="outlined">
        <TextInput source="code" fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="name" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="skillsets" fullWidth={true} formClassName={classes.last_three_input} />
        <TextInput source="serviceOffered" fullWidth={true}  />
        <ImageInput source="image" fullWidth={true}
              label="Logo Image" accept="image/*" className="image" >
              <ImageField source="image" title="Icon" />
        </ImageInput>
        <div className="center-align">(Image Dimensions : 200px X 115px)</div>
        <CustomImageInputDisplay/>

        <ImageInput source="clientLogo" fullWidth={true}
              label="Clientele" accept="image/*" className="clientLogo" >
              <ImageField source="clientLogo" title="Icon" />
        </ImageInput>
        <div className="center-align">(Image Dimensions : 200px X 115px)</div>
        <CustomLogoInputDisplay/>
        <RichTextInput source="description" fullWidth={true} />
        <RichTextInput source="moreDescription" fullWidth={true} />
        <TextInput source="emailAddress" fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="phonenumber" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="landlineNumber" fullWidth={true} formClassName={classes.last_three_input} />
        <NumberInput source="displayOrder" />
        <BooleanInput source="isActive" fullWidth={true} formClassName={classes.last_inline_input} />
      </SimpleForm>


    </Edit>
  );
};
