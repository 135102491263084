import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";


const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Partner Code" source="code~like" alwaysOn />
    <SearchInput variant="outlined" label="Partner Name" source="name~like" alwaysOn />
    <ReferenceInput label="Area" source="areaId" reference="areas" >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export const PartnerList = (props) => {
  return (
    <List {...props} title="Partner List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="displayOrder" label="Display Order" />
        <TextField source="code" />
        <TextField source="name" />
        <TextField source="skillsets" />
        <ImageField source="image" title="Icon" />
        <ImageField source="clientLogo" title="Icon" />
        {/* <TextField source="code" label="Partner Code" />
        <TextField source="name" label="Partner Name" />
        <ReferenceField source="areaId" reference="areas" link={false} label="Area" >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="modifydate" label="Updated Date" /> */}
      </Datagrid>
    </List>
  );
};
