import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const TimezoneEdit = (props) => {
  return (
    <Edit title="Timezone Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput
          source="timeZoneName"
          fullWidth={true}
          validate={[required()]}
        />
        <TextInput
          source="description"
          fullWidth={true}
          validate={[required()]}
        />
        <NumberInput
          source="difference"
          fullWidth={true}
          validate={[required()]}
        />
        <BooleanInput
          source="daytimeSaving"
          validate={[required()]}
          label="Day Time Saving"
        />
        <BooleanInput
          source="isActive"
          validate={[required()]}
          label="Active"
        />
      </SimpleForm>
    </Edit>
  );
};
