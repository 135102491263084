import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const TimezoneCreate = (props) => {
  return (
    <Create title="Timezone Create" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
        <TextInput source="timeZoneName" fullWidth={true} validate={[required()]} />
        <TextInput source="description" fullWidth={true} validate={[required()]} />
        <NumberInput source="difference" fullWidth={true} validate={[required()]} />
        <BooleanInput source="daytimeSaving" validate={[required()]} label="Day Time Saving" />
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </SimpleForm>
    </Create>
  );
};
