import React from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  SimpleForm,
  NumberInput,
  FormTab, ImageInput, ImageField
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";

export const CustomLogoInputDisplay = (props) => {
  return (
      <span>
          <div>Existing Image</div>
          {props.record.image && <img src={props.record.image}/>}
      </span>
  )
};

export const AppPartnerEdit = (props) => {
  const classes = useStyles({});
  return (
    <Edit title="AppPartner Edit" {...props}  undoable={false} >

      <SimpleForm variant="outlined">
        <TextInput source="code" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput source="name" fullWidth={true} formClassName={classes.last_inline_input} />
        <RichTextInput source="description" fullWidth={true} />
        <ImageInput source="image" fullWidth={true}
              label="Logo" accept="image/*" className="image" >
              <ImageField source="image" title="Icon" />
        </ImageInput>
        <CustomLogoInputDisplay/>
        <RichTextInput source="moreDescription" fullWidth={true} />
        <TextInput source="emailAddress" fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="phonenumber" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="landlineNumber" fullWidth={true} formClassName={classes.last_three_input} />
        <NumberInput source="displayOrder" />
        <BooleanInput source="isActive" fullWidth={true} formClassName={classes.last_inline_input} />
      </SimpleForm>


    </Edit>
  );
};
