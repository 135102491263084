import React, { useCallback } from 'react';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";



const ServiceFields = ({ formData, ...rest }) => {
    const classes = useStyles({});
    const form = useForm(); 

    const loadextendedPrice = async (id) => {
        
        form.change("extendedPrice",null); 
           apiClient.getservicedata(id.target.value).then(res => {
                form.change("extendedPrice",res[0].price); 
            
       }).catch(err => {
           console.log(err);
       });
       
    }    
    return (     <React.Fragment> 
                <ReferenceInput variant="outlined" source="serviceCategoryId" label="Service Category " perPage={2000} reference="service-categories" fullWidth={true} formClassName={classes.one_three_input} filter={{ 'partentId~neq': 0 }} sort={{ field: 'serviceName', order: 'ASC' }} onChange={(val)=>loadextendedPrice(val)}  >
                    <SelectInput optionText="serviceName" validate={required()} />
                </ReferenceInput>

                </React.Fragment>
    );
}
export default ServiceFields;