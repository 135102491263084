import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  ImageInput,
  ImageField,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
export const industryEdit = (props) => {
  return (
    <Edit title="Industry Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
                <TextInput source="name" label="Name" validate={[required()]}/>
                <TextInput source="details" label="Details" validate={[required()]}  />
                <ImageInput source="image" label="Logo" accept="image/*">
                            <ImageField source="image" title="Logo" />
                   </ImageInput>
                   <ImageField source="image" />
                <BooleanInput source="isActive" label="Active" initialValue={true} />
      </SimpleForm>
    </Edit>
  );
};
