import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const AreasEdit = (props) => {
  return (
    <Edit title="Action Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/>
        <TextInput source="location" fullWidth={true}  />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Edit>
  );
};
