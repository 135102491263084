import React from 'react';
import {  Toolbar, SaveButton, DeleteButton } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
export const useStyles = makeStyles(theme => ({
    cancel_button: {
        marginLeft: 20,
        marginRight: 20,
    },
    main: {
        display: "flex", width: "100%",
        justifyContent: "space-between",
    },
    btnparent: {
        flex: 1,
    },
}));

export const FormToolbar = props => {
    const classes = useStyles({});
    const history = useHistory();
    const cancelForm = () => {
        history.goBack();
    }
    let showSave = true;
    if (props.hideSave) {
        showSave = false;
    }
    return (
        <Toolbar {...props} >
            <div className={classes.main}>
                <div className={classes.btnparent}>
                    {<SaveButton disabled={showSave ? false : true}  {...props} />}
                    <Button
                        className={classes.cancel_button}
                        variant="contained" color="primary"
                         size="medium"
                        onClick={cancelForm}
                    >Back</Button>
                </div>
                { (props.showDelete===undefined||props.showDelete===true)? <DeleteButton   {...props} />:null}
            </div>
        </Toolbar>
    )
};