import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountKeyMembers = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    const handleSubmit = async (formdata) => {
        
        if(props.editKeyMemberId===0)
        {
            let data = { 
                //id:0,
                accountId: parseInt(props.accountid),
                name:  formdata.name,
                designation:  formdata.designation,
                email: formdata.email,
                contactNo: formdata.contactNo,
                insertedBy: 0,
                insertedAt: "2021-12-18T07:17:23.601Z",
                updatedBy: 0,
                updatedAt: "2021-12-18T07:17:23.601Z",
                isActive: true,
                isDefault: true,
                isVerified: false,
                imageSize: 0,
                statusId: 0
              }
            apiClient.addEntiyRecord("account-key-members",data).then(res => {
                console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });
        }
        else
        {
            let data = { 
                id:props.editKeyMemberId,
                accountId: parseInt(props.accountid),
                name:  formdata.name,
                designation:  formdata.designation,
                email: formdata.email,
                contactNo: formdata.contactNo,
                insertedBy: 0,
                insertedAt: "2021-12-18T07:17:23.601Z",
                updatedBy: 0,
                updatedAt: "2021-12-18T07:17:23.601Z",
                isActive: true,
                isDefault: true,
                isVerified: false,
                imageSize: 0,
                statusId: 0
              }
              let resource="account-key-members\\"+props.editKeyMemberId;
            apiClient.putEntiyRecord(resource,data).then(res => {
                console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });
        }

        
    }
    return (
        <div>
        {/* // <Edit {...props} id={props.editKeyMemberId} resource="account-key-members" redirect="false"> */}
        {props.editKeyMemberId>0  && <Edit {...props} id={props.editKeyMemberId} resource="account-key-members" redirect="false">
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <TextInput   validate={required()} fullWidth={true} source="name" label="Name" />
                <TextInput   validate={required()} fullWidth={true} source="designation" label="Designation" />
                <TextInput   validate={required()} fullWidth={true} source="email" label="Email" />
                <TextInput   validate={required()} fullWidth={true} source="contactNo" label="Contact No" />
                {/* <ReferenceInput source="serviceCategoryId" reference="service-categories" fullWidth={true} >
                    <SelectInput optionText="serviceName" />
                </ReferenceInput>

                <TextInput multiline validate={required()} fullWidth={true} source="serviceDetails" label="Service Details" />
                <ImageInput fullWidth={true} source="serviceCategoryLogo" label="Logo" accept="image/*">
                    <ImageField source="serviceCategoryLogo" title="Logo" />
                </ImageInput>
                <ImageField fullWidth={true} source="serviceCategoryLogo" label="Existing Category Logo" />
                <FileInput fullWidth={true} source="serviceCatalogue" label="Service Catalog" accept="application/pdf,application/vnd.ms-powerpoint">
                    <FileField source="serviceCatalogue" title="File" />
                </FileInput> */}
            </SimpleForm>
        </Edit>
        
            }
         {props.editKeyMemberId===0  &&<Create {...props}  resource="account-key-members" redirect="false">
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <TextInput   validate={required()} fullWidth={true} source="name" label="Name" />
                <TextInput   validate={required()} fullWidth={true} source="designation" label="Designation" />
                <TextInput   validate={required()} fullWidth={true} source="email" label="Email" />
                <TextInput   validate={required()} fullWidth={true} source="contactNo" label="Contact No" />
                {/* <ReferenceInput source="serviceCategoryId" reference="service-categories" fullWidth={true} >
                    <SelectInput optionText="serviceName" />
                </ReferenceInput>

                <TextInput multiline validate={required()} fullWidth={true} source="serviceDetails" label="Service Details" />
                <ImageInput fullWidth={true} source="serviceCategoryLogo" label="Logo" accept="image/*">
                    <ImageField source="serviceCategoryLogo" title="Logo" />
                </ImageInput>
                <ImageField fullWidth={true} source="serviceCategoryLogo" label="Existing Category Logo" />
                <FileInput fullWidth={true} source="serviceCatalogue" label="Service Catalog" accept="application/pdf,application/vnd.ms-powerpoint">
                    <FileField source="serviceCatalogue" title="File" />
                </FileInput> */}
            </SimpleForm>
        </Create>
        
            }
            </div>
    );
}
