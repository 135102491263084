import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, NumberInput, BooleanInput, required, maxLength, minLength } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";

export const EmployeeCreate = props => {
    // const defaultValues = { is_active: true, faculty_type: 'Teacher' }
    return (
        <React.Fragment>
            <Create title="Edit Employee" {...props}>
                <SimpleForm warnWhenUnsavedChanges redirect="list" toolbar={<FormToolbar {...props} />}
                    variant="outlined" >
                    <TextInput source="name" label="Employee Name" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}