import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict"

export const ServiceCategoryCreate = (props) => {
  return (
    <Create title="State Edit" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
             <FormDataConsumer>
              {formDataProps => (
                <StateDistrict {...formDataProps} />
              )}
            </FormDataConsumer>
        <TextInput source="cityCode" fullWidth={true} validate={[required()]} />
        <TextInput source="cityName" fullWidth={true} validate={[required()]} />
        
                    
        <BooleanInput
          source="isActive"
          validate={[required()]}
          label="Active"
        />
      </SimpleForm>
    </Create>
  );
};
