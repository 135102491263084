import React from "react";
import {
  
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const UsersEdit = (props) => {
  const classes = useStyles({});
  return (
    <Edit title="User Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <ReferenceInput label="Role" source="roleTypeId" sort={{ field: 'roleName', order: 'ASC' }} reference="roletypes" formClassName={classes.one_three_input}>
              <SelectInput optionText="roleName" validate={[required()]} />
      </ReferenceInput>
      {/* <ReferenceInput label="Type" formClassName={classes.two_three_input} 
                    perPage={5000} source="typeId"
                    filter={{ groupId: 11 }}
                    sort={{ field: 'sno', order: 'ASC' }}
                    reference="status-groups" >
                    <SelectInput optionText="statusName" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput> */}
       {/* <TextInput source="code"  validate={[required()]} formClassName={classes.last_three_input}/> */}
       <TextInput source="firstName" label="Name" formClassName={classes.two_three_input}  />
        <TextInput source="userName" formClassName={classes.last_three_input}  />
        
        <PasswordInput source="password" formClassName={classes.one_three_input}  />
        {/* <DateInput source="startDate" formClassName={classes.last_three_input}/>
        <DateInput source="endDate" formClassName={classes.one_three_input}  />
        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.two_three_input}  />
        <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.last_three_input}/> */}
        {/* <BooleanInput source="isSuperUser" validate={[required()]}  label="Super User" formClassName={classes.two_three_input}  />  */}
        <BooleanInput source="isActive" validate={[required()]}  label="Active"  formClassName={classes.two_three_input}  />
        
        <FormDataConsumer formClassName={classes.two_three_input}>
          {({ formData, ...rest }) =>
            formData.typeId &&
            formData.typeId === 10 &&
             (
              <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" formClassName={classes.one_three_input} variant="outlined" >
                <SelectInput optionText="name"  validate={[required()]}  variant="outlined" formClassName={classes.one_three_input} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        
      </SimpleForm>
    </Edit>
  );
};
