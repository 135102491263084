import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TPAConfigEdit from "./TPAConfigEdit";
import CDeleteButton from "../../common/CDeleteButton";
import TPAConfigCreate from "./TPAConfigCreate";
import HospitalDocumentsCreate from "./HospitalDocumentsCreate";

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
      display: "flex",
      justifyContent: "space-between",
  },
  item: {
      width: 100,
  },
}));

export const TPAEditButton = props => {
  const setHospitalDetId = () => {
      props.setHospitalDetEdit(props.record.id);
  }
  return (
      <Button
          color="secondary" disableElevation
          size="medium"
          
          onClick={setHospitalDetId}
          startIcon={<EditIcon />}>Edit</Button>
  )
};

export const HospitalEdit = (props) => {
  const classes = useStyles({});
  const [isaddTpa, setIsAddTpa] = useState(false);
  const [isEditTpa, setIsEditTpa] = useState(false);
  const [hospitalDetEditId, setHospitalDetEditId] = useState(0);
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const setgridRecordEdit = (id) => {
   
    setHospitalDetEditId(id);
    setIsEditTpa(true);
}
const closeEdit = () => {
  setIsAddTpa(false);
  setIsEditTpa(false);
  setIsAddDocuments(false);
  refresh();
}


const onCancel = () => {
  setIsAddTpa(false);
  setIsEditTpa(false);
  setIsAddDocuments(false);
}

  const handleClose = useCallback(() => {
    setIsAddTpa(false);
    //setIsAccountServiceAdd(false);
}, []);

  return (
    <React.Fragment>
    <Edit title="Hospital Edit" {...props}>
   
   <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}>
          <FormTab value="DETAILS" label="TPA Detail">
            <TextInput source="code" label="Code" validate={[required()]} formClassName={classes.one_three_input} disabled={true} initialValue={"Auto Generate"} />
            <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />
            <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="phoffice" label="Phone(Off)."  formClassName={classes.two_three_input} />
            <TextInput source="email" label="email" formClassName={classes.last_three_input} />
            
            <TextInput source="phmobile" label="Mobile" formClassName={classes.one_three_input} />
            <TextInput source="tin" label="Tin" formClassName={classes.two_three_input} />
            <TextInput source="cstNo" label="CST Number" formClassName={classes.last_three_input} />

            <TextInput source="tpt" label="tpt" formClassName={classes.one_three_input} />
            <TextInput source="licno" label="LIC No" formClassName={classes.two_three_input} />
            <TextInput source="panno" label="Pan Number"  formClassName={classes.last_three_input} />

            <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.one_three_input}>
              <SelectInput optionText="name" />
          </ReferenceInput>

          <TextInput source="remarks" label="Remarks"  formClassName={classes.two_three_input} />
          
             
          </FormTab>
          <FormTab value="DETAILS" label="Other Config">
          
          <BooleanInput source="isCeLic" label="Is CE License" initialValue={true} formClassName={classes.one_three_input} />
          <BooleanInput source="isFireLic" label="Is Fire Lic" initialValue={true} formClassName={classes.two_three_input} />
          <BooleanInput source="isPndt" label="Is PNDT" initialValue={true} formClassName={classes.last_three_input} />

          <BooleanInput source="isPollut" label="Is Pollution" initialValue={true} formClassName={classes.one_three_input} />
          <BooleanInput source="isBioWaste" label="Is Biomedical Waste" initialValue={true} formClassName={classes.two_three_input} />
          <BooleanInput source="isPpn" label="Is PPN" initialValue={true} formClassName={classes.last_three_input} />

          <BooleanInput source="isRohiniId" label="Is Rohini Id" initialValue={true} formClassName={classes.one_three_input} />
          <BooleanInput source="isNabh" label="isBioWaste" initialValue={true} formClassName={classes.two_three_input} />
          <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.last_three_input} />


          </FormTab>
          <FormTab value="DETAILS" label="Address Detail">
           <TextInput source="state" label="State" validate={[required()]} formClassName={classes.one_three_input} />
          <ReferenceInput label="Area" source="areaId" reference="areas" formClassName={classes.two_three_input}>
              <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="city" label="City" validate={[required()]} formClassName={classes.last_three_input} />
          
          <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="add2" label="Address 2" formClassName={classes.two_three_input} />
          <TextInput source="add3" label="Address 3"  formClassName={classes.last_three_input} />

          <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.one_three_input} />
   
          </FormTab>

          <FormTab value="DETAILS" label="Contact Person's Detail">
        
          <TextInput source="cpName1" label="Contact Person 1"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes1" label="Contact Person 1 ResiID"  formClassName={classes.last_three_input} />

          <TextInput source="cpName2" label="Contact Person 2"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes2" label="Contact Person 2 ResiID"  formClassName={classes.last_three_input} />
          
          <TextInput source="cpName3" label="Contact Person 3"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob3" label="Contact Person 3 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes3" label="Contact Person 3 ResiID"  formClassName={classes.last_three_input} />
          

          <TextInput source="cpName4" label="Contact Person 4"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob4" label="Contact Person 4 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes4" label="Contact Person 4 ResiID"  formClassName={classes.last_three_input} />

          <TextInput source="cpName5" label="Contact Person 5"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob5" label="Contact Person 5 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes5" label="Contact Person 5 ResiID"  formClassName={classes.last_three_input} />
              
          </FormTab>
          <FormTab label="TPA and Insurer">
                        <Button variant="contained" onClick={() => { setIsAddTpa(true); }}
                            color="secondary">
                            Add TPA and Insurer
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="hospital-dets"
                            target="hospitalId">
                            <Datagrid>
                                <VSDeleteButton {...props} />
                                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                                <ReferenceField link={false} label="TPA" source="tpaid" reference="tpas" fullWidth={true} >
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField fullWidth={true} source="isTieUp" label="Tie-Up" />
                                <TextField fullWidth={true} source="empanelled" label="Empanelled" />
                                <DateField fullWidth={true} source="agrmtFDt" label="Agrmnt. Date From" />
                                <DateField fullWidth={true} source="agrmtTDt" label="Agrmnt. Date To" />
                                <TextField fullWidth={true} source="remarks" label="Remarks" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
   <FormTab label=" Documents">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddDocuments(true); }}>Add New</Button>
              
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="hospital-docs"
              target="hospitalId">
              <Datagrid>
                <CDeleteButton {...props} />
                {/* <TextField label="ITP" source="itp" />
                <TextField label="BillNo" source="billNo" /> */}
                <DateField showTime={true} label="createdDate" source="createdDate" />
                <TextField label="Document Type" source="docType" />
                <TextField label="Document Value" source="docValue" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                {/* <UploadDocument onClick={(record) => { setIsEditDocumentRecord(record); setIsEditDocuments(true); }} label="Upload Document" /> */}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
    
        </TabbedForm>


    </Edit>
    <Drawer
     anchor="right"
     onClose={handleClose}
     classes={{ paper: myclasses.drawerPaper, }}
     open={isaddTpa || isaddDocuments} >
       {isaddTpa &&  <TPAConfigCreate
                      onClose={closeEdit}
                      onCancel={onCancel}
                      hospitalId={props.id}
                      {...props} />}
       {isaddDocuments && <HospitalDocumentsCreate
                    onClose={closeEdit}
                    onCancel={onCancel}
                    hospitalId={props.id}
                    {...props}   />}
       </Drawer>
 
</React.Fragment>
  );
};
