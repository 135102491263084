import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import * as apiClient from '../../common/apiClient';
import CreateToolbar from '../../common/PopupCreateToolbar';


export const ContentQueryCreatePopup = props => {
    const handleSubmit = (formdata) => {
        let data = {
            content_id: props.content_id,
            query: formdata.query
        };
        apiClient.addEntiyRecord("contentquery", data).then(() => {
            props.onClose();
        });
    }
    return (
        <Create basePath="contentquery" resource="contentquery" {...props}>
            <SimpleForm
                variant="outlined"
                onSubmit={handleSubmit}
                toolbar={<CreateToolbar onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
            >
                <TextInput source="query" fullWidth={true} multiline={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
}