import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { useStyles } from "../../formStyles";
export const OfficeAddressfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});;
    return (
        <div>
            
            <ReferenceInput variant="outlined" label="Country" source="officeCountryId" reference="countries"
                onChange={(v) => {                    
                    form.change("officeStateId", 0);
                }}
                validate={required()}
                fullWidth={true}
                 perPage={500} className={classes.one_three_input}>
                <SelectInput optionText="name" className={classes.one_three_input}></SelectInput>
            </ReferenceInput>
            
            <ReferenceInput variant="outlined" label="State" source="officeStateId"
                reference="states" perPage={500}
                filter={{ "countryId": formData.officeCountryId }}
                validate={required()}
                fullWidth={true}
                onChange={(v) => {
                    form.change("officeCityId", 0);
                }}
                
                className={classes.two_three_input}>
                <SelectInput validate={required()} optionText="stateName"  className={classes.two_three_input}/>
            </ReferenceInput>


            
            <ReferenceInput variant="outlined" label="City" source="officeCityId"
                reference="cities" perPage={500}
                filter={{ "stateId": formData.officeStateId }}
                validate={required()}
                fullWidth={true}
                 className={classes.last_three_input}>
                <SelectInput validate={required()} optionText="cityName" />
            </ReferenceInput>
        </div>
    )
}
