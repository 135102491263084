import React, { useCallback,useState } from 'react';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";



const TaxFields = ({ formData, ...rest }) => {
    const classes = useStyles({});
    const[totalamt,setTotalAmt]=useState(0)
    const form = useForm();

    const calculateAmounts = async (e, field) => {
        debugger
        if (field != "taxHeadId") {
            form.change(field, parseFloat(e.target.value));
        }
        else {
            form.change("taxHeadPc", parseFloat(e));

        }
        let { customerTargetCost,otherCost,processCost,taxHeadPc } = formData;
        
        if(field=="customerTargetCost")
        {
            customerTargetCost= e.target.value;
        }
        if(field=="otherCost")
        {
            otherCost= e.target.value;
        }
        if(field=="processCost")
        {
            processCost= e.target.value;
        }
        if(field=="taxHeadId")
        {
            taxHeadPc= e;
        }
        if (isNaN(customerTargetCost)) {
            customerTargetCost = 0;
        }
        if (isNaN(otherCost)) {
            otherCost = 0;
        }
        if (isNaN(processCost)) {
            processCost = 0;
        }
        if (isNaN(taxHeadPc)) {
            taxHeadPc = 0;
        }

        
        customerTargetCost= parseFloat(customerTargetCost);
        otherCost= parseFloat(otherCost);
        processCost= parseFloat(processCost);
        taxHeadPc= parseFloat(taxHeadPc);
        let taxamt=(customerTargetCost+otherCost+processCost);
        
        taxamt=taxamt*taxHeadPc/100
        let totalAmount=(customerTargetCost+otherCost+processCost)+taxamt;
        form.change("taxAmount", taxamt);
        form.change("jobLineTotalCost", totalAmount);
        setTotalAmt(totalAmount)
        // let settleAmt = parseFloat(e.target.value);
        // let { pPaidAmt, hospDisc, hBillAmt, othDisc, tdsamt,
        //     claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        // hBillAmt = parseFloat(hBillAmt);
        // hospDisc = parseFloat(hospDisc);
        // pPaidAmt = parseFloat(pPaidAmt);
        // tdsamt = parseFloat(tdsamt);
        // settleAmt = parseFloat(settleAmt);
        // reclRecAmt = parseFloat(reclRecAmt);


        // form.change("taxHeadPc", null);
        // apiClient.gettaxper(id.target.value).then(res => {
        //     form.change("taxHeadPc", res[0].taxpc);

        // }).catch(err => {
        //     console.log(err);
        // });

    }
    const load = async (id) => {

        form.change("taxHeadPc", null);
        apiClient.gettaxper(id.target.value).then(res => {
            form.change("taxHeadPc", res[0].taxpc);
            calculateAmounts(res[0].taxpc, "taxHeadId");

        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <div className="payment-field-parent">
            <NumberInput variant="outlined" validate={required()} source="customerTargetCost" label="Customer Target Cost" formClassName={classes.one_three_input}
                onChange={(e) => {
                    calculateAmounts(e, "customerTargetCost");
                }}
            />
            {"   "}
            <NumberInput variant="outlined" validate={required()} source="otherCost" label="Other Cost" formClassName={classes.two_three_input}
                onChange={(e) => {
                    calculateAmounts(e, "otherCost");
                }}
            />
            {"   "}
            <NumberInput variant="outlined" validate={required()} source="processCost" label="Process Cost" formClassName={classes.last_three_input}
                onChange={(e) => {
                    calculateAmounts(e, "processCost");
                }}
            />

            {"   "}
            <ReferenceInput variant="outlined" source="taxHeadId" label="Tax Head " perPage={2000} reference="tax-heads" formClassName={classes.one_three_input} sort={{ field: 'taxName', order: 'ASC' }} onChange={(val) => load(val)} >
                <SelectInput optionText="taxName" validate={required()} />
            </ReferenceInput>
            {"   "}
            <NumberInput variant="outlined" validate={required()} source="taxHeadPc" label="Tax Percent" formClassName={classes.two_three_input} disabled
                onChange={(e) => {
                    calculateAmounts(e, "taxHeadPc");
                }}

            />
            {"   "}
            <NumberInput variant="outlined" validate={required()} source="taxAmount" label="Tax Amount" formClassName={classes.last_three_input} disabled initialValue={0}
                onChange={(e) => {
                    calculateAmounts(e, "taxAmount");
                }}
            />

            <div style={{ width: "100%", textAlign: "right", fontSize: 15, marginRight: "10", fontWeight: "bold" }}>
                Total Amount:{parseFloat(totalamt)}
            </div>

        </div>
    );
}
export default TaxFields;