import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

import "./report.css";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const MasterReport = (props: any) => {
    // const classes = useStyles();
    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [types, setTypes] = useState<any>([]);
    const [type, setType] = useState<any>(0);
    const [statuses, setStatuses] = useState<any>([]);
    const [status, setStatus] = useState<any>(0);
    const [wariningtypes, setWariningTypes] = useState([]);
    const [wariningtype, setWariningtype] = useState<any>(0);
    
    const [patientName, setpatientName] = useState("");
    const [hospitals, sethospitals] = useState<any>([]);
    const [hospital, sethospital] = useState<any>(0);

    const [tpas, settpas] = useState<any>([]);
    const [tpa, settpa] = useState<any>(0);

    const [corporates, setcorporates] = useState<any>([]);
    const [corporate, setcorporate] = useState<any>(0);
        
    const [insurers, setinsurers] = useState<any>([]);
    const [insurer, setinsurer] = useState<any>(0);
    

     
    const getHospitals = () => {
        apiClient.getdata("hospitals").then(res => {
            sethospitals(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getTpas = () => {
        apiClient.getdata("tpas").then(res => {
            settpas(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getCorporates = () => {
        apiClient.getdata("corporates").then(res => {
            setcorporates(res);
        }).catch(err => {
            console.log(err);
        });
    }
    
    const getInsurers = () => {
        apiClient.getdata("insurers").then(res => {
            setinsurers(res);
        }).catch(err => {
            console.log(err);
        });
    }
    

    const generateReport = () => {
        setFectched(false);

        let sDate =  moment(startdate).format('YYYY-MM-DD');
        let eDate =  moment(enddate).format('YYYY-MM-DD');
        if(startdate==="")
        {
            sDate="";
        }
        if(enddate ==="")
        {
            eDate="";
        }

        let data = {
            fromDate: sDate,
            toDate: eDate,
            pName: patientName,
            hospitalID: hospital,
            tpaId: tpa,
            corporateID: corporate,
            insurerID: insurer,
            rptName:props.rpt
        };
        
        setLoading(true);

        apiClient.loadReport("/report/masterreport",data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {
        // let n = localStorage.getItem('username');
        // if(n == null){
        //     props.history.push("/login");
        // }
        getHospitals();
        getTpas();
        getCorporates();
        getInsurers();
    }, []);


    return (
        <div >
            <div className="report-heading">
                 {props.rptTtile}    
            </div>
            
            <div className="filters">
                <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                    <TextField id="outlined-basic" label="Patient Name" onChange={(e) => {  setpatientName(e.target.value); }} variant="outlined" size="small" />
            </div>
              

            
                <FormControl variant="outlined" className={classes.formControl} size="small">
                <InputLabel id="demo-simple-select-outlined-label">Hospital</InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Hospital" onChange={(e) => { sethospital(e.target.value); }} value={hospital} >
                <MenuItem value={0}>{"-All-"}</MenuItem>
                {hospitals && hospitals.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
                </Select>
                </FormControl>

           <FormControl variant="outlined" className={classes.formControl} size="small">
        <InputLabel id="demo-simple-select-outlined-label">TPA</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="TPA" onChange={(e) => { settpa(e.target.value); }} value={tpa} >
        <MenuItem value={0}>{"-All-"}</MenuItem>
          {tpas && tpas.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl} size="small">
        <InputLabel id="demo-simple-select-outlined-label">Corporate</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Corporate" onChange={(e) => { setcorporate(e.target.value); }} value={corporate} >
        <MenuItem value={0}>{"-All-"}</MenuItem>
          {corporates && corporates.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl} size="small">
        <InputLabel id="demo-simple-select-outlined-label">Insurer</InputLabel>
        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Insurer" onChange={(e) => { setinsurer(e.target.value); }}  value={insurer} >
        <MenuItem value={0}>{"-All-"}</MenuItem>
          {insurers && insurers.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
        </Select>
      </FormControl>

                          {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div>No Record found</div>}
                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div>
            </div>
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
            </div>
            <div className="report-data">
            
            {isLoading && <CircularProgress />}

                <div className="w3-responsive">
                
                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">
                    
                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
};