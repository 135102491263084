import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  SelectInput,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Code" source="userTypeCode~like" alwaysOn />
        <SearchInput variant="outlined" label="Name" source="userTypeName~like" alwaysOn />
    </Filter>
);
export const usertypeList = (props) => {
  return (
    <List {...props} title="User Type List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="userTypeCode" label="Code" />
        <TextField source="userTypeName" label="Name" />
        <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="insertedAt" label="Created Date"/>
        <ReferenceField source="updatedBy" reference="users" label="Modified By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="updatedAt" label="Updated Date"/>
        </Datagrid>      
    </List>
  );
};
