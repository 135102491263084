import React from 'react';
import {
    List, Datagrid, TextField, ChipField, EmailField, DateField, EditButton,
    BooleanField,
    ReferenceField, SearchInput, Filter
} from 'react-admin';
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Account Code" source="accountCode~like" alwaysOn />
        <SearchInput variant="outlined" label="Account Name" source="accountName~like" alwaysOn />
        <SearchInput variant="outlined" label="Email" source="emailAddress~like" alwaysOn />
    </Filter>
);
export const AccountList = (props) => {
    let user: any = localStorage.getItem("userId");
      if (user) {
        user = parseInt(user);
      }
    
    return (
        <React.Fragment>
            <List title='List of Service Provider' {...props}  filter={{ accountTypeId: 1,moderatorId:user,statusId:0 }} filters={<Filters />}  >
                <Datagrid rowClick='edit'>
                    <EditButton />
                    <ChipField source='accountCode' label='Code' />
                    <TextField source='accountName' label='Name' />
                    <ReferenceField source="accountTypeId" reference="status-groups" label="Company Type" link={false}>
                        <TextField source="statusName" />
                    </ReferenceField>
                    <TextField source="yearofCorp" label="Year of Corp" />
                    <TextField source="noOfEmployeeId" label="No Of Employee" />
                    <TextField source='webSite' label='Web Site' />
                    <TextField source='contactName' label='Contact Name' />
                    <TextField source='contactPhone' label='Contact Phone' />
                    <EmailField source='contactEmail' label='Contact Email' />
                    <TextField source='contactMobile' label='Contact Mobile' />
                    <TextField source='profileDetails' label='Profile Details' /> 
                    <ReferenceField source="annualTurnOverId" label="Annual Turnover" reference="status-groups" link={false}>
                        <TextField source="statusName" />
                    </ReferenceField>
                    <ReferenceField source="anualTurnoverRangeId" label="Annual Turnover Range" reference="status-groups" link={false}>
                        <TextField source="statusName" />
                    </ReferenceField>
                    
                    <ReferenceField source="anualTurnoverCurrencyId" label="Currency" reference="currency" link={false}>
                        <TextField source="currencyName" />
                    </ReferenceField>
                    <ReferenceField source="statusId" label="Status" reference="status-groups" link={false}>
                        <TextField source="statusName" />
                    </ReferenceField>
                    <TextField source='isProfileCompleted' label='Profile Completed' /> 
                    
                    
                </Datagrid>
            </List>
        </React.Fragment>
    );
}

