import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab, SimpleForm, NumberInput,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import RichTextInput from 'ra-input-rich-text';

export const AppPartnerCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="AppPartner Create" {...props}  undoable={false} >
      <SimpleForm variant="outlined">
        <TextInput source="code" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput source="name" fullWidth={true} formClassName={classes.last_inline_input} />
        <RichTextInput source="description" fullWidth={true} />
        <ImageInput source="image" fullWidth={true}
          label="Logo" accept="image/*" className="image" >
          <ImageField source="image" title="Icon" />
        </ImageInput>
        <RichTextInput source="moreDescription" fullWidth={true} />
        <TextInput source="emailAddress" fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="phonenumber" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="landlineNumber" fullWidth={true} formClassName={classes.last_three_input} />
        <NumberInput source="displayOrder" />
        <BooleanInput source="isActive" fullWidth={true} formClassName={classes.last_inline_input} />
      </SimpleForm>
    </Create>
  );
};
