import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="State Name" source="stateName~like" alwaysOn />
    </Filter>
);
export const StateList = (props) => {
  return (
    <List {...props} title="State List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        
        <TextField source="stateCode" label="State Code" />
        <TextField source="stateName" label="State Name" />
        <ReferenceField source="countryId" reference="countries" link={false} label="Country" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/>
      </Datagrid>
    </List>
  );
};
