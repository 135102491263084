import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountIndustries = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    const handleSubmit = async (formdata) => {
        let data = { 
            //id:0,
            accountId: parseInt(props.accountid),
            industryId:parseInt(formdata.industryId),
          }
        apiClient.addEntiyRecord("account-industries",data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

        
    }
    return (
        <div>
        
        
         {<Create {...props}  resource="account-industries" redirect="false">
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                
                 
            <ReferenceInput variant="outlined" label="Industry" source="industryId"
                reference="industries" perPage={500} 
                validate={required()}
                fullWidth={true}>
                 
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>
            </SimpleForm>
        </Create>
        
            }
            </div>
    );
}
