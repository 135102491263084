import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//#1976d2" : Royal blue
// 84254A
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#4A6855",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 700,
        marginTop: 20,
        marginBottom: 20
    },
});

export default function JobProgress({ data }) {
    const classes = useStyles();
    return (
        <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Job No</StyledTableCell>
                    <StyledTableCell>Job Name   </StyledTableCell>
                    <StyledTableCell>Job Category  </StyledTableCell>
                    <StyledTableCell>Status </StyledTableCell>
                    <StyledTableCell>Partner</StyledTableCell>
                    <StyledTableCell>Target Date</StyledTableCell>
                    <StyledTableCell>Completion Date</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data && data.length > 0 && data.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                            {row.name}
                        </StyledTableCell>
                        <StyledTableCell>{row.alert_mobile_no}</StyledTableCell>
                        <StyledTableCell >{row.session}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}
