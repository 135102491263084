import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const StatusGroupCreate = (props) => {
  return (
    <Create title="Status Group Create" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
        <ReferenceInput label="Group" source="groupId" reference="groups"   fullWidth={true} >
          <SelectInput optionText="groupName" />
        </ReferenceInput>

        <TextInput
          source="statusCode"
          fullWidth={true}
          validate={[required()]}
        />
        <TextInput
          source="statusName"
          fullWidth={true}
          validate={[required()]}
        />
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </SimpleForm>
    </Create>
  );
};
