import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const StatusGroupEdit = (props) => {
  return (
    <Edit title="Status Group Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <ReferenceInput source="groupId" reference="groups"  fullWidth={true}  link={false} label="Group" >
          <SelectInput optionText="groupName" />
      </ReferenceInput>
        <TextInput
          source="statusCode"
          fullWidth={true}
          validate={[required()]}
        />
        <TextInput
          source="statusName"
          fullWidth={true}
          validate={[required()]}/>
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
        </SimpleForm>
    </Edit>
  );
};
