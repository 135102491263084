import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="User Name"
      source="userName~like"
      alwaysOn
    />
  </Filter>
);
export const UsersList = (props) => {
  return (
    <List {...props} title="User List"  filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="code" label="User Id" />
        <TextField source="userName" label="User Name " />
        {/* <BooleanField source="isSuperUser" label="isSuperUser" /> */}
        <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="insertedAt" label="Created Date"/>
        <ReferenceField source="updatedBy" reference="users" label="Modified By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="updatedAt" label="Updated Date"/>
      </Datagrid>
    </List>
  );
};
