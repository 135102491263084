import React, {
    useState,
    useEffect,
    useCallback,
    FC,
    CSSProperties,
} from 'react';

import { ContentQueryCreatePopup } from './ContentQueryCreatePopup';

import Blink from 'react-blink-text';
import { makeStyles, Drawer } from '@material-ui/core';
import ReactPlayer from 'react-player';

import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TimerIcon from '@material-ui/icons/Timer';

import * as apiClient from '../../common/apiClient';
import { VideoPlayerPopup } from '../../common/VideoPlayerPopup';
// import { DocumentViewerPopup } from '../../common/DocumentViewerPopup';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import VideoCallIcon from '@material-ui/icons/VideoCall';
import LinkIcon from '@material-ui/icons/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';

import Button from '@material-ui/core/Button';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import StarIcon from '@material-ui/icons/Star';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 350,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 0,
        width: 650,
        maxWidth: "100%",
        padding: 10,
        paddingBottom: 50,
        backgroundColor: "#f9f9f9",
        margin: "auto"
    },
}));


export const Timeline: FC = (props: any) => {
    const [contentId, setContentId] = useState(0);
    const [subjects, setSubjects] = useState([]);
    const [subject, setSubject] = useState(null);
    const [content, setContent] = useState([]);
    const [videoContent, setVideoContent] = useState<any>(null);
    const [isVideoContent, setIsVideoContent] = useState(false);
    const [video_content_url, setVideo_content_url] = useState("");
    const [documentContent, setDocumentContent] = useState(null);
    const [askQuestion, setAskQuestion] = useState(false);
    const myclasses = myStyles({});

    const fetchSubjects = () => {
        apiClient.getSubjectsForStudent().then(response => {
            setSubjects(response.result);
        }).catch(err => console.log(err));
    }
    useEffect(() => {
        console.log("calling fetch subjects");
        fetchSubjects();
    }, []);

    const handleChange = (event) => {
        const subjectId = event.target.value;
        if (subjectId != '') {
            apiClient.getContentPageForSubject(subjectId).then(response => {
                setContent(response.result);
                setSubject(subjectId);
            }).catch(err => console.log(err));
        }
    }

    const handleViewClick = (currentContent) => {
        if (currentContent.content_type_id == 1 || currentContent.content_type_id == 2) {
            setVideoContent(currentContent);
            setVideo_content_url(currentContent.content_url);
            setIsVideoContent(true);
        }
        else if (currentContent.content_type_id == 3) {
            setDocumentContent(currentContent);
        }
        apiClient.StudentTracking(currentContent.id).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const markRead = (currentContent) => {
        apiClient.ContentReadStatus(currentContent.id).then(res => {
            apiClient.getContentPageForSubject(subject).then(response => {
                setContent(response.result);
            }).catch(err => console.log(err));
        }).catch(err => {
            console.log(err);
        });
    }
    const getRandomBackgroundColor = () => {
        return "hsl(" + 360 * Math.random() + ',' +
            (25 + 70 * Math.random()) + '%,' +
            (85 + 10 * Math.random()) + '%)'
    }

    const generateContentArrowStyle = (color) => {
        return '7px solid ' + color;
    }

    const getTimelineItem = (currentContent: any) => {
        let icon;
        let isVideo = false;
        let isVideoLink = false;
        let isStudyMaterial = false;
        let isAssignment = false;
        if (currentContent.content_type_id == 1) {
            icon = <VideoCallIcon />;
            isVideo = true;
        }
        else if (currentContent.content_type_id == 2) {
            icon = <LinkIcon />;
            isVideoLink = true;
        }
        else if (currentContent.content_type_id == 3) {
            icon = <PictureAsPdfIcon />;
            isStudyMaterial = true;
        }
        else if (currentContent.content_type_id == 4) {
            icon = <PlaylistAddCheckOutlinedIcon />;
            isAssignment = true;
        }

        let topicNote = null;
        if (currentContent.topic_note.length > 0)
            topicNote = currentContent.topic_note;

        let bgColor = getRandomBackgroundColor();
        let arrowStyle = generateContentArrowStyle(bgColor);
        return (
            <VerticalTimelineElement
                key={currentContent.id}
                icon={icon}

                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}

            //contentStyle={{ background: bgColor, color: '#444' }}
            //contentArrowStyle={{ borderRight: arrowStyle }}
            >
                <div className="topic-title">{currentContent.topic}</div>
                {topicNote && <div className="topic-note">{topicNote}</div>}

                <div className="topic-time-stamp">
                    <TimerIcon fontSize="large" />
                    <div>Covered on {currentContent.approval_date}</div>
                </div>

                <div className='resource-type'>
                    {(isVideo) && <span>Resource: Video</span>}
                    {(isVideoLink) && <span>Resource: Video Link</span>}
                    {(isStudyMaterial) && <span>Resource: Study Material</span>}
                    {
                        (isAssignment) &&
                        <div>
                            <div>Resource: Assignment</div>
                            <div>Last date for submission: {currentContent.submission_date}</div>
                            <div className='assignment-code-div'>Assignment Code:<span className='numberCircle'>{currentContent.id}</span></div>
                        </div>
                    }

                </div>

                {
                    (isVideo || isVideoLink) && <div className='link' onClick={(event) => handleViewClick(currentContent)}>
                        <Blink color='cyan' text='Click Here to view the video' fontSize='30'></Blink>
                    </div>
                }

                {
                    (isStudyMaterial || isAssignment) && <div className='link' onClick={(event) => handleViewClick(currentContent)}>
                        <a href={currentContent.content_url} target='_blank'>
                            <Blink color='cyan' text='Click Here to view the document' fontSize='30'></Blink>
                        </a>
                    </div>
                }
                <div className="mark-read-container">
                    {!currentContent.read && <Button variant="contained" size="small" color="default"
                        onClick={() => {
                            markRead(currentContent);
                        }}>
                        Mark as Read
                    </Button>}

                    {<Button variant="contained" size="small" color="default" style={{ marginLeft: 10 }}
                        onClick={() => {
                            setContentId(currentContent.id);
                            setAskQuestion(true);
                        }}>
                        Ask a question
                    </Button>}

                </div>
            </VerticalTimelineElement>
        )
    }
    const closePopup = () => {
        setVideoContent(null);
        setVideo_content_url("");
    }
    const onCancel = () => {
        setAskQuestion(false);
        setVideoContent(null);
        setVideo_content_url("");
        setIsVideoContent(false);
    }
    const getVideoUrl = (content_url) => {
        var aData = content_url.split("/");
        var index = aData.length - 1;
        var url = "https://player.vimeo.com/video/" + aData[index];
        console.log(url);
        return url;
    }
    return (
        <div className="timeline-container">
            {/* {videoContent && <VideoPlayerPopup record={videoContent} closePopup={closePopup} />} */}
            {/* {documentContent && <DocumentViewerPopup record={documentContent} closePopup={closePopup} />} */}

            <div className="subject-prompt-container">
                <select
                    onChange={handleChange}
                    className="my-select"
                >
                    <option value=''>Select Subject</option>
                    {subjects && subjects.map((element: any) => {
                        return (
                            <option key={element.id} value={element.id}>{element.subject}</option>
                        )
                    })}
                </select>

                <VerticalTimeline>
                    {
                        content && content.map((currentContent: any) => {
                            return getTimelineItem(currentContent);
                        })
                    }
                    <VerticalTimelineElement
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<StarIcon />}
                    />

                </VerticalTimeline>
            </div>
            <Drawer
                anchor="top"
                onClose={onCancel}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={askQuestion || isVideoContent}>
                {
                    askQuestion && contentId &&
                    <ContentQueryCreatePopup
                        {...props}
                        content_id={contentId}
                        onClose={() => {
                            setAskQuestion(false);
                        }}
                        onCancel={() => {
                            setAskQuestion(false);
                        }}
                    />}
                {videoContent && videoContent.content_type_id == "1" && <iframe className="video-player-frame"
                    src={getVideoUrl(video_content_url)}
                    width="100%"
                    height="564"
                    allow="autoplay; fullscreen">
                </iframe>}
                {videoContent && videoContent.content_type_id == "2" &&
                    <ReactPlayer
                        url={video_content_url}
                        controls={true} />}
                <Button className="close-me-btn" variant="contained" color="primary" onClick={onCancel}>Close me</Button>
            </Drawer>
        </div>
    )
};

