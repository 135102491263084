import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  ImageInput,
  ImageField,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
export const industryCreate = (props) => {
  return (
    <Create title="Industry Create" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="name" label="Name" validate={[required()]}/>
        <TextInput source="details" label="Details" validate={[required()]}  />
        <ImageInput source="image" label="Logo" accept="image/*">
                    <ImageField source="image" title="Logo" />
            </ImageInput>
        <BooleanInput source="isActive" label="Active" initialValue={true} />
      </SimpleForm>
      </Create>
  );
};
