import React, { useState, useCallback } from 'react';
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput, DateField,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict";
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import RealizationOtherDocumentsCreate from "./RealizationOtherDocumentsCreate";
import RealizationDocumentsCreate from "./RealizationDocumentsCreate";
import * as apiClient from "../../common/apiClient";
import RealizationDocumentEdit from "./RealizationDocumentEdit";
import { RealizationOrderPatmentFields } from "./RealizationOrderPatmentFields";
import { RealizationOrderOtherFields } from "./RealizationOrderOtherFields";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 750,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}

const UploadDocument = (props) => {
  let { record } = props;
  return (
    <div className="upload-document" onClick={() => {
      props.onClick(record);
    }}>Upload Document</div>
  )
}


export const RealizeOrderEdit = (props) => {
  const refresh = useRefresh();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const [isEditDocuments, setIsEditDocuments] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(0);
  const [isaddOtherDocuments, setIsAddOtherDocuments] = useState(false);

  const addFromSetup = (id) => {
    apiClient.addFromSetup(id).then(res => {
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }
  const onCloseDocumentForm = () => {
    setIsAddDocuments(false);
    setIsEditDocumentRecord(0);
    setIsEditDocuments(false);
    refresh();
  }
  const onCloseOtherDocumentForm = () => {
    setIsAddOtherDocuments(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddDocuments(false);
    setIsAddOtherDocuments(false);
    setIsEditDocuments(false);
  }
  const handleClose = useCallback(() => {
    setIsAddDocuments(false);
    setIsAddOtherDocuments(false);
    setIsEditDocuments(false);
  }, []);

  return (
    <React.Fragment>
      <Edit title="Realize Order Edit" {...props} undoable={false}>
        <TabbedForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} />}>
          <FormTab label="Basic Details">
            <TextInput validate={[required(), minLength(3)]} source="billNo" label="Record No" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required(), minLength(3)]} source="hBillNo" label="Hospital Bill No" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} source="pName" label="Patient Name" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required(), minLength(3)]} source="pMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} source="pEmail" label="Patient e-Mail" fullWidth={true} formClassName={classes.first_inline_input} />
            <ReferenceInput validate={[required()]} label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} formClassName={classes.last_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <DateInput validate={[required()]} label="DOA" source="doa" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput validate={[required()]} label="DOD" source="dod" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} label="Card No" source="cardNo" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required(), minLength(3)]} label="CCN No" source="ccnno" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} source="policyNo" fullWidth={true} formClassName={classes.first_inline_input} />
            <ReferenceInput validate={[required()]} label="TPA Name" source="tpaId" reference="tpas" fullWidth={true} formClassName={classes.last_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput validate={[required()]} label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.first_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput validate={[required()]} label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <DateInput validate={[required()]} label="Received At HDC" source="frecDate" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput label="File Submission Date" source="fSubDate" fullWidth={true} formClassName={classes.last_inline_input} />

            <ReferenceInput label="Status" source="statusId" reference="lookups" formClassName={classes.first_inline_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

          </FormTab>

          <FormTab label="Other Info">

          <FormDataConsumer>
              {formDataProps => (
                <RealizationOrderOtherFields {...formDataProps} />
              )}
            </FormDataConsumer>

            {/* <BooleanInput label="HDC Query Received ?" source="isHdqConf" fullWidth={true} formClassName={classes.start_twice_4_input} />
            <DateInput label="HDC Internal Query Dt " source="hdcqDt" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput label="HDC Query Rcvd. Dt" source="hdcQrDt" fullWidth={true} formClassName={classes.three_4_input} />

            <TextInput label="HDC. Internal Query" multiline={true} source="hdcqDet" fullWidth={true} />

            <BooleanInput label="Query Submitted by Hospital?" source="isHqConf" fullWidth={true} formClassName={classes.start_twice_4_input} />
            <DateInput label="Insurance Query Dt" source="hospqDt" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput label="Query Submitted Date" source="hospqsDt" fullWidth={true} formClassName={classes.three_4_input} />

            <TextInput label="Insurance Remarks" multiline source="hospqDet" fullWidth={true} />


            <BooleanInput label="Bill Raised ?" source="billRaised" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput label="Bill Raised Date" source="billRDt" fullWidth={true} formClassName={classes.last_inline_input} />

            <BooleanInput label="Invoice Raised ?" source="invRaised" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput label="Invoice Raised Date" source="rInvDt" fullWidth={true} formClassName={classes.last_inline_input} />

            <BooleanInput label="Reclaim Bill Raised ?" source="rBilRaised" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput label="Reclaim Bill Raised Date" source="rBillRDt" fullWidth={true} formClassName={classes.last_inline_input} />

            <BooleanInput label="Disputed In-Conclusive " source="isDspInCon" fullWidth={true} formClassName={classes.first_inline_input} /> */}

          </FormTab>
          <FormTab label="Payment Info.">

            <FormDataConsumer>
              {formDataProps => (
                <RealizationOrderPatmentFields {...formDataProps} />
              )}
            </FormDataConsumer>

          </FormTab>
          <FormTab label="Realization Documents">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddDocuments(true); }}>Add New</Button>
              <Button onClick={() => { addFromSetup(props.id); }}>Add From Setup</Button>
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="realiz-ord-dets"
              target="realizordId">
              <Datagrid>
                <CDeleteButton {...props} />
                {/* <TextField label="ITP" source="itp" />
                <TextField label="BillNo" source="billNo" /> */}
                <DateField showTime={true} label="Entry Date" source="entryDt" />
                <TextField label="Document Type" source="docType" />
                <TextField label="Document Value" source="docValue" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <UploadDocument onClick={(record) => { setIsEditDocumentRecord(record); setIsEditDocuments(true); }} label="Upload Document" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Other Documents">
            <div style={{ flex: 1 }}>
              <Button onClick={() => { setIsAddOtherDocuments(true); }}>Add New Document</Button>
            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="realiz-ord-docs"
              target="realizOrdId">
              <Datagrid>
                <CDeleteButton {...props} />
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit >
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isaddDocuments || isaddOtherDocuments || isEditDocuments} >

        {isaddOtherDocuments && <RealizationOtherDocumentsCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isaddDocuments && <RealizationDocumentsCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isEditDocuments && <RealizationDocumentEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          record={isEditDocumentRecord}
          {...props} />}
      </Drawer>
    </React.Fragment>
  );
};
