import React, { useState, useEffect, useCallback } from 'react';
import * as apiClient from "../../common/apiClient";

import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
  getFlatDataFromTree
} from 'react-sortable-tree';
import {
  useNotify,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { useStyles } from "../../formStyles";
import { handleCheck } from 'ra-core/esm/sideEffect/auth';
import { blue } from '@material-ui/core/colors';
export const ServiceSelection = ({ formData, ...rest }) => {
  const form = useForm();
  const classes = useStyles({});
  const [treeData, setTreeData] = useState([]);
  const [nodeState, setNodeState] = useState([]);
  const [accountId, setAccountId] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedUniqueCategory, setSelectedUniqueCategory] = useState([]);
  const TEAM_COLORS = ['Red', 'Black', 'Green', 'Blue'];
  const notify = useNotify();
  useEffect(() => {
    setAccountId({ ...rest }.id)
    loadServiceCategories({ ...rest }.id);
  }, []);



  const loadServiceCategories = (id) => {
    apiClient.get_account_service_category(id).then(res => {
      debugger
      //console.log(res);
      if (res.length > 0) {
        setSelectedCategory(res);

        const uniqueTags = [];
        const uniqueTag = [];
        res.filter(item => item.topparentid > 0).map(item => {
          if (uniqueTag.indexOf(item.topparentid) === -1) {
            uniqueTag.push(item.topparentid);
            uniqueTags.push({ topparentid: item.topparentid, topparentname: item.topparentname })
          }
        });
        setSelectedUniqueCategory(uniqueTags);
        
        // /alert(JSON.stringify({...rest}))
        // setCategories(res);
        let parentCategories = res.filter(item => item.ParentID == 0);
        //console.log(parentCategories);
        //console.log(nodeState, "parentCategories ====== nodeState=====");
        parentCategories.forEach(cat => {
          debugger
          let status = nodeState[cat.ID];
          let isExpended = false;
          if (status)
            isExpended = true;

          let child = loadChildCategories(cat, res, cat);
          cat.title = cat.ServiceName;
          cat.children = child;
          cat.expanded = isExpended;
          cat.topparentid = 0
          cat.topparentid = "";
        });
        //console.log(parentCategories);
        setTreeData(parentCategories);
       }
    }).catch(err => {
      console.log("erro : " + err);
    });
  }

  const loadChildCategories = (category, allCategories, item) => {
    let allChildrens = allCategories.filter(i => i.ParentID == category.ID);
    allChildrens.forEach(cat => {
      let status = nodeState[category.ID];
      let isExpended = false;
      if (status)
        isExpended = true;
      let child = loadChildCategories(cat, allCategories, item);
      cat.title = cat.ServiceName;
      cat.children = child;
      cat.expanded = isExpended;
      cat.topparentid = item.ID
      cat.topparentname = item.ServiceName
    });
    return allChildrens;
  }


  const handleCheck = (event, id, topparentid) => {
    let selected = 0;
    if (event.target.checked) {
      selected = 1;
    }

    apiClient.add_update_account_service_category(accountId, id, selected, topparentid).then(res => {

      if (res[0].success) {
        loadServiceCategories(accountId);
      }
      else {

        notify(res[0].message, "warning");
      }



    })

  }
  const CustomCheckBox = (...node) => {
    console.log(node);


    if (node[0].node.ParentID && node[0].node.ParentID > 0 && node[0].node.children == 0) {
      return <input type="checkbox" checked={node[0].node.selected} onChange={(event) => handleCheck(event, node[0].node.ID, node[0].node.topparentid)} />
    }
    else {
      return null
    }

  }
  return (
    <div>
      <span style={{color:"blue",fontWeight:"bold"}}>Business Solution Offerings({selectedCategory.filter(x => x.selected === true).length})</span>
      
      {selectedUniqueCategory.map((_item) => {
        return <div style={{ border: "1px solid lightgrey", marginTop: "10px", padding: 2, borderRadius: 5 }}>
          <span style={{ fontSize: "12px" }} >Selected Offerings for <span style={{color:"blue",fontWeight:"bold"}}>{_item.topparentname} ({selectedCategory.filter(x => x.selected === true && x.topparentid === _item.topparentid).length})</span></span>
          {selectedCategory.filter(x => x.selected === true && x.topparentid === _item.topparentid).map((item) => {
            return <div class="custom-chip">
              {item.ServiceName}
            </div>
          })}
        </div> 
      }  )}




      <div style={{ minheight: 500, height: 500, border: "1px solid lightgrey", marginTop: "10px", marginBottom: "10px", borderRadius: 5, padding: 20 }}>
        {treeData.length > 0 && <div > <span style={{color:"blue",fontWeight:"bold",fontSize:12}}>Select the Main service category and Sub Categories from the below(maximum 3 Main Service Categories) </span></div>}
        {treeData.length > 0 && <SortableTree canDrag={false}
          treeData={treeData}
          onVisibilityToggle={(params) => {
            let id = params.node.ID;
            const nodeStateArr = [...nodeState];
            nodeStateArr[id] = params.expanded;
            setNodeState(nodeStateArr);
            console.log(nodeStateArr, "===nodeStateArr===");
          }}
          onChange={treeData => { setTreeData(treeData); }}
          generateNodeProps={({ node, path }) => ({
            // title:node.selected?<span style={{fontSize:"30px",backgroundColor:"red"}} >{node.title}</span>:node.title,
            title:node.title,
            buttons: [
              <CustomCheckBox node={node} />
            ]
          })}
        />
        }
      </div>
    </div>
  )
}
