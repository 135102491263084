import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const UomsEdit = (props) => {
  return (
    <Edit title="Uom Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
        </SimpleForm>
    </Edit>
  );
};
