import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const RealizationDocumentsCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        realizordId: props.realizOrdId
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);

        apiClient.UploadFile("docUrl", formdata.docUrl).then(res => {
            let documentUrl = res;
            let data = {
                realizordId: parseInt(formdata.realizOrdId),
                typeId: parseInt(formdata.typeId),
                remarks: formdata.remarks,
                docUrl: documentUrl,
                itp:"",
                billNo:"",
            }
            apiClient.addEntiyRecord("realiz-ord-dets", data).then(res => {
                //console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });

        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <FileInput fullWidth={true} source="docUrl" label="Document" accept="application/pdf,image/*,application/vnd.ms-powerpoint">
                    <FileField source="docUrl" title="File" />
                </FileInput>

                {/* <TextInput source="itp" label="ITP" fullWidth={true} />
                <TextInput source="billNo" label="Bill No" fullWidth={true} /> */}
                <TextInput source="docType" label="Document name" fullWidth={true} />
                <TextInput source="docValue" label="Document value" fullWidth={true} /> 
            </SimpleForm>
        </Edit>
    );
}
export default RealizationDocumentsCreate;