import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { useStyles } from "../../formStyles";
export const LocationAddressfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});;
    return (
        <div>
            
            <ReferenceInput variant="outlined" label="Country" source="countryId" reference="countries"
                onChange={(v) => {                    
                    form.change("stateId", 0);
                }}
                validate={required()}
                fullWidth={true}
                 >
                <SelectInput optionText="name" ></SelectInput>
            </ReferenceInput>
            
            <ReferenceInput variant="outlined" label="State" source="stateId"
                reference="states" perPage={500}
                filter={{ "countryId": formData.countryId }}
                validate={required()}
                fullWidth={true}
                onChange={(v) => {
                    form.change("officeCityId", 0);
                }}
                
                >
                <SelectInput validate={required()} optionText="stateName"  />
            </ReferenceInput>


            
            <ReferenceInput variant="outlined" label="City" source="cityId"
                reference="cities" perPage={500}
                filter={{ "stateId": formData.stateId }}
                validate={required()}
                fullWidth={true}
                >
                <SelectInput validate={required()} optionText="cityName" />
            </ReferenceInput>
        </div>
    )
}
