import React, { useState, useCallback, useEffect } from "react";
import Button from '@material-ui/core/Button';
import {
  Edit,
  DateInput,
  SimpleForm,
  AutocompleteInput,
  AutocompleteArrayInput,
  SelectInput,
  TextInput,
  BooleanInput,
  NumberInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TextField, useRefresh,
  SelectArrayInput,
  useRedirect,
  minLength,
  useNotify,
  maxLength,
} from "react-admin";

import { useStyles } from "../../formStyles";
 import EditIcon from '@material-ui/icons/Edit';
 import { makeStyles } from '@material-ui/core/styles';
 import * as apiClient from "../../common/apiClient";
import CircularProgress from '@material-ui/core/CircularProgress';
import WizardToolbar from "../../common/WizardToolbar";
 import { AccountPricePositioning } from "./AccountPricePositioning";



import { ServiceSelection } from "./ServiceSelection";



const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));

export const AccountServiceEditButton = props => {
  const setAccountServiceId = () => {
    props.setAccountServiceEdit(props.record.id);
  }
  return (
    <Button
      color="secondary"
      size="medium"
      onClick={setAccountServiceId}
      startIcon={<EditIcon />}
    >Edit</Button>
  )
};
export const LinkButton = props => {

  return (
    <Button
      color="secondary"
      size="medium"
      onClick={() =>
        window.open(props.record.fileUrl, 'sharer', 'toolbar=0,status=0,width=548,height=325')
      }

    >View</Button>
  )
};



export const AccountEdit = (props) => {

  const classes = useStyles({});
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const [isAccountServiceAdd, setIsAccountServiceAdd] = useState(false);
  const [isEditExibitor, setIsEditExibitor] = useState(false);
  const [officeCounrtyId, setOfficeCountryId] = useState(0);
  const [editExibitorId, setEditExibitorId] = useState(0);
  const [editKeyMemberId, setEditKeyMemberId] = useState(0);
  const [profileStep, setProfileStep] = useState("LOADING");
  const [isAccountKeyMembers, setIsAccountKeyMembers] = useState(false);
  const [isOfficeAddress, setIsOfficeAddress] = useState(false);
  const [isAccountIndustries, setIsAccountIndustries] = useState(false);
  const [isAccountCustomerLocation, setIsAccountCustomerLocation] = useState(false);
  const [isAccountClients, setIsAccountClients] = useState(false);
  const [isAccountBusinessProof, setIsAccountBusinessProof] = useState(false);
  const [isAccountKeyMgmt, setIsAccountKeyMgmt] = useState(false);
  const [isAccountCard, setIsAccountCard] = useState(false);
  const [isAccountRecoginition, setIsAccountRecoginition] = useState(false);
  const [isAccountRatingMap, setIsAccountRatingMap] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);
  const [isVendorProfile, setIsVendorProfile] = useState(false);
  const [isServicesCompleted, setIsServicesCompleted] = useState(false);
  const [isCompliancesCompleted, setIsCompliancesCompleted] = useState(false);
  const [isRecognitionCompleted, setIsRecognitionCompleted] = useState(false);
  const notify = useNotify();
  const redirect=useRedirect();



  const validateProfileDetails = [required(), minLength(100), maxLength(1000)];
  const validateBigProject = [minLength(100), maxLength(1000)];
  const handlePrevious = async () => {

    let _hash = window.location.hash.toString().split('/')[0] + '/' + window.location.hash.toString().split('/')[1] + '/' + window.location.hash.toString().split('/')[2]
    let step = 0;
    if (profileStep === "VENDORPROFILE") {
      setProfileStep("PROFILE");
    }
    if (profileStep === "SERVICES") {
      setProfileStep("VENDORPROFILE");
      step = 1
    }

    if (profileStep === "COMPLIANCES") {
      setProfileStep("SERVICES");
      step = 2
    }
    if (profileStep === "RECOGNITION") {
      setProfileStep("COMPLIANCES");
      step = 3
    }

    if (step > 0) {
      window.location.hash = _hash + "/" + step;
    }
    else {
      window.location.hash = _hash;
    }



  }
  const handleSubmit = async (formdata) => {
    debugger
    console.log(formdata);
    
    
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    formdata.userId=user;
      apiClient.update_Profile_Action(formdata).then(res => {
        debugger
        if (res) {
          if (res[0].success) {
            notify(res[0].message);
            redirect("/accounts")
          }
          else {
            notify(res[0].message, "warning");
          }

        }
        console.log(res);

      }).catch(err => {
        console.log(err);
      });
    

  }

  const setAccountKeyMembers = (id) => {
    setEditExibitorId(id);
    setIsAccountKeyMembers(true);
  }
  const setOfficeAddress = (id) => {
    setEditExibitorId(id);
    setIsOfficeAddress(true);
  }

  const setAccountIndustries = (id) => {
    setEditExibitorId(id);
    setIsAccountIndustries(true);
  }
  const setAccountCustomerLocation = (id) => {
    setEditExibitorId(0);
    setIsAccountCustomerLocation(true);
  }
  const setAccountClients = (id) => {
    setEditExibitorId(0);
    setIsAccountClients(true);
  }
  const setAccountBusinessProof = (id) => {
    setEditExibitorId(0);
    setIsAccountBusinessProof(true);
  }

  const setAccountKeyMgmt = (id) => {
    setEditExibitorId(0);
    setIsAccountKeyMgmt(true);
  }
  const setAccountCard = (id) => {
    setEditExibitorId(0);
    setIsAccountCard(true);
  }

  const setAccountRecoginition = (id) => {
    setEditExibitorId(0);
    setIsAccountRecoginition(true);
  }

  const setAccountRatingMap = (id) => {
    setEditExibitorId(0);
    setIsAccountRatingMap(true);
  }




  const setAccountKeyMembersEdit = (id) => {
    debugger
    setEditKeyMemberId(id);
    setIsAccountKeyMembers(true);
  }


  const closeExbhitorEdit = () => {
    setIsEditExibitor(false);
    refresh();
  }
  const closeAccountServiceForm = () => {
    setEditKeyMemberId(0);
    setIsAccountKeyMembers(false);
    setIsAccountIndustries(false);
    setIsAccountCustomerLocation(false);
    setIsAccountClients(false);
    setIsAccountBusinessProof(false);
    setIsAccountCard(false);
    setIsAccountKeyMgmt(false);
    setIsOfficeAddress(false);
    setIsAccountRecoginition(false);
    setIsAccountRatingMap(false);
    //refresh();
  }
  const onCancel = () => {
    setIsAccountServiceAdd(false);
    setIsEditExibitor(false);
    setIsAccountKeyMembers(false);
    setIsAccountIndustries(false);
    setIsAccountCustomerLocation(false);
    setIsAccountClients(false);
    setIsAccountBusinessProof(false);
    setIsAccountKeyMgmt(false);
    setIsAccountCard(false);
    setIsOfficeAddress(false);
    setIsAccountRecoginition(false);
    setIsAccountRatingMap(false);
  }
  const handleClose = useCallback(() => {
    setIsAccountServiceAdd(false);
  }, []);
  const EditButtonKeyMember = (props) => {
    let { record } = props;
    return <Button onClick={() => setAccountKeyMembersEdit(record.id)}  > Edit </Button>

  }
  useEffect(() => {

    debugger
    if (profileStep != "LOADING") return;
    if (!props.id) return;
    apiClient.getAccount(props.id).then(res => {
      console.log(res, "====res====");
      if (res && res.profileStep) {
        setProfileStep(res.profileStep);
      } else {
        setProfileStep("PROFILE");
        apiClient.updateProfile(props.id, "PROFILE");
      }
    }).catch(err => console.log(err));
  }, []);
  return (
    <React.Fragment>
      {profileStep && profileStep == "LOADING" && <CircularProgress color="secondary" />}
      {profileStep && profileStep != "LOADING" && <Edit title="Profile  " {...props}>
        <TabbedForm syncWithLocation={false} disabled={true}
          toolbar={<WizardToolbar label={"Save"} onSave={handleSubmit}
            showPrevious={false}
            onClose={handlePrevious} onCancel={handlePrevious} />}
          variant="outlined">

          {<FormTab value="DETAILS1" label="Account Detail">

            <TextInput disabled={true} source="accountName" label="Business Name" formClassName={classes.one_three_input} fullWidth={true} />
            <ReferenceInput label="Company Type" source="accountTypeId" sort={{ field: 'sno', order: 'ASC' }} reference="status-groups" filter={{ groupId: 4 }} formClassName={classes.two_three_input} fullWidth={true}>
              <SelectInput disabled={true} optionText="statusName" />
            </ReferenceInput>



            <NumberInput disabled={true} source="yearofCorp" label="Year of Incorporation " length={4} formClassName={classes.last_three_input} fullWidth={true} />
            <ReferenceInput disabled={true} label="Number Of Employee" source="noOfEmployeeId" reference="status-groups" filter={{ groupId: 5 }} formClassName={classes.one_three_input} fullWidth={true} sort={{ field: 'sno', order: 'ASC' }}  >
              <SelectInput disabled={true} optionText="statusName" />
            </ReferenceInput>
            <TextInput disabled={true} source="webSite" label="Business Website " formClassName={classes.two_three_input} fullWidth={true} />
            <TextInput disabled={true} source="contactName" label="Primary Contact Person Name" formClassName={classes.last_three_input} fullWidth={true} />
            <TextInput disabled={true} source="contactEmail" label="Work Email" formClassName={classes.one_three_input} fullWidth={true} />
            <TextInput disabled={true} source="contactMobile" label="Mobile Number" formClassName={classes.two_three_input} fullWidth={true} />
            <TextInput disabled={true} source="contactPhone" label="Office Phone Number" formClassName={classes.last_three_input} fullWidth={true} />

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline", cursor: "pointer" }} title="You can setup main office address"   >Office Address :</span>
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              filter={{ officeTypeId: 21 }}
              reference="account-office-addresses"
              target="accountId">
              <Datagrid>

                <TextField label="Address 1" source="address1" />
                <TextField label="Address 2" source="address2" />
                <ReferenceField source="countryId" reference="countries" label="Country" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="stateName" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                  <TextField source="cityName" />
                </ReferenceField>
                <TextField source="zipCode" label="Pincode" />
              </Datagrid>
            </ReferenceManyField>

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline", cursor: "pointer" }} title="You can add more other office addresses" >Other Office Address :</span>
            </div>

            {!isOfficeAddress && <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              filter={{ officeTypeId: 22 }}
              reference="account-office-addresses"
              target="accountId">
              <Datagrid>

                <TextField label="Address 1" source="address1" />
                <TextField label="Address 2" source="address2" />
                <ReferenceField source="countryId" reference="countries" label="Country" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="stateName" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                  <TextField source="cityName" />
                </ReferenceField>
                <TextField source="zipCode" label="Pincode" />
              </Datagrid>
            </ReferenceManyField>}


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline", cursor: "pointer" }} title="You can add Key Team Members" >Key Team Members : </span>
            </div>

            {!isAccountKeyMembers && <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="account-key-members"
              target="accountId">
              <Datagrid>


                <TextField label="Name" source="name" />
                <TextField label="Designation" source="designation" />
                <TextField label="Email" source="email" />
                <TextField label="Contact No" source="contactNo" />
              </Datagrid>
            </ReferenceManyField>}

          </FormTab>}
          {<FormTab value="DETAILS2" label="Business Information"  >
            <TextInput disabled={true} source="profileDetails" label="Business Summary (minimum 100 and maximum 1000 characters)" multiline={true} validate={validateProfileDetails} fullWidth={true} />

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Account Industries :</span>
            </div>

            {!isAccountIndustries && <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="account-industries"
              target="accountId">
              <Datagrid>

                <ReferenceField source="industryId" reference="industries" label="Industry" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              </Datagrid>
            </ReferenceManyField>}



            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Customer Location (Location of customers served) :</span>
            </div>



            {!isAccountCustomerLocation && <ReferenceManyField fullWidth={true}
              addLabel={false}

              reference="account-cust-locations"
              target="accountId">
              <Datagrid>


                <ReferenceField source="countryId" reference="countries" label="Country" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="stateName" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                  <TextField source="cityName" />
                </ReferenceField>
                <TextField source="zipCode" label="Pincode" />
              </Datagrid>
            </ReferenceManyField>}



            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Clients Served :</span>
            </div>



            {!isAccountClients && <ReferenceManyField fullWidth={true}
              addLabel={false}

              reference="account-clients"
              target="accountId">
              <Datagrid>

                <TextField source="name" label="Client Name" />
                <TextField source="website" label="Website" />
              </Datagrid>
            </ReferenceManyField>}
          </FormTab>
          }
          {/*profileStep === "SERVICES" &&  */}
          {<FormTab value="DETAILS2" label="Services"  >
            <ReferenceInput label="Annual Turnover" source="annualTurnOverId" reference="status-groups" filter={{ groupId: 8 }} formClassName={classes.one_4_input} fullWidth={true} sort={{ field: 'sno', order: 'ASC' }} >
              <SelectInput disabled={true} optionText="statusName" />
            </ReferenceInput>
            <ReferenceInput perPage={5000} label="Currency" source="anualTurnoverCurrencyId" reference="currency" formClassName={classes.two_4_input} filter={{ id: 64 }} fullWidth={true} initialValue={64} >
              {/* <SelectInput optionText="currencyName"  /> */}
              <SelectInput disabled={true} optionText="currencyName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>



            <ReferenceInput label="Annual Turnover Range" source="anualTurnoverRangeId" reference="status-groups" filter={{ groupId: 9, isActive: 1 }} formClassName={classes.three_4_input} fullWidth={true}>
              <SelectInput disabled={true} optionText="statusName" />
            </ReferenceInput>
            <ReferenceInput label="Primary Source of Revenue" source="primarySourceId" reference="service-categories" filter={{ parentId: 0 }} formClassName={classes.last_4_input} fullWidth={true}>
              <SelectInput disabled={true} optionText="serviceName" />
            </ReferenceInput>


            <FormDataConsumer  >
              {formDataProps => (
                <AccountPricePositioning {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput disabled={true} source="pricePositioningDetails" label="Price Positioning Details" multiline={true} fullWidth={true} />
            <ServiceSelection {...props} />



            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Mention Any Big 3 Projects:</span>
            </div>
            <TextInput disabled={true} source="bigProjects1" label="Big Projects 1" multiline={true} validate={validateBigProject} fullWidth={true} />
            <TextInput disabled={true} source="bigProjects2" label="Big Projects 2" multiline={true} validate={validateBigProject} fullWidth={true} />
            <TextInput disabled={true} source="bigProjects3" label="Big Projects 3" multiline={true} validate={validateBigProject} fullWidth={true} />


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>References:</span>
            </div>
            <TextInput disabled={true} source="ref1" label="Reference Name 1" fullWidth={true} />
            <TextInput disabled={true} source="ref2" label="Reference Name 2" fullWidth={true} />
            <TextInput disabled={true} source="ref3" label="Reference Name 3" fullWidth={true} />
            <TextInput disabled={true} source="ref4" label="Reference Name 4 " fullWidth={true} />
            <TextInput disabled={true} source="ref5" label="Reference Name 5" fullWidth={true} />


          </FormTab>
          }
          {<FormTab value="DETAILS2" label="Compliances and Documentation"  >
            <TextInput disabled={true} source="gstno" label="GST No" fullWidth={true} formClassName={classes.one_three_input} />
            <TextInput disabled={true} source="panno" label="PAN NO" fullWidth={true} formClassName={classes.two_three_input} />
            <TextInput disabled={true} source="msmeno" label="MSME Regn No" fullWidth={true} formClassName={classes.last_three_input} />
            <ImageField source="gstUrl1" label="GST Doc" title="GST Certificate " formClassName={classes.one_three_input} />
            <ImageField source="panurl1" title="GST Certificate " formClassName={classes.two_three_input} />
            <ImageField source="msmeurl1" title="MSME Regn Certificate Scan Copy " formClassName={classes.two_three_input} />

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Proof of Primary Place of Business (Latest Electricity bill, telephone bill, other) : :</span>
            </div>



            {!isAccountBusinessProof && <ReferenceManyField fullWidth={true}
              addLabel={false}
              filter={{ proofType: "PROOF" }}
              reference="account-image-maps"
              target="accountId">
              <Datagrid >

                <TextField source="imageName" label="Proof" />
                <LinkButton label="" source="fileUrl" />
              </Datagrid>
            </ReferenceManyField>}


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Photo of the Key Management : </span>
            </div>



            {!isAccountKeyMgmt && <ReferenceManyField fullWidth={true}
              addLabel={false}
              filter={{ proofType: "KEYMGMT" }}
              reference="account-image-maps"
              target="accountId">
              <Datagrid >

                <TextField source="imageName" label="Designation" />
                <LinkButton label="" source="fileUrl" />
              </Datagrid>
            </ReferenceManyField>}

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Business Card  : </span>
            </div>



            {!isAccountCard && <ReferenceManyField fullWidth={true}
              addLabel={false}
              filter={{ proofType: "CARD" }}
              reference="account-image-maps"
              target="accountId">
              <Datagrid >

                <TextField source="imageName" label="Card" />
                <LinkButton label="" source="fileUrl" />
              </Datagrid>
            </ReferenceManyField>}


          </FormTab>}

          {
            <FormTab value="DETAILS2" label="Recognition and Awards"  >
              <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
                <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Recoginitions :</span>
              </div>



              {!isAccountRecoginition && <ReferenceManyField fullWidth={true}
                addLabel={false}
                reference="account-recognitions"
                target="accountId">
                <Datagrid >

                  <TextField source="criteria" label="Criteria" />
                  <TextField source="name" label="Name of the Recognition/ Award" />
                  <LinkButton label="" source="url" />
                </Datagrid>
              </ReferenceManyField>}


              <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
                <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Ratings on other online platforms : </span>
              </div>



              {!isAccountRatingMap && <ReferenceManyField fullWidth={true}
                addLabel={false}

                reference="account-rating-maps"
                target="accountId">
                <Datagrid >

                  <TextField source="platform" label="Platform Name" />
                  <TextField source="link" label="Link To Profile" />
                  <TextField source="ratingCount" label="Rating on the platform" />

                </Datagrid>
              </ReferenceManyField>}


            </FormTab>
          }

          <FormTab value="DETAILS2" label="Approver Action"  >
            <ReferenceInput label="Action" source="statusId" reference="status-groups" filter={{ groupId: 12 }} formClassName={classes.one_4_input} fullWidth={true} sort={{ field: 'sno', order: 'ASC' }} >
              <SelectInput optionText="statusName" validate={[required()]} />
            </ReferenceInput> 
            <TextInput source="remarks" label="Remarks" multiline={true} fullWidth={true} validate={[required()]} />
          </FormTab>




        </TabbedForm>

      </Edit>}

    </React.Fragment>
  );
};
