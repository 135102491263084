import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import moment from "moment";

export const JoborderCreate = (props) => {
  let user: any = localStorage.getItem("userId");
  if (user) {
    user = parseInt(user);
  }

let initialValues={

  customerId: null,
  jobNumber: "1",
  jobDate: moment().toISOString(),
  jobStatusId: 176,
  leadTime: "",
  requestedComments: "",
  inquiryDate: moment().toISOString(),
  requestedDelvDate: moment().toISOString(),
  totalJobAmount: "",
  totalGrossAmount: "",
  insertedBy: user,
  insertedAt: moment().toISOString(),
  updatedBy: 0,
  updatedAt:moment().toISOString(),
  isActive: true,
  approvalComment: "",
  isApproved: false,
  isJobFinalized: false
}

 
  const classes = useStyles({});
  return (
    <Create title="Create Job Order" {...props}>
      <SimpleForm redirect="list" initialValues={initialValues} toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" > 
            <DateInput source="jobDate" label="Job Date" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <ReferenceInput label="Customer" perPage={5000} source="customerId" filter={{ accountTypeId: 2 ,statusId:170 }} reference="accounts" formClassName={classes.two_three_input} validate={[required()]} sort={{ field: 'accountName', order: 'ASC' }}  fullWidth={true} >
              <SelectInput optionText="accountName" />
            </ReferenceInput>
            <TextInput source="leadTime" label="Lead Time" validate={[required()]}  formClassName={classes.last_three_input} fullWidth={true}/> 
            <TextInput source="requestedComments" multiline label="Requested Comments"  fullWidth={true}/>
            <DateInput source="inquiryDate" label="Inquiry Date" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <DateInput source="requestedDelvDate" label="Requested Delivery Date" validate={[required()]} formClassName={classes.two_three_input} fullWidth={true} />
            <NumberInput source="totalJobAmount"  label="Total Job Amount"  validate={[required()]}  formClassName={classes.last_three_input}   fullWidth={true}/>
            <NumberInput source="totalGrossAmount"  label="Total Gross Amount"  validate={[required()]}  formClassName={classes.one_three_input}   fullWidth={true}/>
      </SimpleForm>
    </Create>
  );
};
