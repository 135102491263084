import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useTranslate } from 'react-admin';
import CommentIcon from '@material-ui/icons/Comment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 10,
        width:250, 
        marginBottom:15,
        position:"relative"
    },
    card: {
        overflow: 'inherit',
        textAlign: 'center',
        padding: 11,
        minHeight: 52,
    },
    img:{
        height:83,
        width:83,
        position:"center",
        left:2,
        top:2,
    }
});

const CountBlock = ({ title, value, icon }) => {
    const translate = useTranslate();
    const classes = useStyles({});
    let circlecolor="black";
    if(isNaN(value))
    {
        value=0;
    }
    if(value>50 && value<100)
    {
        circlecolor="yellow"
    }
    if(value>99)
    {
        circlecolor="green"
    }
    return (
        <div className={classes.main}>
            {!icon && <CardIcon Icon={CommentIcon} bgColor="#f44336" />}
            
            <Card className={classes.card}>
                <div>
                {icon && icon != "" && <img className={classes.img} src={icon} bgColor="#f44336" />}
                </div>
                <Typography className={classes.title} color="textSecondary">
                {title}
                </Typography>
                
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress style={{ color: circlecolor }}  variant="determinate" value={100} />
      <Box
        sx={{
          marginTop: 10,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}% Done  `}
        </Typography>
      </Box>
    </Box>
                {/* <Typography variant="h5" component="h2">
                    {value}
                </Typography> */}
                 
            </Card>
        </div>
    );
};

export default CountBlock;
