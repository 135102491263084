import React ,{ useState,useCallback }from "react";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  required,
  FormTab,
  DateInput,
  DateField,
  ReferenceManyField, ReferenceField, Datagrid,
  useRefresh,
  TextField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import ServiceCreate from "./ServiceCreate";
const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 0,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: "80%",
  },
}));

export const JoborderEdit = (props) => {
  const [isadd, setIsAdd] = useState(false);
  const classes = useStyles({});
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const closeEdit = () => {
    setIsAdd(false); 
    refresh();
  } 
  const onCancel = () => {
    setIsAdd(false); 
  } 
  const handleClose = useCallback(() => {
      setIsAdd(false); 
  }, []);
  
  return (
    <React.Fragment>
    <Edit title="Job Order Edit" {...props}>
      
      <TabbedForm variant="outlined" redirect="list" toolbar={<FormToolbar {...props} />}>
          <FormTab value="DETAILS" label="Job Order">
          <DateInput source="jobDate" label="Job Date" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <ReferenceInput label="Customer" perPage={5000} source="customerId" filter={{ accountTypeId: 2 ,statusId:170 }} reference="accounts" formClassName={classes.two_three_input} validate={[required()]} sort={{ field: 'accountName', order: 'ASC' }}  fullWidth={true} >
              <SelectInput optionText="accountName" />
            </ReferenceInput>
            <TextInput source="leadTime" label="Lead Time" validate={[required()]}  formClassName={classes.last_three_input} fullWidth={true}/> 
            <TextInput source="requestedComments" multiline label="Requested Comments"  fullWidth={true}/>
            <DateInput source="inquiryDate" label="Inquiry Date" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <DateInput source="requestedDelvDate" label="Requested Delivery Date" validate={[required()]} formClassName={classes.two_three_input} fullWidth={true} />
            <NumberInput source="totalJobAmount"  label="Total Job Amount"  validate={[required()]}  formClassName={classes.last_three_input}   fullWidth={true}/>
            <NumberInput source="totalGrossAmount"  label="Total Gross Amount"  validate={[required()]}  formClassName={classes.one_three_input}   fullWidth={true}/>
       </FormTab>
       <FormTab value="DETAILS" label=" Order Detail">
       <div style={{ flex: 1 }}>
              <Button onClick={()=>setIsAdd(true)} >Add New Service</Button>
              
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="job-order-details"
              target="jobOrderId">
              <Datagrid>
                <CDeleteButton {...props} /> 
                <ReferenceField source="serviceCategoryId" reference="service-categories" label="Service"  link={false}>
                <TextField source="serviceName" />
                </ReferenceField> 
                <ReferenceField source="orderLineStatusId" reference="status-groups" label="Status"  link={false}>
                <TextField source="statusName" />
                </ReferenceField> 
                <TextField label="Quantity" source="quantity" />
                <TextField label="Total Cost" source="jobLineTotalCost" />
                {/* <DateField  label="Expiration Date" source="expirationDate" />
                <TextField label="Extended Price" source="extendedPrice" /> */}
                {/* <TextField label="Provider Name" source="providerName" />
                <TextField label="Customer Target Cost" source="customerTargetCost" />
                <TextField label="Other Cost" source="otherCost" />
                <TextField label="Tax Percent" source="taxHeadPc" /> */}

                
                
                
            
            {/* expirationDate: formdata.expirationDate,
            extendedPrice: formdata.extendedPrice,
            deliverDate: formdata.deliverDate,
            providerName: formdata.providerName,
            orderLineStatusId: 176,
            customerTargetCost: formdata.customerTargetCost,
            otherCost:  formdata.otherCost,
            processCost:  formdata.processCost,
            taxHeadId: formdata.taxHeadId,
            taxHeadPc: formdata.taxHeadPc,
            taxAmount: formdata.taxAmount,
            jobLineTotalCost: formdata.jobLineTotalCost,
            insertedBy: user,
            insertedAt: moment().toISOString(),
            updatedBy: 0,
            updatedAt:moment().toISOString(),
            isApproved: false */}
                
              </Datagrid>
            </ReferenceManyField>
       </FormTab>
        </TabbedForm>
    </Edit>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper, }}
    open={isadd} >
      {isadd &&  <ServiceCreate
                     onClose={closeEdit}
                     onCancel={onCancel}
                     jobOrderId={props.id}
                     {...props} />}
       
      </Drawer>

</React.Fragment>
  );
};
