import React from "react";
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput, DateField, Create,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict"
import { useStyles } from "../../formStyles";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';


export const RealizeOrderCreate = (props) => {
  const classes = useStyles({});
  let defaultValues = {
    itp: "I1",
    date: moment().toISOString(),
    hospDisc: 0,
    claimAmt: 0,
    uDisAllAmt: 0,
    jDisAllAmt: 0,
    uDisAllAmtPer: 0,
    rpriotiyId: 14,
    hospqDet: "",
    hdcqDet: "",
    settleAmt: 0,
    payment: 0,
    tdsamt: 0,
    invRaised: false,
    othDisc: 0,
    disAllAmt: 0,
    disAllDet: "",
    reclAmt: 0,
    reclRecAmt: 0,
    reclNeftNo: "",
    billRaised: false,
    rBilRaised: false,
    rInvRaised: false,
    isDspInCon: false,
  };


  return (
    <Create title="Add Realize Order" {...props} >
      <SimpleForm
        initialValues={defaultValues}
        redirect="edit"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined">
        <TextInput disabled={true} source="billNo" label="Record No" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput validate={[required(), minLength(3)]} source="hBillNo" label="Hospital Bill No" fullWidth={true} formClassName={classes.last_inline_input} />

        <TextInput validate={[required(), minLength(3)]} source="pName" label="Patient Name" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput validate={[required(), minLength(3)]} source="pMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.last_inline_input} />

        <TextInput validate={[required(), minLength(3)]} source="pEmail" label="Patient e-Mail" fullWidth={true} formClassName={classes.first_inline_input} />
        <ReferenceInput validate={[required()]} label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} formClassName={classes.last_inline_input}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <DateInput validate={[required()]} label="DOA" source="doa" fullWidth={true} formClassName={classes.first_inline_input} />
        <DateInput validate={[required()]} label="DOD" source="dod" fullWidth={true} formClassName={classes.last_inline_input} />

        <TextInput validate={[required(), minLength(3)]} label="Card No" source="cardNo" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput validate={[required(), minLength(3)]} label="CCN No" source="ccnno" fullWidth={true} formClassName={classes.last_inline_input} />

        <TextInput validate={[required(), minLength(3)]} source="policyNo" fullWidth={true} formClassName={classes.first_inline_input} />
        <ReferenceInput validate={[required()]} label="TPA Name" source="tpaId" reference="tpas" fullWidth={true} formClassName={classes.last_inline_input}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput validate={[required()]} label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.first_inline_input}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput validate={[required()]} label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <DateInput validate={[required()]} label="Received At HDC" source="frecDate" fullWidth={true} formClassName={classes.first_inline_input} />
        <DateInput label="File Submission Date" source="fSubDate" fullWidth={true} formClassName={classes.last_inline_input} />
      </SimpleForm>
    </Create>
  );
};
