
import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput

} from "react-admin";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Work Order Number" source="jobNumber~like" alwaysOn /> 
        <ReferenceInput source="jobStatusId" reference="status-groups" label="Status" sort={{ field: 'statusName', order: 'ASC' }}  link={false} filter={{ groupId: 13 }} >
                <SelectInput optionText="statusName" />
            </ReferenceInput>
            <ReferenceInput source="customerId" reference="accounts" label="Customer" sort={{ field: 'accountName', order: 'ASC' }} filter={{ accountTypeId: 2 }}  link={false}>
                <SelectInput optionText="accountName" />
            </ReferenceInput>

    </Filter>
);
export const JoborderList = (props) => {
  return (
    <List {...props} title="Work Order List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="jobNumber" label="Work Order Number" />
        <DateField  source="jobDate" label="Job Date"/> 
        <ReferenceField source="jobStatusId" reference="status-groups" label="Status"  link={false}>
                <TextField source="statusName" />
            </ReferenceField> 
        <ReferenceField source="customerId" reference="accounts" label="Customer"  link={false}>
                <TextField source="accountName" />
            </ReferenceField> 
        <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/>
        
        
      </Datagrid>
    </List>
  );
};
