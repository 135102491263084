import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
  FormDataConsumer
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import moment from "moment";
export const UsersCreate = (props) => {
  const classes = useStyles({});
  let user: any = localStorage.getItem("userId");
      if (user) {
        user = parseInt(user);
      }
  
 let initialValues={
    
  "userName":"",
  "password":"",
  "emailAddress":"",
  "timeZoneId":68,
  "languageId":1,
  "address1":"",
  "address2":"",
  "city":"",
  "state":"",
  "zipCode":"",
  "countryId":0,
  "firstName":"",
  "lastName":"",
  "nickName":"",
  "gender":"",
  "imageUrl":"",
  "mobileNumber":"",
  "registerVia":"Web",
  "weChatId":"",
  "facebookId":"",
  "googlePlusId":"",
  "qqid":"",
  "linkedInId":"",
  "company":"",
  "designation":"",
  "qrCode":"",
  "otp":0,
  "resendOtp":0,
  "website":"",
  "userType":0,
  "roleTypeId":0,
  "isMobileVerified":false,
  "isEmailVerified":false,
  "passwordResetToken":"-",
  "passwordTokenExpire":0,
  "uuid":"",
  "otherMobile":"",
  "accountId":-1,
  "qrId":"",
  "stateId":0,
  "cityId":0,
  "meetingGuid":"",
  "meetingStatus":false,
  "userMeetingStatus":false,
  "userMeetingUrl":"",
  "insertedBy":user,
  "insertedAt":moment().toISOString(),
  "updatedBy":user,
  "updatedAt":moment().toISOString(),
  "isActive":true,
  
  
}
  return (
    <Create title="User Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={initialValues}>
      <ReferenceInput label="Role" source="roleTypeId" sort={{ field: 'roleName', order: 'ASC' }} reference="roletypes" formClassName={classes.one_three_input}>
              <SelectInput optionText="roleName" validate={[required()]} />
      </ReferenceInput>
      {/* <ReferenceInput label="Type" formClassName={classes.two_three_input} 
                    perPage={5000} source="typeId"
                    filter={{ groupId: 11 }}
                    sort={{ field: 'sno', order: 'ASC' }}
                    reference="status-groups" >
                    <SelectInput optionText="statusName" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput> */}
       {/* <TextInput source="code"  validate={[required()]} formClassName={classes.last_three_input}/> */}
       <TextInput source="firstName" label="Name" formClassName={classes.two_three_input}  />
        <TextInput source="userName" formClassName={classes.last_three_input}  />
        
        <PasswordInput source="password" formClassName={classes.one_three_input}  />
        {/* <DateInput source="startDate" formClassName={classes.last_three_input}/>
        <DateInput source="endDate" formClassName={classes.one_three_input}  />
        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.two_three_input}  />
        <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.last_three_input}/> */}
        {/* <BooleanInput source="isSuperUser" validate={[required()]}  label="Super User" formClassName={classes.two_three_input}  />  */}
        <BooleanInput source="isActive" validate={[required()]}  label="Active"  formClassName={classes.two_three_input}  />
        
        <FormDataConsumer formClassName={classes.two_three_input}>
          {({ formData, ...rest }) =>
            formData.typeId &&
            formData.typeId === 10 &&
             (
              <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" formClassName={classes.one_three_input} variant="outlined" >
                <SelectInput optionText="name"  validate={[required()]}  variant="outlined" formClassName={classes.one_three_input} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        
        
      </SimpleForm>
    </Create>
  );
};
