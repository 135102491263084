import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountRecoginition = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    let name="PROOf";
    if(props.docType==="CARD")
    {
        name="Card"
    }
    if(props.docType==="KEYMGMT")
    {
        name="Designation"
    }
    const handleSubmit = async (formdata) => {
        debugger
        let data = {
            accountId: parseInt(props.accountid),
            criteria:formdata.criteria,
            year:formdata.year,
            name:formdata.name,
            url: formdata.fileUrl1.fileUrl1,
            insertedBy: 0,
            insertedAt: "2021-12-19T15:14:15.824Z",
            updatedBy: 0,
            updatedAt: "2021-12-19T15:14:15.824Z",
            isActive: true,
 
        }
        // {
        //     "accountId": 0,
        //     "criteria": "string",
        //     "name": "string",
        //     "year": 0,
        //     "url": "string",
        //     "insertedAt": "2022-01-02T10:15:04.698Z",
        //     "insertedBy": 0,
        //     "updatedAt": "2022-01-02T10:15:04.698Z",
        //     "updatedBy": 0,
        //     "isActive": true
        //   }
        apiClient.addEntiyRecord("account-recognitions", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });


    }
    return (
        <div>
            {<Create {...props} resource="account-recognitions" redirect="false">
                <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined"> 
                    <TextInput source="criteria" label="Criteria" validate={[required()]} fullWidth={true} multiline />
                    <TextInput source="name" label="Name of the Recognition/ Award" validate={[required()]} fullWidth={true} />
                    <NumberInput source="year" label="Year" validate={[required(),minLength(4), maxLength(4)]} fullWidth={true} />
                    <ImageInput validate={[required()]}  fullWidth={true} source="fileUrl1" label="Image"accept="image/*" fullWidth={true}>
                        <ImageField source="fileUrl1" title={name} />
                    </ImageInput>


                </SimpleForm>
            </Create>

            }
        </div>
    );
}
