import React from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import CommentIcon from '@material-ui/icons/Comment';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: 10,
        marginTop: 10,
        width:220,
        marginBottom:0,
        position:"relative"
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 10,
        minHeight: 40,
    },
    img:{
        height:53,
        position:"absolute",
        left:5,
        top:15,
    }
});

const CountBlock = ({ title, value, icon }) => {
    const translate = useTranslate();
    const classes = useStyles({});
    return (
        <div className={classes.main}>
            {!icon && <CardIcon Icon={CommentIcon} bgColor="#f44336" />}
            {icon && icon != "" && <img className={classes.img} src={icon} bgColor="#f44336" />}
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                {title}
                </Typography>
                <Typography variant="h5" component="h2">
                    {value}
                </Typography>
            </Card>
        </div>
    );
};

export default CountBlock;
