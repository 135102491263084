import React, { useState, useCallback,useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,ReferenceArrayInput,AutocompleteArrayInput,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,ChipField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer,SingleFieldList
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { useStyles } from "../../formStyles";
export const AccountPricePositioning = ({ formData, ...rest }) => {
    const[pricesegement,setPricesegement]=useState([]) 
    const form = useForm();
    useEffect(() => { 
        getAccountPricesegement({ ...rest }.record.id)
      }, []);
       const getAccountPricesegement = (id) => {
         
        apiClient.getAccountPrimarySegments(id).then(res => {
            setPricesegement(res)
            
            
          }).catch(err => {
            console.log("erro : " + err);
          });
    }
    const classes = useStyles({});;
    return (
        <div style={{display:"flex"}}  >
            <div style={{width:"32%"}} >
            {/* <ReferenceInput variant="outlined" label="Primary  Segment" source="customerSegmentsId" reference="status-groups" filter={{ groupId: 7 }} formClassName={classes.last_three_input} fullWidth={true} >
              <SelectInput optionText="statusName" validate={[required()]} />
            </ReferenceInput> */}
            {/* <ReferenceArrayInput variant="outlined"  label="Primary  Segment" source="primaySegementId" reference="status-groups" filter={{ groupId: 7 }} formClassName={classes.last_three_input} fullWidth={true}>
              <AutocompleteArrayInput variant="outlined"  optionText="statusName" />
            </ReferenceArrayInput> */}
            <span style={{ fontSize: "12px" }} >Primary  Segment </span>
            {pricesegement.map((item)=>{ 
                        return <div class="custom-chip">
                        {item.StatusName}
                      </div>
                     } 
            )}

            
            </div>
            <div style={{marginLeft:"20px",marginTop:"10px", width:"12%",verticalAlign:"middle"}} >
                    Price Positioning:-
            </div>
        <BooleanInput disabled={true} variant="outlined" source="pricePositioningIDEconomy" label="Economy " 
        formClassName={classes.two_four_input} 
        initialValue={true}
        onChange={(v) => {                    
            form.change("pricePositioningIDValue", false);
            form.change("pricePositioningIDPremium", false);
            
        }}
        />
        <BooleanInput variant="outlined" source="pricePositioningIDValue" label="Value"
         initialValue={false}
         disabled={true}
         formClassName={classes.three_four_input} 
         onChange={(v) => {                    
            form.change("pricePositioningIDEconomy", false);
            form.change("pricePositioningIDPremium", false);
            
        }}
          />
        <BooleanInput variant="outlined" source="pricePositioningIDPremium" label="Premium"
        formClassName={classes.last_four_input} 
        disabled={true}
        onChange={(v) => {                    
            form.change("pricePositioningIDEconomy", false);
            form.change("pricePositioningIDValue", false);
            
        }}
         initialValue={false} />
        </div>
    )
}
