import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";



export const ConfigsList = (props) => {
  return (
    <List {...props} title="Configration"  >
      <Datagrid rowClick="edit">
        <EditButton />
        
        <TextField source="mconame" label="Company Name" />
        <TextField source="add1" label="Address1" />
        <TextField source="add2" label="Address2" />
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date"/>
            </Datagrid>      
    </List>
  );
};
