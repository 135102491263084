import * as http from "./http";


export const isDemoSite = false;

const getApi = () => {
    let hostname = window.location.host;
    var arrValue = hostname.split(".");
    var result = arrValue[0];
    if (result.includes("localhost") === true) {
        result = "http://localhost:8484/";
    }
    else {
        result = "https://szi.servizkart.com/";
    }
    result = "https://szi.servizkart.com/";
    
//    result = "https://szi.servizkart.com/";
    //result = "https://szi.servizkart.com/";
    //result = "https://szi.servizkart.com/";
    return result;
};
export const APIUrl = getApi();

export const innerUploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {

            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getBaseUrl = (url) => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

export const UploadFile = async (field, data) => {
    if (data && data.rawFile) {
        let response = await innerUploadFile(field, data);
        if (response.files && response.files.length > 0) {
            return getBaseUrl(response.files[0].url);
        } else {
            return data;
        }
    }
    return data;
}




export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}


export const getUserById = (userid) => {
    let api = APIUrl + "user/getuserbyid/" + userid;
    return http.get(api).then(response => {
        return response;
    });
}

export const getVideoId = (content_url) => {
    var aData = content_url.split("/");
    var index = aData.length - 1;
    return aData[index];
}
export const VideoPatch = (url, token, model) => {
    let headers = new Headers();
    headers.append("Authorization", 'Bearer ' + token);
    headers.append("Content-Type", "application/json");
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(model),
        headers
    }).then(r => {
        if (r.status == 409) {
            return r.json();
        }
        if (!r.ok) {
            throw Error(r.statusText);
        }
        return r.json();
    });
};

export const updateVideoMeta = (video_id, token, title, description) => {
    let api = "https://api.vimeo.com/videos/" + video_id;
    let data = {
        name: title,
        description: description
    };
    return VideoPatch(api, token, data).then(response => {
        return response;
    });
}
export const registerUser = (user) => {
    let api = APIUrl + "registerUser";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const approveContent = (contentId, approvalFlag) => {
    let api = APIUrl + "content/approve_disapprove_content";
    let data = {
        id: contentId,
        status: approvalFlag
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const loginUser = (user) => {
    let api = APIUrl + "user/login";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const forgot_password = (user) => {
    let api = APIUrl + "user/forgotpassword";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const domainValidate = (domain) => {
    let api = APIUrl + "app/domain";
    return http.post(api, domain).then(response => {
        return response;
    });
}

export const createAccountServiceCategoryMap = (data) => {
    let api = APIUrl + "account-servicecat-maps";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateAccountServiceCategoryMap = (data) => {
    let api = APIUrl + "account-servicecat-maps/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}

export const StudentTracking = (content_id) => {
    let api = APIUrl + "studenttracking";
    let data = {
        content_id: content_id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const ContentReadStatus = (content_id) => {
    let api = APIUrl + "contentreadstatus";
    let data = {
        content_id: content_id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getOrgData = () => {
    let api = APIUrl + "org/1";
    return http.get(api).then(response => {
        return response;
    });
}

export const getAccount = (account_id: any) => {
    let api = APIUrl + `profileupdate/${account_id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const updateProfile = (account_id: any, status:string) => {
    let api = APIUrl + `profile/updateStatus?account=${account_id}&status=${status}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getStudentAssignment = () => {
    let api = APIUrl + "dashboard/upcoming_assignments";
    return http.get(api).then(response => {
        return response;
    });
}

export const loginUserByToken = (token) => {
    let api = APIUrl + "userById";
    let user = {
        token: token,
        StartIndex: 0,
        PageSize: 10,
    };
    return http.post(api, user).then(response => {
        return response;
    });
}

export const deleteRecord = async (path, id) => {
    let api = APIUrl + path + "/" + id;
    return await http.del(api).then(response => {
        return response;
    });
}

export const addEntiyRecord = (resource, data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const putEntiyRecord = (resource, data) => {
    let api = APIUrl + resource;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const ChangePassword = (data) => {
    let api = APIUrl + "user/update_password";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const ValidateToken = (data) => {
    let api = APIUrl + "user/validate_token";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const reviewAssignment = (data) => {
    let api = APIUrl + "assignment/review_assignment";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const update_Profile= (data) => {
    let api = APIUrl + "profileupdate/update";
    return http.post(api, data).then(response => {
        return response;
    }); 
}
export const update_Profile_Action= (data) => {
    let api = APIUrl + "profileupdate/action";
    return http.post(api, data).then(response => {
        return response;
    }); 
}


export const getSubjectsForStudent = () => {
    let api = APIUrl + "subject/get_subjects_for_logged_user";
    return http.get(api).then(response => {
        return response;
    });
}

export const getContentPageForSubject = (subjectId) => {
    let api = APIUrl + `content/get_content_page_for_subject/${subjectId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAccountToken = (accountId) => {
    let api = APIUrl + `account/get_account_token`;
    let data = { "accountId": accountId };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const getSectionsForClass = (classId) => {
    //let api = APIUrl + `section/${classId}`;
    var filter = { "class_id=": classId };
    const filterString = JSON.stringify(filter);
    let api = `${APIUrl}/section?filter=${filterString}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAccountPrimarySegments = (id) => {
    
    let api = `${APIUrl}//profileupdate/primarysegement/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getAccountInstantsegement = (id) => {
    
    let api = `${APIUrl}//profileupdate/instantsegement/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getMeetingSchedule = (id) => {
    let api = APIUrl + `meeting_schedule/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const startMeeting = (id) => {
    let api = APIUrl + `meeting_schedule/flag_meeting_as_started`;
    let data = {
        id: id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const stopMeeting = (id) => {
    let api = APIUrl + `meeting_schedule/flag_meeting_as_stopped`;
    let data = {
        id: id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
//Dashboard APIs
export const getAllClasses = () => {
    let api = APIUrl + `genere?pagesize=30&page=1&sortby=numeric_equivalent&sortorder=ASC&filter={}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_scholars_for_assignment = (class_id, assignmentCode, submitStatus) => {
    let api = APIUrl + `scholar/get_scholars_for_assignment`;
    let data = {
        class_id: class_id,
        assignment_id: assignmentCode,
        submit_status: submitStatus
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const get_all_users = () => {
    let api = APIUrl + `user/get_all_users`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_all_assignments = () => {
    let api = APIUrl + `content/get_all_assignments`;
    let data = {

    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const get_all_classes_for_employee = () => {
    let api = APIUrl + `genere/get_all_classes_for_employee`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_dashboard = (userid) => {
    let api = APIUrl + `users/dashboard/`+userid;
    return http.get(api).then(response => {
        return response;
    });
}

export const get_all_assignments_for_class_from_faculty = (class_id) => {
    let api = APIUrl + `content/get_all_assignments_for_class_from_faculty`;
    let data = {
        class_id: class_id
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserAccountMapping = (data) => {
    let api = APIUrl + "/useraccountmaps/mapaccount";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateUserRoleMapping = (data) => {
    let api = APIUrl + "/userrolemap/maprole";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserDeparmentMapping = (data) => {
    let api = APIUrl + "/userdepartmentmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserfDeparmentMapping = (data) => {
    let api = APIUrl + "/userfullfillmentdeptmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}



export const updateaccountprice = (data) => {
    let api = APIUrl + "/accountlabpartnertestprices/mapprice";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapglobalRouting = (data) => {
    let api = APIUrl + "/globalroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapTestRouting = (data) => {
    let api = APIUrl + "/testroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const MapGroupTest = (data) => {
    let api = APIUrl + "/testgroupmaps/maptest";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const fetchGridData = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    console.log(api);
    return http.get(api).then(response => {

        return response;
    })
}
export const getPatientData = (id) => {
    let api = APIUrl + "/patients/" + id;
    return http.get(api).then(response => {
        return response;
    })
}
export const addFromSetup = (id) => {
    let api = APIUrl + "/realiz-ords/addfromsetup/" + id;
    return http.get(api).then(response => {
        return response;
    })
}
export const exportToCSV = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    return http.get(api).then(response => {

        return response;
    })
}
export const managePatient = (data) => {
    let api = APIUrl + "/patient/manage";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getBookigList = (data) => {
    let api = APIUrl + "/testbookingorders/list";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const SearchTest = (data) => {
    let api = APIUrl + "/testaccountmap/find";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const addEditBooking = (data) => {
    let api = APIUrl + "/testbookingorders/addedit";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const DeleteBookedTest = (data) => {
    let api = APIUrl + "/testbookingorders/delete";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const GetBookingData = (data) => {
    let api = APIUrl + "/testbookingorders/bookingdata";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateRealizationDocument = (data) => {
    let api = APIUrl + "/realiz-ord-dets/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateHospitalDocument = (data) => {
    let api = APIUrl + "/hospital-docs/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}

export const createHospitalTpaConfig = (data) => {
    let api = APIUrl + "hospital-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const loadReport = (resource,data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getdata = (resource) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=250&filter[order]=id%20DESC`;
    return http.get(api).then(response => {
        return response;
    });
}


export const updateRolerights = (data) => {
    let api = APIUrl + "role-types/rights";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateRoleType = (id, data) => {
    let api = APIUrl + `role-types/${id}`;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const getmodules = (user_id) => {
    let api = APIUrl + "employees/getmodules/" + user_id;
    return http.get(api).then(response => {
        return response;
    });
}

export const getfunctionbyroletype = (roleid) => {
    let api = `${APIUrl}employees/getfunctionbyroletype/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceCategories = () => {
    let api = `${APIUrl}service-categories?filter[order]=displayOrder`;
    return http.get(api).then(response => {
        return response;
    });
}

export const addServiceCategory = (data) => {
    let api = `${APIUrl}service-categories`;
    return http.post(api, data).then(response => {
        return response;
    });
}


export const updateServiceCategoryDisplayOrder = (category_id, display_order, parent_id) => {
    let api = `${APIUrl}service-categories/updatedisplayorder?id=${category_id}&displayorder=${display_order}&parentid=${parent_id}`;
    return http.get(api ).then(response => {
        return response;
    });
}


export const updateServiceCategory = (data) => {
    let api = APIUrl + "/service-categories/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}

export const deleteServiceCategory = (data) => {
    let api = APIUrl + "/service-categories/" + data.id;
    return http.del(api).then(response => {
        return response;
    });
}


export const get_account_service_category = (id) => {
    let api = APIUrl + `service-categories/${id}/account`;
    return http.get(api).then(response => {
        return response;
    });
     
}
export const get_account_dashboard = (id) => {
    let api = APIUrl + `profile/dashboard/${id}`;
    return http.get(api).then(response => {
        return response;
    });
     
}

export const getservicedata = (id) => {
    let api = APIUrl + `service-categories?filter[where][Id]=${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const gettaxper = (id) => {
    let api = APIUrl + `tax-heads?filter[where][id]=${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const add_update_account_service_category = (accountId,serviceId,selected,topparentid) => {
    let api = APIUrl + `service-categories/addeditaccountservice`;
    let data = {
        accountId: accountId,
        serviceId: serviceId,
        selected: selected,
        topparentid:topparentid
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
