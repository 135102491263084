import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Divider from '@material-ui/core/Divider';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';

import VideoCallIcon from '@material-ui/icons/VideoCall';
import LinkIcon from '@material-ui/icons/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StoreIcon from '@material-ui/icons/Store';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink, setSidebarVisibility } from 'react-admin';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MenuContainer } from './MenuContainer';

type MenuName = 'mnuAdminTool' | 'mnuUtility' | 'mnuTestOperations' | 'mnuReports' | 'mnuOrder';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        mnuAdminTool: false,
        mnuUtility: false,
        mnuTestOperations: false,
        mnuReports: false,
        mnuOrder: false
    });
    const [rights, setRights] = useState([]);
    const [categories, setCategories] = useState<string[]>([]);

    const MyOnMenuClick = () => {
        window.scrollTo(0, 0);
        //onMenuClick();
    }

    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(setSidebarVisibility(false));
    // },[]);
    const onMenuClick1 = () => {
        // dispatch(setSidebarVisibility(false));
    }
    let role = localStorage.getItem("role");
    let isSuperAdmin = (role == 'super administrator');
    let isAdministrator = (role == 'system');
    let accountid = localStorage.getItem("accountid");
    if(accountid!=null && accountid!=undefined && parseInt(accountid)<=0)
    {
        accountid=null;
    }
    const SetMenuData = () => {
        let menuData = [];
        let menu = localStorage.getItem("menu");
        if (menu) {
            menuData = JSON.parse(menu);
        }
        var outObject = menuData.reduce(function (a: any, e: any) {
            let estKey = (e['ModuleCategory']);
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
        }, {});
        var keys = Object.keys(outObject);
        setCategories(keys);
        setRights(outObject);
    }

    useEffect(() => {
        SetMenuData();
        console.log("EMPLOYEE LOGGED IN ");
    }, []);

    return (
        <div>
            {' '}
            <DashboardMenuItem onClick={onMenuClick1} sidebarIsOpen={open} />
            {categories && categories.map((item: any, index: number) => {
                return (
                    <MenuContainer onMenuClick={MyOnMenuClick} sidebarIsOpen={open} dense={dense} caption={item} items={rights[item]} />
                )
            })}
            <Divider />
            {accountid   && <MenuItemLink
                to={`/profileupdate/${accountid}`}
                primaryText="Company profile"
                leftIcon={<AssessmentOutlinedIcon />}
                onClick={onMenuClick1}
                sidebarIsOpen={open}
                dense={dense}
            />}
            {/* <Divider />
            <SubMenu
                handleToggle={() => handleToggle('mnuAdminTool')}
                isOpen={state.mnuAdminTool}
                sidebarIsOpen={open}
                name="System Setup"
                icon={<SettingsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/countries`}
                    primaryText="Country"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/states`}
                    primaryText="State"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/cities`}
                    primaryText="City"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/currency`}
                    primaryText="Currency"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/timezones`}
                    primaryText="Timezone"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/UOM`}
                    primaryText="UOM"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/StatusGroup`}
                    primaryText="Status Group"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/RolesPermission`}
                    primaryText="Roles & Permission"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/Users`}
                    primaryText="Users"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/TaxHeads`}
                    primaryText="Tax Heads"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/UserType`}
                    primaryText="User Type"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />

            </SubMenu>

            <Divider />
            <SubMenu
                handleToggle={() => handleToggle('mnuUtility')}
                isOpen={state.mnuUtility}
                sidebarIsOpen={open}
                name="Service Setup"
                icon={<SettingsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/service-categories`}
                    primaryText="Service Categories"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/industries`}
                    primaryText="Industries"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/faqs`}
                    primaryText="FAQ"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/testi-monials`}
                    primaryText="Testimonials"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/subscription-features`}
                    primaryText="Subscription Features"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/subscriptions`}
                    primaryText="Subscriptions"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/AdvisoryPanels`}
                    primaryText="Advisory Panels"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/news-settings`}
                    primaryText="News Master"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/content-page-configs`}
                    primaryText="Content Settings"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />

            </SubMenu>
            <Divider />
            <SubMenu
                handleToggle={() => handleToggle('mnuTestOperations')}
                isOpen={state.mnuTestOperations}
                sidebarIsOpen={open}
                name="Account Mgmt"
                icon={<SettingsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/ServiceProviders`}
                    primaryText="Service Providers"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/Buyers`}
                    primaryText="Buyers"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/AdvisoryPanel`}
                    primaryText="Advisory Panel"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/partners`}
                    primaryText="Partners"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/Moderators`}
                    primaryText="Moderators"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <Divider />
            <SubMenu
                handleToggle={() => handleToggle('mnuOrder')}
                isOpen={state.mnuOrder}
                sidebarIsOpen={open}
                name="Order Mgmt"
                icon={<SettingsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/WorkOrder`}
                    primaryText="Work Order"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/Enquiries`}
                    primaryText="Enquiries"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <Divider />

            <SubMenu
                handleToggle={() => handleToggle('mnuReports')}
                isOpen={state.mnuReports}
                sidebarIsOpen={open}
                name="Reports"
                icon={<SettingsIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/ActiveServiceProviders`}
                    primaryText="Active Service Providers"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/DatewiseBuyerList`}
                    primaryText="Date wise Buyer List"
                    leftIcon={<AssessmentOutlinedIcon />}
                    onClick={onMenuClick1}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <Divider />
            <MenuItemLink
                to={`/changepassword`}
                primaryText="Change Password"
                leftIcon={<VpnKeyIcon />}
                onClick={onMenuClick1}
                sidebarIsOpen={open}
                dense={dense}
            /> */}
            {logout}
            <Divider />
        </div>
    );
};

export default Menu;
