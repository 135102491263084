import React from 'react';
import {
    List, Datagrid, TextField, ChipField, EmailField, DateField, EditButton,
    BooleanField,
    ReferenceField, SearchInput, Filter
} from 'react-admin';
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Account Code" source="accountCode~like" alwaysOn />
        <SearchInput variant="outlined" label="Account Name" source="accountName~like" alwaysOn />
        <SearchInput variant="outlined" label="Email" source="emailAddress~like" alwaysOn />
    </Filter>
);
export const ProfileList = (props) => {
    // const classes = useStyles({});
    return (
        <React.Fragment>
            <List title='List of account' {...props} filters={<Filters />}  >
                <Datagrid rowClick='edit'>
                    <EditButton />
                    <ChipField source='accountCode' label='Account Code' />
                    <TextField source='accountName' label='Account Name' />
                    <ReferenceField source="accountTypeId" reference="status-groups"><TextField source="name" /></ReferenceField>
                    <BooleanField source="isIndividual" />
                    <EmailField source='emailAddress' label='Email' />
                    <TextField source="phonenumber" />
                </Datagrid>
            </List>
        </React.Fragment>
    );
}

