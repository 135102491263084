import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const TaxheadEdit = (props) => {
  return (
    <Edit title="Taxhead Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        
        <TextInput source="taxCode" label="Tax Code"   fullWidth={true} validate={[required()]} />
        <TextInput source="taxName" label="Tax Name"   fullWidth={true} validate={[required()]} />
        <NumberInput  source="taxpc" label="Tax %"  fullWidth={true} validate={[required()]} />
        <BooleanInput source="isActive" validate={[required()]} label="Active" />
        
        </SimpleForm>
    </Edit>
  );
};
