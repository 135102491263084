import React, { useState, useEffect } from 'react';
import { Jutsu } from "../common/Jitsi" // Component
import { useJitsi } from "../common/Jitsi"  // Custom hook
import CircularProgress from '@material-ui/core/CircularProgress';
import * as apiClient from "../common/apiClient";
import { Button } from 'react-admin';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';

export const VideoConference = (props) => {
    const [isHangup, setIsHangup] = useState(false);
    let history = useHistory();
    let { id } = useParams();
    const isRoomNameAvailable = props.guid != undefined;
    const isDisplayNameAvailable = props.display_name != undefined;
    const isPasswordAvailable = props.room_code != undefined;
    const role = props.role;
    const roomName = props.guid;
    const parentNode = 'easysoft-vc-container'
    let jitsi = useJitsi({ roomName, parentNode , role});

    const StopMeeting = () => {
        if (role == "student")
            return;
        apiClient.stopMeeting(id).then(res => {
            if (res.status == "success") {
                if (res.result == "stopped") {
                    //history.push("/meeting_schedule");
                    if (jitsi) {
                        setIsHangup(true);
                        jitsi.executeCommand('hangup');
                    }
                }
            } else {
                alert("Please relogin in the application.");
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const MuteEveryone = () => {
        if (jitsi) {
            jitsi.executeCommand('muteEveryone');
        }
    }
    const toggleFilmStrip = () => {
        if (jitsi) {
            jitsi.executeCommand('toggleFilmStrip');
            //const fr = jitsi.getIFrame();
            //console.log(fr);
        }
    }

    useEffect(() => {
        if (jitsi) {
            jitsi.executeCommand('setVideoQuality', 320);
            jitsi.addEventListener('videoConferenceJoined', () => {
                jitsi.executeCommand('displayName', props.display_name);
                jitsi.executeCommand('subject', 'EasySoft Technologies - Live Class');
                jitsi.executeCommand('setVideoQuality', 320);
            });
            jitsi.addEventListener('videoConferenceLeft', () => {
                if (role != "student") {
                    setIsHangup(true);
                    apiClient.stopMeeting(id).then(res => {
                        console.log("videoConferenceLeft");
                    }).catch(err => {
                        console.log(err);
                    });
                }
                history.push("/meeting_schedule");
            });

        }
        return () => jitsi && jitsi.dispose()
    }, [jitsi])

    return (
        <div className="live-class-container">
            <div id={parentNode} />
            <div class="easysoft-technologies-logo"><img src="https://esst.in/olc/services/assets/uploads/files/e6bffbf9401f436799b098f92e0adfad_ES.png" /></div>
            {isHangup && <div className="after-hangup"></div>}
            {role != "student" && <div className="vc-actions">
                <Button
                    className="stop-meeting"
                    variant="contained" color="primary"
                    label="End Class" size="medium"
                    startIcon={<QueuePlayNextIcon />}
                    onClick={() => {
                        StopMeeting();
                    }}
                />
                <Button
                    className="stop-meeting"
                    variant="contained" color="primary"
                    label="Mute Everyone" size="medium"
                    startIcon={<QueuePlayNextIcon />}
                    onClick={() => {
                        MuteEveryone();
                    }}
                />
                <Button
                    className="stop-meeting"
                    variant="contained" color="primary"
                    label="Toggle FilmStrip " size="medium"
                    startIcon={<QueuePlayNextIcon />}
                    onClick={() => {
                        toggleFilmStrip();
                    }}
                />
            </div>}
        </div>
    )
}