import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab, SimpleForm, NumberInput,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import RichTextInput from 'ra-input-rich-text';

export const PartnerCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="Partner Create" {...props} undoable={false} >
      <SimpleForm variant="outlined">
        <TextInput source="code" fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="name" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="skillsets" fullWidth={true} formClassName={classes.last_three_input} />
        <TextInput source="serviceOffered" fullWidth={true} />
        <ImageInput source="image" fullWidth={true}
          label="Logo Image" accept="image/*" className="image" >
          <ImageField source="image" title="Icon" />
        </ImageInput>
        <div className="center-align">(Image Dimensions : 200px X 115px)</div>
        <ImageInput source="clientLogo" fullWidth={true}
          label="Clientele" accept="image/*" className="clientLogo" >
          <ImageField source="clientLogo" title="Icon" />
        </ImageInput>
        <div className="center-align">(Image Dimensions : 200px X 115px)</div>
        <RichTextInput source="description" fullWidth={true} />
        <RichTextInput source="moreDescription" fullWidth={true} />
        <TextInput source="emailAddress" fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="phonenumber" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="landlineNumber" fullWidth={true} formClassName={classes.last_three_input} />
        <NumberInput source="displayOrder" />
        <BooleanInput source="isActive" fullWidth={true} formClassName={classes.last_inline_input} />
      </SimpleForm>
    </Create>
  );
};
