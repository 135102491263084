import React,{useState} from 'react';
import './popup.css'

const Popup = (props) =>{   
   let classes="popup-content shadow height-auto";
   if(props.customClasses!=undefined && props.customClasses!="")
   {
      classes=props.customClasses
   }
return (<div id="myModal" className="popup" style={props.open===true?{display: "block"}:{display: "none"}}>
   <div className={classes}>
   <div style={{background:"#233D63",color:"white",height:"15px"}}> 
   </div>
      <div style={{background:"#233D63",color:"white",height:"35px"}}> 
         <span style={{marginLeft:"10px",fontSize:"19px",marginTop:"10px"}}>{props.title} </span>
         <span className="close" title="Click here to close" onClick={()=>props.onClose()} style={{marginRight:"10px",marginTop:"0px"}} >×</span> 
      </div>
       <div className="popup-line" />
        {props.children}
      </div>
   </div>)

}

export default Popup;