import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from './themes';
import { Location } from 'history';
import logo from "../images/logo.png";
import * as apiClient from "../common/apiClient";
import { HomeForgotPassword } from './Home-Forgot-Password';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#233d63',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '0em',
        height: 380,
        borderRadius: "5px 0px 0px 5px",
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    registerCard: {
        minWidth: 300,
        marginTop: '0em',
        height: 380,
        backgroundColor: "#fff",
        display: 'flex',
        justifyContent: "center",
        borderRadius: "0px 5px 5px 0px",
    },
    register: {
        padding: '1em',
        color: "#fff",
        display: 'flex',
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center"
    },
    registerText: {
        margin: '0px 15px 15px 15px',
        textAlign: "center",
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));
interface IMeta {
    touched: boolean;
    error: Error;
}

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField variant="outlined"
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    email?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = ({ location }: { location: Location }) => {
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setforgotPassword] = useState(false);
    const translate = useTranslate();
    const classes = useStyles({});
    const notify = useNotify();
    const login = useLogin();
    let history = useHistory();


    const handleSubmit = (auth: FormValues) => {
        if (forgotPassword === true) {
            setLoading(true);
            let formdata = {
                username: auth.email,
                password: "",
                usertype: 'admin'
            }
            apiClient.forgot_password(formdata).then(async res => {
                debugger
                setLoading(false);
                //console.log(res);            
                if (res[0].result === true) { 
                    setforgotPassword(false);
                    notify(res[0].message);

                } else {
                    notify(res[0].message, 'warning');
                }
            }).catch(err => {
                setLoading(false);
                notify(err, 'warning');
            });



        }
        else {
            setLoading(true);
            let formdata = {
                username: auth.email,
                password: auth.password,
                usertype: 'admin'
            }
            apiClient.loginUser(formdata).then(async res => {
                debugger
                //localStorage.setItem("type", "admin");
                setLoading(false);
                //console.log(res);            
                if (res.data && res.data.result == true) {
                    let user = res.data;
                    localStorage.setItem("firstname", user.name);
                    localStorage.setItem("lastname", "");
                    localStorage.setItem('username', user.name);
                    localStorage.setItem("userId", user.userId);
                    localStorage.setItem("type", user.userType);
                    localStorage.setItem("displayName", user.name);
                    localStorage.setItem("role", user.userType);
                    localStorage.setItem("token", res.token);
                    localStorage.setItem("accountid", user.accountid);
                    const menu = await apiClient.getmodules(user.userId)
                    localStorage.setItem("menu", JSON.stringify(menu));
                    //console.log(res);
                    debugger
                    if (user.userType == "Service Provider" && user.IsProfileCompleted === false) {
                        history.push(`/profileupdate/${user.accountid}/1`);
                    } else
                        history.push("/");

                    // localStorage.setItem("Ownrecords", user.Ownrecords);
                    // apiClient.getmodules(user.userId).then(res => {
                    //     localStorage.setItem("menu", JSON.stringify(res));
                    //     //console.log(res);
                    //     history.push("/");
                    // }).catch(err => {
                    //     console.log(err);
                    // });

                } else {
                    notify(res[0].message, 'warning');
                }
            }).catch(err => {
                setLoading(false);
                notify(err, 'warning');
            });
        }

    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.password && !forgotPassword) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {!forgotPassword &&
                        <div className={classes.main}>
                            <Card className={classes.card}>
                                <div className={classes.avatar}>
                                    <Avatar className={classes.icon}>
                                        <LockIcon />
                                    </Avatar>
                                </div>
                                <div className={classes.hint}>

                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            autoFocus
                                            name="email"
                                            // @ts-ignore
                                            component={renderInput}
                                            label={translate('ra.auth.username')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="password"
                                            // @ts-ignore
                                            component={renderInput}
                                            label={translate('ra.auth.password')}
                                            type="password"
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        {translate('ra.auth.sign_in')}
                                    </Button>


                                </CardActions>
                                <CardActions className={classes.actions}>
                                    <Button variant="contained"
                                        fullWidth
                                        onClick={() => { setforgotPassword(true) }}
                                    >
                                        Forgot Password
                                    </Button>


                                </CardActions>


                            </Card>
                            <Card className={classes.registerCard}>
                                <div className={classes.register}>
                                    {/* <div className={classes.avatar}>
                                    <Avatar className={classes.icon}>
                                        <HowToRegIcon />
                                    </Avatar>
                                </div> */}
                                    <div className={classes.registerText}>
                                        <img style={{ height: "200px" }} src={logo} />
                                    </div>
                                </div>
                            </Card>
                            <Notification />
                            {/* <HomeForgotPassword/> */}
                        </div>
                    }
                    {forgotPassword && <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.hint}>

                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="email"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.username')}
                                        disabled={loading}
                                    />
                                </div>

                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}

                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {"Submit"}
                                </Button>
                            </CardActions>
                            <CardActions className={classes.actions}>
                                <Button variant="contained"
                                    fullWidth
                                    onClick={() => { setforgotPassword(false) }}
                                >
                                    Back To Login
                                </Button>


                            </CardActions>

                        </Card>
                        <Card className={classes.registerCard}>
                            <div className={classes.register}>
                                {/* <div className={classes.avatar}>
                                    <Avatar className={classes.icon}>
                                        <HowToRegIcon />
                                    </Avatar>
                                </div> */}
                                <div className={classes.registerText}>
                                    <img style={{ height: "200px" }} src={logo} />
                                </div>
                            </div>
                        </Card>
                        <Notification />
                        {/* <HomeForgotPassword/> */}
                    </div>
                    }

                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles({}) hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
