import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountProof = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    let name="PROOf";
    if(props.docType==="CARD")
    {
        name="Card"
    }
    if(props.docType==="KEYMGMT")
    {
        name="Designation"
    }
    const handleSubmit = async (formdata) => {
        debugger
        let data = {
            accountId: parseInt(props.accountid),
            imageName: formdata.imageName,
            proofType: props.docType,
            imageLegacyName: formdata.fileUrl1.rawFile.name,
            fileUrl: formdata.fileUrl1.fileUrl1,
            insertedBy: 0,
            insertedAt: "2021-12-19T15:14:15.824Z",
            updatedBy: 0,
            updatedAt: "2021-12-19T15:14:15.824Z",
            isActive: true,
            isDefault: true,
            isVerified: false,
            imageSize: formdata.fileUrl1.rawFile.size,
            statusId: 0

        }
        apiClient.addEntiyRecord("account-image-maps", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });


    }
    return (
        <div>
            {<Create {...props} resource="account-image-maps" redirect="false">
                <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined">
                    
                    <TextInput source="imageName" label={name} validate={[required()]} fullWidth={true} />
                    <ImageInput fullWidth={true} source="fileUrl1" label={name} accept="image/*" formClassName={classes.two_three_input}>
                        <ImageField source="fileUrl1" title={name} />
                    </ImageInput>


                </SimpleForm>
            </Create>

            }
        </div>
    );
}
