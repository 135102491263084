import React, { useState, useCallback, useEffect } from "react";
import Button from '@material-ui/core/Button';
import {
  Edit,
  DateInput,
  SimpleForm,
  AutocompleteInput,
  AutocompleteArrayInput,
  SelectInput,
  TextInput,
  BooleanInput,
  NumberInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TextField, useRefresh,
  SelectArrayInput,
  ReferenceArrayInput,
  minLength,
  useNotify,
  maxLength,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import { Addressfields } from "../patient/Addressfields";
import EditIcon from '@material-ui/icons/Edit';
import VSDeleteButton from "../../VSDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { AccountServiceMapEdit } from "./AccountServiceMapEdit";
import { AccountServiceMapCreate } from "./AccountServiceMapCreate";
import * as apiClient from "../../common/apiClient";
import CircularProgress from '@material-ui/core/CircularProgress';
import WizardToolbar from "../../common/WizardToolbar";
import CDeleteButton from "../../common/CDeleteButton";
import { AccountKeyMembers } from "./AccountKeyMembers";
import { OfficeAddressfields } from "./OfficeAddressfields";
import { AccountIndustries } from "./AccountIndustries";
import { AccountCustomerLocation } from "./AccountCustomerLocation";
import { OtherOfficeAddress } from "./OtherOfficeAddress";
import { AccountClients } from "./AccountClients";
import { AccountPricePositioning } from "./AccountPricePositioning";
import { AccountProof } from "./AccountProof";
import { AccountRecoginition } from "./AccountRecoginition";
import { AccountRatingMap } from "./AccountRatingMap";
import Popup from "./popup";
import CountBlock from "./CountBlock"; 
import { ServiceSelection } from "./ServiceSelection";


const useCustomeStyles = makeStyles({
  flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" ,width:"100%" },
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
});
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));

export const AccountServiceEditButton = props => {
  const setAccountServiceId = () => {
    props.setAccountServiceEdit(props.record.id);
  }
  return (
    <Button
      color="secondary"
      size="medium"
      onClick={setAccountServiceId}
      startIcon={<EditIcon />}
    >Edit</Button>
  )
};
export const LinkButton = props => {

  return (
    <Button
      color="secondary"
      size="medium"
      onClick={() =>
        window.open(props.record.fileUrl, 'sharer', 'toolbar=0,status=0,width=548,height=325')
      }

    >View</Button>
  )
};



export const ProfileEdit = (props) => {
  
  const styles = useCustomeStyles({});
  const classes = useStyles({});
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const [isAccountServiceAdd, setIsAccountServiceAdd] = useState(false);
  const [isEditExibitor, setIsEditExibitor] = useState(false);
  const [officeCounrtyId, setOfficeCountryId] = useState(0);
  const [editExibitorId, setEditExibitorId] = useState(0);
  const [editKeyMemberId, setEditKeyMemberId] = useState(0);
  const [profileStep, setProfileStep] = useState("LOADING");
  const [isAccountKeyMembers, setIsAccountKeyMembers] = useState(false);
  const [isOfficeAddress, setIsOfficeAddress] = useState(false);
  const [isAccountIndustries, setIsAccountIndustries] = useState(false);
  const [isAccountCustomerLocation, setIsAccountCustomerLocation] = useState(false);
  const [isAccountClients, setIsAccountClients] = useState(false);
  const [isAccountBusinessProof, setIsAccountBusinessProof] = useState(false);
  const [isAccountKeyMgmt, setIsAccountKeyMgmt] = useState(false);
  const [isAccountCard, setIsAccountCard] = useState(false);
  const [isAccountRecoginition, setIsAccountRecoginition] = useState(false);
  const [isAccountRatingMap, setIsAccountRatingMap] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);
  const [isVendorProfile, setIsVendorProfile] = useState(false);
  const [isServicesCompleted, setIsServicesCompleted] = useState(false);
  const [isCompliancesCompleted, setIsCompliancesCompleted] = useState(false);
  const [isRecognitionCompleted, setIsRecognitionCompleted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [remarks, setRemarks] = useState(""); 
  const [updatedPerc, setUpdatedPerc] = useState(0); 
  const [acccountDetailPerc, setAcccountDetailPerc] = useState(0); 
  const [servicesPerc, setServicesPerc] = useState(0); 
  const [compDocuPerc, setCompDocuPerc] = useState(0); 
  const [recogPerc, setRecogPerc] = useState(0); 
  const [businessInfoPerc, setBusinessInfoPerc] = useState(0); 
  const [acccountDetailPercImg, setAcccountDetailPercImg] = useState(""); 
  const [servicesPercImg, setServicesPercImg] = useState(""); 
  const [compDocuPercImg, setCompDocuPercImg] = useState(""); 
  const [recogPercImg, setRecogPercImg] = useState(""); 
  const [businessInfoPercImg, setBusinessInfoPercImg] = useState(""); 
  
  

   

  const notify = useNotify();  
  const validateProfileDetails = [required(), minLength(100), maxLength(1000)];
  const validateBigProject = [minLength(100), maxLength(1000)];
  const handlePrevious = async () => { 
    let _hash = window.location.hash.toString().split('/')[0] + '/' + window.location.hash.toString().split('/')[1] + '/' + window.location.hash.toString().split('/')[2]
    let step = 0;
    if (profileStep === "VENDORPROFILE") {
      setProfileStep("PROFILE");
    }
    if (profileStep === "SERVICES") {
      setProfileStep("VENDORPROFILE");
      step = 1
    }

    if (profileStep === "COMPLIANCES") {
      setProfileStep("SERVICES");
      step = 2
    }
    if (profileStep === "RECOGNITION") {
      setProfileStep("COMPLIANCES");
      step = 3
    }

    if (step > 0) {
      window.location.hash = _hash + "/" + step;
    }
    else {
      window.location.hash = _hash;
    }



  }

  const closePopup = async () => {
    setOpenPopup(false);
  }
  const handleSubmit = async (formdata) => {
    debugger
    console.log(formdata);
    let _hash = window.location.hash.toString().split('/')[0] + '/' + window.location.hash.toString().split('/')[1] + '/' + window.location.hash.toString().split('/')[2]
    let step = 0;
    if (profileStep === "PROFILE") {
      setProfileStep("VENDORPROFILE");
      step = 1
    }
    if (profileStep === "VENDORPROFILE") {
      setIsVendorProfile(true)
      setProfileStep("SERVICES");
      step = 2
    }
    if (profileStep === "SERVICES") {
      let count= await loadServiceCategories(formdata.id); 
      if(count===0)
      {
        notify("Please select Business Solution Offerings","warning");
        return;
      }
      else
      {
        setIsServicesCompleted(true)
        setProfileStep("COMPLIANCES");
        step = 3
      }
      
    }
    if (profileStep === "COMPLIANCES") {
      setIsCompliancesCompleted(true)
      setProfileStep("RECOGNITION");
      step = 4
    }
    if (profileStep != "RECOGNITION") {
      if (step > 0) {
        window.location.hash = _hash + "/" + step;

      }
      else {
        window.location.hash = _hash;
      }


    }
    if (profileStep === "RECOGNITION") {
      setIsRecognitionCompleted(true)
      apiClient.update_Profile(formdata).then(res => {
        debugger
        if (res) {
          if (res[0].success) {
            notify(res[0].message);
            loaddashboard(props.id);
            setProfileStep("PROFILE");
            window.location.hash = _hash ;
          }
          else {
            notify(res[0].message, "warning");
          }

        }
        console.log(res);

      }).catch(err => {
        console.log(err);
      });
    } 

  }

  
  const loaddashboard = async (id) => {
    
    await apiClient.get_account_dashboard(id).then(res => {
      debugger
      //console.log(res);
      if (res.length > 0) {
        
        setUpdatedPerc(res[0].UpdatePerc);
        setAcccountDetailPerc(res[0].ProfilePerc);
        setBusinessInfoPerc(res[0].BusinessInfoPerc); 
        setServicesPerc(res[0].ServicesPerc);
        setCompDocuPerc(res[0].CompDocuPerc);
        setRecogPerc(res[0].RecogPerc);

        setAcccountDetailPercImg(res[0].Profileurl);
        setBusinessInfoPercImg(res[0].BusinessInfourl);  
        setServicesPercImg(res[0].Servicesurl);
        setCompDocuPercImg(res[0].CompDocuurl);
        setRecogPercImg(res[0].Recogrurl);

        
       }
    }).catch(err => {
      console.log("erro : " + err);
    });

    
  }

  const loadServiceCategories = async (id) => {
    let selectedcount =0;
    await apiClient.get_account_service_category(id).then(res => {
      debugger
      //console.log(res);
      if (res.length > 0) {
        if (res.filter(x => x.selected === true).length>0)
        {
          selectedcount=res.filter(x => x.selected === true).length;  
        }
        
       }
    }).catch(err => {
      console.log("erro : " + err);
    });

    return selectedcount ; 
  }

  
  const setAccountKeyMembers = (id) => {
    setEditExibitorId(id);
    setIsAccountKeyMembers(true);
  }
  const setOfficeAddress = (id) => {
    setEditExibitorId(id);
    setIsOfficeAddress(true);
  }

  const setAccountIndustries = (id) => {
    setEditExibitorId(id);
    setIsAccountIndustries(true);
  }
  const setAccountCustomerLocation = (id) => {
    setEditExibitorId(0);
    setIsAccountCustomerLocation(true);
  }
  const setAccountClients = (id) => {
    setEditExibitorId(0);
    setIsAccountClients(true);
  }
  const setAccountBusinessProof = (id) => {
    setEditExibitorId(0);
    setIsAccountBusinessProof(true);
  }

  const setAccountKeyMgmt = (id) => {
    setEditExibitorId(0);
    setIsAccountKeyMgmt(true);
  }
  const setAccountCard = (id) => {
    setEditExibitorId(0);
    setIsAccountCard(true);
  }

  const setAccountRecoginition = (id) => {
    setEditExibitorId(0);
    setIsAccountRecoginition(true);
  }

  const setAccountRatingMap = (id) => {
    setEditExibitorId(0);
    setIsAccountRatingMap(true);
  }




  const setAccountKeyMembersEdit = (id) => {
    debugger
    setEditKeyMemberId(id);
    setIsAccountKeyMembers(true);
  }


  const closeExbhitorEdit = () => {
    setIsEditExibitor(false);
    refresh();
  }
  const closeAccountServiceForm = () => {
    setEditKeyMemberId(0);
    setIsAccountKeyMembers(false);
    setIsAccountIndustries(false);
    setIsAccountCustomerLocation(false);
    setIsAccountClients(false);
    setIsAccountBusinessProof(false);
    setIsAccountCard(false);
    setIsAccountKeyMgmt(false);
    setIsOfficeAddress(false);
    setIsAccountRecoginition(false);
    setIsAccountRatingMap(false);
    //refresh();
  }
  const onCancel = () => {
    setIsAccountServiceAdd(false);
    setIsEditExibitor(false);
    setIsAccountKeyMembers(false);
    setIsAccountIndustries(false);
    setIsAccountCustomerLocation(false);
    setIsAccountClients(false);
    setIsAccountBusinessProof(false);
    setIsAccountKeyMgmt(false);
    setIsAccountCard(false);
    setIsOfficeAddress(false);
    setIsAccountRecoginition(false);
    setIsAccountRatingMap(false);
  }
  const handleClose = useCallback(() => {
    setIsAccountServiceAdd(false);
  }, []);
  const EditButtonKeyMember = (props) => {
    let { record } = props;
    return <Button onClick={() => setAccountKeyMembersEdit(record.id)}  > Edit </Button>

  }
  // const load
  useEffect(() => {
    
    debugger
    if (profileStep != "LOADING") return;
    if (!props.id) return;
    loaddashboard(props.id);
    apiClient.getAccount(props.id).then(res => {
      console.log(res, "====res====");

      debugger
      
      if (res.isProfileCompleted)
      {
        setIsProfileCompleted(true);
        setIsVendorProfile(true);
        setIsServicesCompleted(true);
        setIsCompliancesCompleted(true);
        setIsRecognitionCompleted(true);
      }
       
  
      if ((res.statusId ===undefined || res.statusId===null) && res.statusId !=0  && (res.remarks===undefined || res.remarks===null))
      {
        setOpenPopup(true);
        setRemarks("<p>Dear Partner,</p> <p>Thank you for taking the first step towards the world of opportunities. You need to complete the Partner Profile to showcase your profile to our Customers as Verified Partner.</p> <p>Please allocate 15 minutes to complete the profile at once which has following tabs:</p> <p>Account Details, Business Information, Services, Compliance &amp; Documentation, and Recognition and Awards. You may be asked to upload certain mandatory documents during completion process.</p> <p>Thank you,</p><p>Partner Relation Team</p><p>SERVIZKART</p><p><br></p>")
      }
      else if ( res.statusId !=undefined && res.statusId!=null &&  res.statusId != 170 ) {
        // if(res.remarks!=undefined )
        // {

        // }
        if(res.remarks!=undefined &&  res.remarks!=null &&  res.remarks.length>0)
        {
          setOpenPopup(true);
          setRemarks(res.remarks)
        }
        // else
        // {
        //   setOpenPopup(true);
        //   setRemarks("<p>Dear Partner,</p> <p>Thank you for taking the first step towards the world of opportunities. You need to complete the Partner Profile to showcase your profile to our Customers as Verified Partner.</p> <p>Please allocate 15 minutes to complete the profile at once which has following tabs:</p> <p>Account Details, Business Information, Services, Compliance &amp; Documentation, and Recognition and Awards. You may be asked to upload certain mandatory documents during completion process.</p> <p>Thank you,</p><p>Partner Relation Team</p><p>SERVIZKART</p><p><br></p>")
        // }
        
      }

    

   
      if (res && res.profileStep) {
        setProfileStep(res.profileStep);
      } else {
        setProfileStep("PROFILE");
        apiClient.updateProfile(props.id, "PROFILE");
      }
    }).catch(err => console.log(err));
  }, []);
  return (
    <React.Fragment>
      <Popup title="Action Required" open={openPopup} onClose={closePopup} >
        {/* <div>
          <h3>Comments</h3>
        </div> */}
        <div>
        <h5 ><p dangerouslySetInnerHTML={{ __html: remarks }} ></p></h5>
        </div>
      </Popup>

      {profileStep && profileStep == "LOADING" && <CircularProgress color="secondary" />}
      {profileStep && profileStep != "LOADING" && <Edit title="Profile Builder" {...props}>
        <TabbedForm syncWithLocation={false}
          toolbar={<WizardToolbar label={profileStep === "RECOGNITION" ? "Submit" : "Next"} onSave={handleSubmit}
            showPrevious={profileStep != "PROFILE"}
            onClose={handlePrevious} onCancel={handlePrevious} />}
          variant="outlined">
           <FormTab value="DETAILS1" label={ "Profile Status"}  >
           <div className={styles.flexCenter}  >
             
                    <div key={1}>
                        <CountBlock title={"Profile"} value={acccountDetailPerc}  icon={acccountDetailPercImg} /> 
                    </div>
                    <div key={2}>
                        <CountBlock title={"Business Information"} value={servicesPerc}   icon={servicesPercImg}  /> 
                    </div>
                    <div key={3}>
                        <CountBlock title={"SERVICES"} value={businessInfoPerc} icon={businessInfoPercImg}  /> 
                    </div>
                    <div key={4}>
                        <CountBlock title={"Compliances and Documentation"} value={compDocuPerc}   icon={compDocuPercImg}  /> 
                    </div>
                    <div key={5}>
                        <CountBlock title={"Recognition and Awards"} value={recogPerc} icon={"https://servizkart.blob.core.windows.net/servizkartdocs/RecognitionRewards.png"}  /> 
                    </div>
                    
               
            </div>
           </FormTab>
          {(profileStep === "PROFILE" || isProfileCompleted) && <FormTab value="DETAILS1" label={ "Profile Detail"}  >

            <TextInput source="accountName" label="Business Name" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <ReferenceInput label="Company Type" source="companyTypeId" sort={{ field: 'sno', order: 'ASC' }} reference="status-groups" filter={{ groupId: 4 }} formClassName={classes.two_three_input} fullWidth={true}>
              <SelectInput optionText="statusName" validate={[required()]} />
            </ReferenceInput>



            <NumberInput source="yearofCorp" label="Year of Incorporation " length={4} formClassName={classes.last_three_input} validate={[required()]} fullWidth={true} />
            <ReferenceInput label="Number Of Employee" source="noOfEmployeeId" reference="status-groups" filter={{ groupId: 5 }} formClassName={classes.one_three_input} fullWidth={true} sort={{ field: 'sno', order: 'ASC' }}  >
              <SelectInput optionText="statusName" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="webSite" label="Business Website " formClassName={classes.two_three_input} fullWidth={true} />
            <TextInput source="contactName" label="Primary Contact Person Name" validate={[required()]} formClassName={classes.last_three_input} fullWidth={true} />
            <TextInput source="contactEmail" label="Work Email" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <TextInput source="contactMobile" label="Mobile Number" validate={[required()]} formClassName={classes.two_three_input} fullWidth={true} />
            <TextInput source="contactPhone" label="Office Phone Number" validate={[required()]} formClassName={classes.last_three_input} fullWidth={true} />

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline", cursor: "pointer" }} title="You can setup main office address"   >Office Address :</span>
            </div>
            <TextInput source="officeAddress1" label="Address 1" validate={[required()]} formClassName={classes.one_three_input} fullWidth={true} />
            <TextInput source="officeAddress2" label="Address 2" formClassName={classes.two_three_input} fullWidth={true} />
            <TextInput source="officePincode" label="Pincode" validate={[required()]} formClassName={classes.last_three_input} fullWidth={true} />
            {<FormDataConsumer  >
              {formDataProps => (
                <OfficeAddressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            }

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline", cursor: "pointer" }} title="You can add more other office addresses" >Other Office Address :</span>
            </div>
            <Button onClick={setOfficeAddress}> ADD More Office Address</Button>
            {!isOfficeAddress && <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              filter={{ officeTypeId: 22 }}
              reference="account-office-addresses"
              target="accountId">
              <Datagrid>
                <CDeleteButton {...props} />
                <TextField label="Address 1" source="address1" />
                <TextField label="Address 2" source="address2" />
                <ReferenceField source="countryId" reference="countries" label="Country" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="stateName" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                  <TextField source="cityName" />
                </ReferenceField>
                <TextField source="zipCode" label="Pincode" />
              </Datagrid>
            </ReferenceManyField>}


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline", cursor: "pointer" }} title="You can add Key Team Members" >Key Team Members : </span>
            </div>
            <Button onClick={setAccountKeyMembers}> ADD Key Team Member</Button>
            {!isAccountKeyMembers && <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="account-key-members"
              target="accountId">
              <Datagrid>
                <CDeleteButton {...props} />
                <EditButtonKeyMember label="" source="id" />
                <TextField label="Name" source="name" />
                <TextField label="Designation" source="designation" />
                <TextField label="Email" source="email" />
                <TextField label="Contact No" source="contactNo" />
              </Datagrid>
            </ReferenceManyField>}

          </FormTab>}
          {(profileStep === "VENDORPROFILE" || isVendorProfile) && <FormTab value="DETAILS2" label={"Business Information"}    >
            <TextInput source="profileDetails" label="Business Summary (minimum 100 and maximum 1000 characters)" multiline={true} validate={validateProfileDetails} fullWidth={true} />

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Account Industries :</span>
            </div>
            <Button onClick={setAccountIndustries}> ADD More Industries</Button>
            {!isAccountIndustries && <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="account-industries"
              target="accountId">
              <Datagrid>
                <CDeleteButton {...props} />

                <ReferenceField source="industryId" reference="industries" label="Industry" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              </Datagrid>
            </ReferenceManyField>}



            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Customer Location (Location of customers served) :</span>
            </div>


            <Button onClick={setAccountCustomerLocation}>  Add More Locations</Button>
            {!isAccountCustomerLocation && <ReferenceManyField fullWidth={true}
              addLabel={false}

              reference="account-cust-locations"
              target="accountId">
              <Datagrid>
                <CDeleteButton {...props} />

                <ReferenceField source="countryId" reference="countries" label="Country" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <ReferenceField source="stateId" reference="states" label="State" link={false}>
                  <TextField source="stateName" />
                </ReferenceField>
                <ReferenceField source="cityId" reference="cities" label="City" link={false}>
                  <TextField source="cityName" />
                </ReferenceField>
                <TextField source="zipCode" label="Pincode" />
              </Datagrid>
            </ReferenceManyField>}



            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Clients Served :</span>
            </div>


            <Button onClick={setAccountClients}>  Add More Client</Button>
            {!isAccountClients && <ReferenceManyField fullWidth={true}
              addLabel={false}

              reference="account-clients"
              target="accountId">
              <Datagrid>
                <CDeleteButton {...props} />
                <TextField source="name" label="Client Name" />
                <TextField source="website" label="Website" />
              </Datagrid>
            </ReferenceManyField>}
          </FormTab>
          }
          {/*profileStep === "SERVICES" &&  */}
          {
          (profileStep === "SERVICES" || isServicesCompleted) && 
          <FormTab value="DETAILS2" label={"Services"}    >
            <ReferenceInput label="Annual Turnover" source="annualTurnOverId" reference="status-groups" filter={{ groupId: 8 }} formClassName={classes.one_4_input} fullWidth={true} sort={{ field: 'sno', order: 'ASC' }} >
              <SelectInput optionText="statusName" validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput perPage={5000} label="Currency" source="anualTurnoverCurrencyId" reference="currency" formClassName={classes.two_4_input} filter={{ id: 64 }} fullWidth={true}  >
              <SelectInput optionText="currencyName" validate={[required()]}   />
              {/* <AutocompleteInput optionText="currencyName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} /> */}
            </ReferenceInput>



            <ReferenceInput label="Annual Turnover Range" source="anualTurnoverRangeId" reference="status-groups" filter={{ groupId: 9, isActive: 1 }} formClassName={classes.three_4_input} fullWidth={true}>
              <SelectInput optionText="statusName" validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput label="Primary Source of Revenue" source="primarySourceId" reference="service-categories" filter={{ parentId: 0 }} formClassName={classes.last_4_input} fullWidth={true}>
              <SelectInput optionText="serviceName" validate={[required()]} />
            </ReferenceInput>


            <FormDataConsumer  >
              {formDataProps => (
                <AccountPricePositioning {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput source="pricePositioningDetails" label="Price Positioning Details" multiline={true} validate={[required()]} fullWidth={true} />


            {/* <ReferenceInput label="Instance Service Offerings" source="instantServiceOfferingId" reference="service-categories" filter={{ parentId: 0 }} formClassName={classes.one_three_input} fullWidth={true} sort={{ field: 'serviceName', order: 'ASC' }} >
              <SelectInput optionText="serviceName" validate={[required()]} />
            </ReferenceInput> */}


            <ServiceSelection {...props} />
            <ReferenceArrayInput variant="outlined" label="Instance Service Offerings" source="instantServiceOfferingId" reference="service-categories" filter={{ parentId: 0 }} formClassName={classes.one_three_input} fullWidth={true} sort={{ field: 'serviceName', order: 'ASC' }} >
              <AutocompleteArrayInput variant="outlined" optionText="serviceName" />
            </ReferenceArrayInput>


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Mention Any Big 3 Projects:</span>
            </div>
            <TextInput source="bigProjects1" label="Big Projects 1" multiline={true} validate={validateBigProject} fullWidth={true} />
            <TextInput source="bigProjects2" label="Big Projects 2" multiline={true} validate={validateBigProject} fullWidth={true} />
            <TextInput source="bigProjects3" label="Big Projects 3" multiline={true} validate={validateBigProject} fullWidth={true} />


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>References:</span>
            </div>
            <TextInput source="ref1" label="Reference Name 1" fullWidth={true} />
            <TextInput source="ref2" label="Reference Name 2" fullWidth={true} />
            <TextInput source="ref3" label="Reference Name 3" fullWidth={true} />
            <TextInput source="ref4" label="Reference Name 4 " fullWidth={true} />
            <TextInput source="ref5" label="Reference Name 5" fullWidth={true} />


          </FormTab>
          }
          {(profileStep === "COMPLIANCES" || isCompliancesCompleted) && <FormTab value="DETAILS2"  label={"Compliances and Documentation"}     >
            <TextInput source="gstno" label="GST No" validate={[required()]} fullWidth={true} formClassName={classes.one_three_input} />
            <TextInput source="panno" label="PAN NO" fullWidth={true} formClassName={classes.two_three_input} />
            <TextInput source="msmeno" label="MSME Regn No" fullWidth={true} formClassName={classes.last_three_input} />

            <ImageInput fullWidth={true} source="gstUrl1" label="GST Certificate " accept="image/*" formClassName={classes.one_three_input}>
              <ImageField source="gstUrl1" title="GST Certificate " />
            </ImageInput>
            <ImageInput fullWidth={true} source="panurl1" label="PAN Scan Copy " accept="image/*" formClassName={classes.two_three_input}>
              <ImageField source="panurl1" title="GST Certificate " />
            </ImageInput>
            <ImageInput fullWidth={true} source="msmeurl1" label="MSME Regn Certificate Scan Copy " accept="image/*" formClassName={classes.two_three_input}>
              <ImageField source="msmeurl1" title="MSME Regn Certificate Scan Copy " />
            </ImageInput>

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Proof of Primary Place of Business (Latest Electricity bill, telephone bill, other) : :</span>
            </div>


            <Button onClick={setAccountBusinessProof}>  Add Proof</Button>
            {!isAccountBusinessProof && <ReferenceManyField fullWidth={true}
              addLabel={false}
              filter={{ proofType: "PROOF" }}
              reference="account-image-maps"
              target="accountId">
              <Datagrid >
                <CDeleteButton {...props} />
                <TextField source="imageName" label="Proof" />
                <LinkButton label="" source="fileUrl" />
              </Datagrid>
            </ReferenceManyField>}


            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Photo of the Key Management : </span>
            </div>


            <Button onClick={setAccountKeyMgmt}>  Add Photo  </Button>
            {!isAccountKeyMgmt && <ReferenceManyField fullWidth={true}
              addLabel={false}
              filter={{ proofType: "KEYMGMT" }}
              reference="account-image-maps"
              target="accountId">
              <Datagrid >
                <CDeleteButton {...props} />
                <TextField source="imageName" label="Designation" />
                <LinkButton label="" source="fileUrl" />
              </Datagrid>
            </ReferenceManyField>}

            <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
              <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Business Card  : </span>
            </div>


            <Button onClick={setAccountCard}>  Add Card  </Button>
            {!isAccountCard && <ReferenceManyField fullWidth={true}
              addLabel={false}
              filter={{ proofType: "CARD" }}
              reference="account-image-maps"
              target="accountId">
              <Datagrid >
                <CDeleteButton {...props} />
                <TextField source="imageName" label="Card" />
                <LinkButton label="" source="fileUrl" />
              </Datagrid>
            </ReferenceManyField>}


          </FormTab>}

          {(profileStep === "RECOGNITION" || isRecognitionCompleted) &&
            <FormTab value="DETAILS2" label={ "Recognition and Awards"}      >
              <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
                <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Recoginitions :</span>
              </div> 
              <Button onClick={setAccountRecoginition}>  Add More Recoginitions</Button>
              {!isAccountRecoginition && <ReferenceManyField fullWidth={true}
                addLabel={false}
                reference="account-recognitions"
                target="accountId">
                <Datagrid >
                  <CDeleteButton {...props} />
                  <TextField source="criteria" label="Criteria" />
                  <TextField source="name" label="Name of the Recognition/ Award" />
                  <LinkButton label="" source="url" />
                </Datagrid>
              </ReferenceManyField>}


              <div style={{ height: "40px", width: "100%", borderRadius: "5px" }} >
                <span style={{ padding: "10px", color: "black", textDecoration: "underline" }}>Ratings on other online platforms : </span>
              </div>


              <Button onClick={setAccountRatingMap}>  Add More   </Button>
              {!isAccountRatingMap && <ReferenceManyField fullWidth={true}
                addLabel={false}

                reference="account-rating-maps"
                target="accountId">
                <Datagrid >
                  <CDeleteButton {...props} />
                  <TextField source="platform" label="Platform Name" />
                  <TextField source="link" label="Link To Profile" />
                  <TextField source="ratingCount" label="Rating on the platform" /> 
                </Datagrid>
              </ReferenceManyField>}

              <TextInput source="remarks2" label="Remarks for ServizKart" multiline={true} fullWidth={true} />

            </FormTab>


          }
        </TabbedForm>

      </Edit>}
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isEditExibitor || isAccountServiceAdd || isAccountKeyMembers || isAccountIndustries || isAccountCustomerLocation || isAccountClients || isAccountBusinessProof || isAccountCard || isAccountKeyMgmt || isOfficeAddress || isAccountRecoginition || isAccountRatingMap} >
        {isAccountServiceAdd &&
          <AccountServiceMapCreate
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            {...props} />}

        {isAccountKeyMembers &&
          <AccountKeyMembers
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            editKeyMemberId={editKeyMemberId}
            {...props} />}

        {isAccountIndustries &&
          <AccountIndustries
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            editKeyMemberId={editKeyMemberId}
            {...props} />}
        {isAccountCustomerLocation &&
          <AccountCustomerLocation
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            editKeyMemberId={editKeyMemberId}
            {...props} />}

        {isOfficeAddress &&
          <OtherOfficeAddress
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            editKeyMemberId={editKeyMemberId}
            {...props} />}
        {isAccountClients &&
          <AccountClients
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            editKeyMemberId={editKeyMemberId}
            {...props} />}

        {isAccountBusinessProof &&
          <AccountProof
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            docType={"PROOF"}
            editKeyMemberId={editKeyMemberId}
            {...props} />}

        {isAccountKeyMgmt &&
          <AccountProof
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            docType={"KEYMGMT"}
            editKeyMemberId={editKeyMemberId}
            {...props} />}

        {isAccountCard &&
          <AccountProof
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}
            docType={"CARD"}
            editKeyMemberId={editKeyMemberId}
            {...props} />}

        {isAccountRatingMap &&
          <AccountRatingMap
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}

            editKeyMemberId={editKeyMemberId}
            {...props} />}





        {isAccountRecoginition &&
          <AccountRecoginition
            onClose={closeAccountServiceForm}
            onCancel={onCancel}
            accountid={props.id}

            editKeyMemberId={editKeyMemberId}
            {...props} />}




        {/* {isEditExibitor && <AccountServiceMapEdit
          onClose={closeExbhitorEdit}
          onCancel={onCancel}
          {...props}
          basePath="/account-servicecat-maps"
          resource="account-servicecat-maps"
          accountid={props.id}
          id={editExibitorId} />} */}
      </Drawer>
    </React.Fragment>
  );
};
