import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';

export const RoleTypesList = props => (
    <List {...props} filter={{ 'id~neq': 11 }}>
        <Datagrid rowClick="edit">
            <TextField source="roleName" label="Role Name"/>
            {/* <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/> */}
        </Datagrid>
    </List>
);