import React, { useCallback } from 'react';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,
    FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import ServiceFields from "./ServiceFields";
import TaxFields from "./TaxFields";
import moment from "moment";
 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const ServiceCreate = props => {
    const classes = useStyles({});
    
    let initialValues=
    {
        
        taxHeadId: 0,
        taxHeadPc: 0,
        taxAmount: 0,
        jobLineTotalCost: 0, 
        isApproved: false
      }
    const handleSubmit = async (formdata) => {
         console.log(formdata);
         let user = localStorage.getItem("userId");
         if (user) {
           user = parseInt(user);
         }
       
        let data = {
            jobOrderId:parseInt(props.jobOrderId),
            serviceCategoryId: formdata.serviceCategoryId,
            quantity: formdata.quantity,
            instruction: formdata.instruction,
            expirationDate: formdata.expirationDate,
            extendedPrice: formdata.extendedPrice,
            deliverDate: formdata.deliverDate,
            providerName: formdata.providerName,
            orderLineStatusId: 176,
            customerTargetCost: formdata.customerTargetCost,
            otherCost:  formdata.otherCost,
            processCost:  formdata.processCost,
            taxHeadId: formdata.taxHeadId,
            taxHeadPc: formdata.taxHeadPc,
            taxAmount: formdata.taxAmount,
            jobLineTotalCost: formdata.jobLineTotalCost,
            insertedBy: user,
            insertedAt: moment().toISOString(),
            updatedBy: 0,
            updatedAt:moment().toISOString(),
            isApproved: false
          }
        console.log(data);
        apiClient.addEntiyRecord("job-order-details",data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit}  initialValues={initialValues}
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined"> 
 
               
 <FormDataConsumer formClassName={classes.one_three_input} >
              {formDataProps => (
                <ServiceFields {...formDataProps} />
              )}
            </FormDataConsumer> 
                
                <NumberInput  validate={required()} fullWidth={true} source="quantity" label="Quantity" formClassName={classes.two_three_input}/> 
                <DateInput  validate={required()} fullWidth={true} source="expirationDate" label="Expiration Date" formClassName={classes.last_three_input}/>
 
                <NumberInput  validate={required()} fullWidth={true} source="extendedPrice" label="Extended Price" formClassName={classes.one_three_input} /> 
                <TextInput  multiline validate={required()} fullWidth={true} source="instruction" label="Instruction" formClassName={classes.two_three_input} /> 
                <DateInput  validate={required()} fullWidth={true} source="deliverDate" label="Deliver Date" formClassName={classes.last_three_input}/>
                <TextInput   validate={required()} fullWidth={true} source="providerName" label="Provider Name"  /> 
                
                {/* <ReferenceInput source="taxHeadId" label="Tax Head " perPage={2000} reference="tax-heads" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'taxName', order: 'ASC' }}  >
                    <SelectInput optionText="taxName" validate={required()} />

                </ReferenceInput> */}
                
 <FormDataConsumer formClassName={classes.full_inline_input}  >
              {formDataProps => (
                <TaxFields {...formDataProps} />
              )}
            </FormDataConsumer> 
                
            </SimpleForm>
        </Edit>
    );
}
export default ServiceCreate;