import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
export const usertypeCreate = (props) => {
  return (
    <Create title="User Type Create" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
              <TextInput source="userTypeCode" label="Code" validate={[required()]}  initialValue={"Auto Generate"} />
                <TextInput source="userTypeName" label="Name" validate={[required()]}  />
                <TextInput source="description" label="Description"  />
                <BooleanInput source="isActive" label="Active" initialValue={true} />
      </SimpleForm>
      </Create>
  );
};
