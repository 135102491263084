import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import CountBlock from "./CountBlock";
import MySearches from './my_search';
import JobProgress from './job-progress';
import Subscriptions from './subscriptions';
import './dashboard.css';

const useStyles = makeStyles({
    flexCenter: { padding: 10, justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});
const Dashboard = () => {
    const [db, setdb] = useState([]);
    const [isServiceProvider, setIsServiceProvider] = useState(false);
    const [bsBlock, setBsBlock] = useState([]);
    const [otcBlock, setOtcBlock] = useState([]);
    const [taBlock, setTaBlock] = useState([]);
    const [user, setUser] = useState<any>({});

    const styles = useStyles({});

    const loadDashboard = () => {
        let userid = localStorage.getItem("userId");
        apiClient.get_dashboard(userid).then(res => {
            if (res.length > 0 && res[0].Ord == "Business Solutions") {
                setIsServiceProvider(true);
                let bs = res.filter(item => item.Ord == "Business Solutions");
                setBsBlock(bs);
                let otc = res.filter(item => item.Ord == "OTC");
                setOtcBlock(otc);
                let ta = res.filter(item => item.Ord == "Transaction Amount");
                setTaBlock(ta);
            } else {
                setIsServiceProvider(false);
                setdb(res);
            }

        }).catch(err => {
            console.log(err);
        });
    }
    const loadUserProfile = () => {
        let userid = localStorage.getItem("userId");
        apiClient.getUserById(userid).then(res => {
            console.log(res);
            setUser(res[0]);
        }).catch(err => {

        });
    }
    useEffect(() => {
        loadDashboard();
        loadUserProfile();
    }, []);

    return (
        <div className={styles.flexCenter}>
            <div className='user-information'>
                {user && <div className='user-info-name'>Hello {user.FirstName} {user.LastName}</div>}
                {user && <div>Company: {user.AccountName}, City name</div>}
                {user && <div><strong>Email :</strong>{user.EmailAddress} , <strong>Phone :</strong>{user.MobileNumber}</div>}
            </div>
            <div className="welcome-message">
            Welcome to SERVIZKART, we are glad to be associated with you,
            </div>
            {/* {isServiceProvider && <h2 className='block-heading'>My Searches</h2>}
            {isServiceProvider && <div className='block-container'>
                <div className='services-explored'>Services Explored: [Corporate Legal Services, Strategy and Management]</div>
                <MySearches data={[]} />
            </div>}

            {isServiceProvider && <h2 className='block-heading'>Subscriptions:</h2>}
            {isServiceProvider && <div className='block-container'>
                <Subscriptions data={[]} />
            </div>} */}
            {db && db.length > 0 && <h2 className='block-heading'>Dashboard</h2>}
            {db && db.length > 0 && <div className='block-container'>
                {db.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                        </div>
                    )
                })}
            </div>}


            {isServiceProvider && <div className=''>
                <div><h2 className='block-heading'>Business Solutions</h2></div>
                <div className='block-container'>
                    {bsBlock && bsBlock.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                            </div>
                        )
                    })}
                </div>
                <div><h2 className='block-heading'>OTC</h2></div>
                <div className='block-container'>
                    {otcBlock && otcBlock.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                            </div>
                        )
                    })}
                </div>
                <div><h2 className='block-heading'>Transaction Amount</h2></div>
                <div className='block-container'>
                    {taBlock && taBlock.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                            </div>
                        )
                    })}
                </div>
            </div>}

            <h2 className='block-heading'>Job Progress</h2>
            <div className='block-container'>
                <div className='services-explored'>Services Explored: Service Category Name, Sub Category Name etc</div>
                <JobProgress data={[]} />
            </div>
            <h2 className='block-heading'>Profile</h2>
            {user && <div className='block-container profile-container'>
                <div className='value-parent full-width'><b>Company Name:</b>{user.AccountName}</div>
                <div className='value-parent'><b>Designation:</b>{user.Designation}</div>
                <div className='value-parent'><b>Company Webite:</b>http://www.company.com</div>
                <div className='value-parent'><b>Landline No:</b> {user.MobileNumber}</div>
                <div className='value-parent'><b>Email Address:</b> {user.EmailAddress}</div>
                <div className='value-parent full-width'><b>GST No: </b> {user.GSTNO}</div>
                <div className='value-parent'><b>Alternate Contact Person:</b> Mr. Amit Jain</div>
                <div className='value-parent'><b>Alternate Contact No:</b> 124-456-235 </div>
                <div className='heading-parent'><b>Company Address</b> </div>
                <div className='value-parent full-width'><b>Address 1: </b>{user.Address1}</div>
                <div className='value-parent full-width'><b>Address 2: </b>{user.Address2}</div>
            </div>}

        </div>
    );
}

export default Dashboard;
