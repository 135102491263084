import React from 'react';
import { Button, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";
import EventNoteIcon from '@material-ui/icons/EventNote';

export const useStyles = makeStyles(theme => ({
    cancel_button: {
        marginLeft: 20,
        marginRight: 20,
    },
    main: {
        display: "flex", width: "100%",
        justifyContent: "space-between",
    },
    btnparent: {
        flex: 1,
    },
}));

export const CreateToolbar = props => {
    //console.log("CreateToolbar :");
    //console.log(props);
    //props.record.eventHeaderId
    const classes = useStyles({});
    const history = useHistory();
    const cancelForm = () => {
        history.goBack();
    }
    const gotoEvent = () => {
        let eventid = props.record.eventHeaderId;
        history.push(`/eventheaders/${eventid}/4`);
    }
    let showGottoEvent = false;
    if(props.showgotoevent){
        showGottoEvent = props.showgotoevent
    }
    return (
        <Toolbar {...props} >
            <div className={classes.main}>
                <div className={classes.btnparent}>
                    <SaveButton  {...props}  />
                    <Button
                        className={classes.cancel_button}
                        variant="contained" color="primary"
                        label="Back" size="medium"
                        startIcon={<CancelIcon />}
                        onClick={cancelForm}
                    />
                    
                </div>
                {props.showdelete && <DeleteButton  {...props} />}
            </div>
        </Toolbar>
    )
};