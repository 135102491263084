import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountRatingMap = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    let name="PROOf";
    if(props.docType==="CARD")
    {
        name="Card"
    }
    if(props.docType==="KEYMGMT")
    {
        name="Designation"
    }
    const handleSubmit = async (formdata) => {
        debugger
        let data = {
            accountId: parseInt(props.accountid),
            platform:formdata.platform,
            link:formdata.link,
            ratingCount:formdata.ratingCount,
            comment:"", //formdata.comment, 
            rateDate: "2021-12-19T15:14:15.824Z",
            
 
        }

        if(parseFloat(formdata.ratingCount)>5 || parseFloat(formdata.ratingCount)<0)
        {
            alert("Rating should be between 0 to 5");
            return;
        }
        // {
        //     "accountId": 0,
        //     "platform": "string",
        //     "link": "string",
        //     "ratingCount": 0,
        //     "comment": "string",
        //     "rateDate": "2022-01-02T11:11:36.409Z"
        //   }
        // {
        //     "accountId": 0,
        //     "criteria": "string",
        //     "name": "string",
        //     "year": 0,
        //     "url": "string",
        //     "insertedAt": "2022-01-02T10:15:04.698Z",
        //     "insertedBy": 0,
        //     "updatedAt": "2022-01-02T10:15:04.698Z",
        //     "updatedBy": 0,
        //     "isActive": true
        //   }
        apiClient.addEntiyRecord("account-rating-maps", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });


    }
    return (
        <div>
            {<Create {...props} resource="account-rating-maps" redirect="false">
                <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined"> 
                    <TextInput source="platform" label="Platform" validate={[required()]} fullWidth={true}  />
                    <TextInput source="link" label="Link" validate={[required()]} fullWidth={true} />
                    <NumberInput source="ratingCount" label="Rating (between 0 to 5)" validate={[required()]} fullWidth={true} />   
                </SimpleForm>
            </Create>

            }
        </div>
    );
}
