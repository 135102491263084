import React from 'react';
import {
    Edit, Create, SimpleForm, TextInput, FileInput, FileField, ImageInput, ImageField,NumberInput,
    ReferenceInput, SelectInput, required
} from 'react-admin';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../common/PopupCreateToolbar";
import RichTextInput from "ra-input-rich-text";

export const EditCategoryPopup = props => {
    //console.log(props);
    const defaultValues = { class_id: props.class_id, };


    const handleSubmit = async (formdata) => {
        console.log("Handle Submit : ");
        console.log(formdata);
        let mVideoUrl = await apiClient.UploadFile("videoUrl", formdata.videoUrl);
        if (mVideoUrl == null) {
            mVideoUrl = "";
        }
        let mLogo = await apiClient.UploadFile("logo", formdata.logo);
        if (mLogo == null) {
            mLogo = "";
        }
        if (formdata.parentId == null) {
           formdata.parentId = 0 ; 
        }
        let data = {
            id: parseInt(props.id),
            serviceName: formdata.serviceName,
            serviceDetails: formdata.serviceDetails,
            parentId: formdata.parentId,
            videoUrl: mVideoUrl,
            price: parseInt(formdata.price),
            displayOrder : formdata.displayOrder,
            priceDetail:formdata.priceDetail,
            logo: mLogo,
            isActive: true,
        };
        apiClient.updateServiceCategory(data).then((res) => {
            props.onClose();
        });
    }
    const { assignment } = props;
    return (
        <Edit title="Service Category" {...props} id={props.id} resource="service-categories" redirect="false" >
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<CreateToolbar label="Save Category" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                initialValues={defaultValues}
                variant="outlined">
                <TextInput source="serviceName" fullWidth={true} validate={[required()]} />
                <TextInput source="price" fullWidth={true} validate={[required()]} />
                 
                <RichTextInput source="priceDetail" fullWidth={true} validate={[required()]} />
                <ReferenceInput label="Parent Category" source="parentId" reference="service-categories" fullWidth={true} >
                    <SelectInput optionText="serviceName" />
                </ReferenceInput>
                <TextInput source="serviceDetails" multiline={true} fullWidth={true} validate={[required()]} />

                <ImageInput fullWidth={true} source="logo" label="Logo" accept="image/*">
                    <ImageField source="logo" label="New Logo" />
                </ImageInput>
                <ImageField source="logo" label=" Existing Logo" />
                <FileInput fullWidth={true} source="videoUrl" label="Video Url" accept="video/*">
                    <FileField source="videoUrl" label="File" />
                </FileInput>

            </SimpleForm>
        </Edit>
    );
}