import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Area Name"
      source="name~like"
      alwaysOn
    />
  </Filter>
);
export const AreasList = (props) => {
  return (
    <List {...props} title="Area List"  filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />

        <TextField source="name" label="Area Name " />
        <TextField source="location" label="Location " />
        
        {/* <TextField source="actionCode" label="Action Code" />
        <TextField source="name" label="Discount Name " />
        
        <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/> */}
            <DateField  source="createdDate" label="Created Date"/>
            <DateField  source="modifyDate" label="Updated Date"/>
      </Datagrid>
    </List>
  );
};
