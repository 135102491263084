import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const RealizationOrderPatmentFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});

    const onAmountChangehBillAmt = (e) => {
        let hBillAmt = parseInt(e.target.value);
        let { hospDisc, othDisc, pPaidAmt, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", vclaimAmount);
        form.change("payment", vPayment);
        form.change("disAllAmt", vDissAllAmount);
        form.change("jDisAllAmt", vDissAllAmount);
        form.change("reclAmt", vReclAmt);
    }

    const onAmountChangehospDisc = (e) => {
        let hospDisc = parseInt(e.target.value);
        let { hBillAmt, othDisc, pPaidAmt, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", vclaimAmount);
        form.change("payment", vPayment);
        form.change("disAllAmt", vDissAllAmount);
        form.change("jDisAllAmt", vDissAllAmount);
        form.change("reclAmt", vReclAmt);
    }

    const onAmountChangepPaidAmt = (e) => {
        let pPaidAmt = parseInt(e.target.value);
        let { hospDisc, hBillAmt, othDisc, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", vclaimAmount);
        form.change("payment", vPayment);
        form.change("disAllAmt", vDissAllAmount);
        form.change("jDisAllAmt", vDissAllAmount);
        form.change("reclAmt", vReclAmt);
    }


    const onAmountChangesettleAmt = (e) => {
        let settleAmt = parseInt(e.target.value);
        let { pPaidAmt, hospDisc, hBillAmt, othDisc, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", vclaimAmount);
        form.change("payment", vPayment);
        form.change("disAllAmt", vDissAllAmount);
        form.change("jDisAllAmt", vDissAllAmount);
        form.change("reclAmt", vReclAmt);
    }
    const onAmountChangetdsamt = (e) => {
        let tdsamt = parseInt(e.target.value);
        let { settleAmt, pPaidAmt, hospDisc, hBillAmt, othDisc,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", vclaimAmount);
        form.change("payment", vPayment);
        form.change("disAllAmt", vDissAllAmount);
        form.change("jDisAllAmt", vDissAllAmount);
        form.change("reclAmt", vReclAmt);
    }

    const onAmountChangeuDisAllAmt = (e) => {
        let uDisAllAmt = parseInt(e.target.value);
        let { claimAmt, disAllAmt } = formData;

        let varjDisAllAmt = disAllAmt - uDisAllAmt;
        let per = Math.round((uDisAllAmt / (claimAmt + varjDisAllAmt)) * 100);
        form.change("jDisAllAmt", varjDisAllAmt);
        form.change("uDisAllAmtPer", per);
        
        if(per < 1){
            form.change("rpriotiyId", 17);
        }else if (per >= 1 && per <= 5){
            form.change("rpriotiyId", 16);
        }else if(per > 5){
            form.change("rpriotiyId", 15);
        }
    }

    return (
        <div className="payment-field-parent">
            <NumberInput variant="outlined" label="Hospital bill amount" source="hBillAmt"
                onChange={(e) => {
                    onAmountChangehBillAmt(e);
                }}
                fullWidth={true} className={classes.one_4_input} />
            <NumberInput variant="outlined" label="Hospital Discount"
                onChange={(e) => {
                    onAmountChangehospDisc(e);
                }}
                source="hospDisc" fullWidth={true} className={classes.two_4_input} />
            <NumberInput variant="outlined" label="Other Discount" source="othDisc"
                fullWidth={true} className={classes.three_4_input} />

            <NumberInput variant="outlined" label="Patient Paid Amount"
                onChange={(e) => {
                    onAmountChangepPaidAmt(e);
                }}
                source="pPaidAmt" fullWidth={true} className={classes.last_4_input} />

            <NumberInput disabled={true} variant="outlined" label="Claim Amount" source="claimAmt" fullWidth={true} className={classes.one_4_input} />
            <NumberInput disabled={true} variant="outlined" label="Disallow. Amount" source="disAllAmt" fullWidth={true} className={classes.two_4_input} />
            <NumberInput variant="outlined" label="Settlement Amount"
                onChange={(e) => {
                    onAmountChangesettleAmt(e);
                }}
                source="settleAmt" fullWidth={true} className={classes.three_4_input} />
            <NumberInput variant="outlined"onChange={(e) => {
                    onAmountChangeuDisAllAmt(e);
                }}
                 label="Unjustified disallow Amount" initialValue="0" source="uDisAllAmt" fullWidth={true} className={classes.last_4_input} />

            <NumberInput variant="outlined" disabled={true} label="Justified disallow Amount"
                source="jDisAllAmt" fullWidth={true} className={classes.one_4_input} />

            <NumberInput disabled={true} variant="outlined" label="Unjustified disallow %" source="uDisAllAmtPer" fullWidth={true} className={classes.two_4_input} />
            <TextInput variant="outlined" label="Disallow Details" source="disAllDet" fullWidth={true} className={classes.twice_4_input} />

            <NumberInput variant="outlined" label="TDS Amount"
                onChange={(e) => {
                    onAmountChangetdsamt(e);
                }}
                source="tdsamt"
                fullWidth={true} className={classes.one_4_input} />
            <NumberInput disabled={true} variant="outlined" label="Net Payment" source="payment" fullWidth={true} className={classes.two_4_input} />
            <TextInput variant="outlined" label="NEFT Numbers" source="neftDet" fullWidth={true} className={classes.three_4_input} />
            <DateInput variant="outlined" label="NEFT Date" source="neftDate" fullWidth={true} className={classes.last_4_input} />

            <ReferenceInput disabled={true} variant="outlined" label="Reclaim Priority" source="rpriotiyId" reference="lookups" className={classes.one_4_input}
                filter={{ type: 'RPR' }} fullWidth={true} >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.reclDt == null) {
                        form.change("reclDt", new Date());
                    }
                }}
                label="Reclaim Raised ?" source="reclRaised" fullWidth={true} className={classes.two_4_input} />
            <DateInput variant="outlined" disabled={(formData.reclRaised == true) ? false : true} label="Reclaim Date" source="reclDt" fullWidth={true} className={classes.three_4_input} />
            <NumberInput variant="outlined" label="Amt. Reclaimed Agnst Deduction" source="reclAmt" fullWidth={true} className={classes.last_4_input} />

            <NumberInput variant="outlined" label="Reclaim Amount Received" source="reclRecAmt" fullWidth={true} className={classes.one_three_input} />
            <TextInput variant="outlined" label="Reclaim NEFT Numbers" source="reclNeftNo" fullWidth={true} className={classes.two_three_input} />
            <DateInput variant="outlined" label="Reclaim NEFT Date" source="reclNeftDt" fullWidth={true} className={classes.last_three_input} />

            <TextInput variant="outlined" multiline={true} label="Reclaim Follow. Rem." source="reclRem" fullWidth={true} />

        </div>
    )
}
