import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountServiceMapCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    const handleSubmit = async (formdata) => {
        console.log(formdata);
        let serviceCategoryLogo = "";
        let serviceCatalogue = "";
        if (formdata.serviceCategoryLogo) {
            serviceCategoryLogo = await apiClient.UploadFile("serviceCategoryLogo", formdata.serviceCategoryLogo);
        }
        if (formdata.serviceCatalogue) {
            serviceCatalogue = await apiClient.UploadFile("serviceCatalogue", formdata.serviceCatalogue);
        }
        let data = {
            serviceCategoryLogo: serviceCategoryLogo,
            serviceCatalogue: serviceCatalogue,
            accountId: parseInt(props.accountid),
            serviceCategoryId: formdata.serviceCategoryId,
            serviceDetails: formdata.serviceDetails,
            isActive: formdata.isActive
        }
        apiClient.createAccountServiceCategoryMap(data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <ReferenceInput source="serviceCategoryId" reference="service-categories" fullWidth={true} >
                    <SelectInput optionText="serviceName" />
                </ReferenceInput>

                <TextInput multiline validate={required()} fullWidth={true} source="serviceDetails" label="Service Details" />
                <ImageInput fullWidth={true} source="serviceCategoryLogo" label="Logo" accept="image/*">
                    <ImageField source="serviceCategoryLogo" title="Logo" />
                </ImageInput>

                <FileInput fullWidth={true} source="serviceCatalogue" label="Service Catalog" accept="application/pdf,application/vnd.ms-powerpoint">
                    <FileField source="serviceCatalogue" title="File" />
                </FileInput>

            </SimpleForm>
        </Edit>
    );
}
