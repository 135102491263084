import { stringify } from "query-string";
import { fetchUtils, DataProvider, HttpError } from "ra-core";
import * as apiClient from "../common/apiClient";
import { responsePathAsArray } from "graphql";
import moment from "moment";
import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from "constants";
import uuid from "react-uuid";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const setProperDates = (data) => {
  /** ========================Realization Data================================== */
  if (data.hasOwnProperty("doa") && data.doa != null) {
    let m = moment(data.doa);
    data.doa = m.toISOString();
  }

  if (data.hasOwnProperty("dod") && data.dod != null) {
    let m = moment(data.dod);
    data.dod = m.toISOString();
  }

  if (data.hasOwnProperty("frecDate") && data.frecDate != null) {
    let m = moment(data.frecDate);
    data.frecDate = m.toISOString();
  }

  if (data.hasOwnProperty("fSubDate") && data.fSubDate != null) {
    let m = moment(data.fSubDate);
    data.fSubDate = m.toISOString();
  }

  if (data.hasOwnProperty("hdcqDt") && data.hdcqDt != null) {
    let m = moment(data.hdcqDt);
    data.hdcqDt = m.toISOString();
  }

  if (data.hasOwnProperty("hdcQrDt") && data.hdcQrDt != null) {
    let m = moment(data.hdcQrDt);
    data.hdcQrDt = m.toISOString();
  }

  if (data.hasOwnProperty("hospqDet") && data.hospqDet != null) {
    let m = moment(data.hospqDet);
    data.hospqDet = m.toISOString();
  }

  if (data.hasOwnProperty("hospqDt") && data.hospqDt != null) {
    let m = moment(data.hospqDt);
    data.hospqDt = m.toISOString();
  }

  if (data.hasOwnProperty("neftDate") && data.neftDate != null) {
    let m = moment(data.neftDate);
    data.neftDate = m.toISOString();
  }

  if (data.hasOwnProperty("reclDt") && data.reclDt != null) {
    let m = moment(data.reclDt);
    data.reclDt = m.toISOString();
  }

  if (data.hasOwnProperty("reclNeftDt") && data.reclNeftDt != null) {
    let m = moment(data.reclNeftDt);
    data.reclNeftDt = m.toISOString();
  }

  if (data.hasOwnProperty("billRDt") && data.billRDt != null) {
    let m = moment(data.billRDt);
    data.billRDt = m.toISOString();
  }

  if (data.hasOwnProperty("rInvDt") && data.rInvDt != null) {
    let m = moment(data.rInvDt);
    data.rInvDt = m.toISOString();
  }

  if (data.hasOwnProperty("rBillRDt") && data.rBillRDt != null) {
    let m = moment(data.rBillRDt);
    data.rBillRDt = m.toISOString();
  }

  if (data.hasOwnProperty("rBillRDt") && data.rBillRDt != null) {
    let m = moment(data.rBillRDt);
    data.rBillRDt = m.toISOString();
  }
  return data;

  /** ================================================================ */
}

const UploadFile = (field: string, data: any) => {
  const formData = new FormData();
  formData.append(field, data.rawFile);
  let APIUrl = apiClient.APIUrl; //"http://localhost:8086/";
  return fetch(`${APIUrl}uploadFile`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
    });
};
const getBaseUrl = (url: string): string => {
  if (url.indexOf("?") != -1) {
    url = url.substring(0, url.indexOf("?"));
  }
  return url;
};

export const VSfetchJson = (url: any, options: fetchUtils.Options = {}) => {

  const requestHeaders = fetchUtils.createHeadersFromOptions(options);
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  });

  //headers.append("Content-Type", "application/json");

  return fetch(url, { ...options, headers: headers })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if(status == 401){
        alert("Token Expired , Please relogin.");
        return Promise.reject(
          new HttpError(
            "Token Expired , Please relogin.",
            status,
            ""
          )
        );
      }
      if (status == 409) {
        return Promise.reject(
          new HttpError(
            (json && json.error && json.error.message) || statusText,
            status,
            json
          )
        );
      }

      if (status == 422) {
        let message = "**VALIDATION FAILED** ";
        if (json.error && json.error.details && json.error.details.length > 0) {
          json.error.details.forEach((element: any) => {
            let f = element;
            let mf = f.path + " " + f.message;
            message = message + " , " + mf;
          });
        }
        return Promise.reject(new HttpError(message, status, json));
      }

      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default (apiUrl: String, httpClient = VSfetchJson): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };

    var keys = Object.keys(filter);
    let where = "";
    keys.forEach(function (key) {
      var val: string = filter[key];
      var otype = typeof val;
      // console.log(otype);
      if (otype == "string") {
        if (val.startsWith("nin~") || val.startsWith("inq~")) {
          var arr = val.split("~");
          var opr = arr[0];
          var opts = arr[1].split(",");
          opts.forEach((item) => {
            if (item != "") where += `&filter[where][${key}][${opr}]=${item}`;
          });
        } else {
          var item = filter[key];
          var keyar = key.split("~");
          if (keyar.length > 1) {
            if (keyar[1] == "like") {
              var fld = keyar[0];
              var opr = keyar[1];
              if (item != "")
                where += `&filter[where][${fld}][${opr}]=%${item}%`;
            }
          } else {
            if (item != "") where += `&filter[where][${key}]=${item}`;
          }
        }
      } else if (otype == "number") {
        where += `&filter[where][${key}]=${val}`;
      }
    });
    const loopbackquery = `filter[offset]=${(page - 1) * perPage
      }&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
    const url = `${apiUrl}/${resource}?${loopbackquery}`;

    return httpClient(url).then(({ headers, json }) => {

      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      let count: string = "10";
      count = headers.get("x-total-count")!.split("/").pop()!;
      return {
        data: json,
        total: parseInt(count, 10),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    /* const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`; */

    var ids = params.ids;
    let where = "";
    if (ids.length == 1) {
      where = `filter[where][id]=` + ids[0];
    } else {
      ids.forEach(function (id, index) {
        where += `&filter[where][or][${index}][id]=${id}`;
      });
    }
    const url = `${apiUrl}/${resource}?${where}`;

    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    var keys = Object.keys(filter);
    let where = "";
    keys.forEach(function (key) {
      where += `&filter[where][${key}]=` + filter[key];
    });
    if (params.target != undefined) {
      let col = params.target;
      let val = params.id;
      where += `&filter[where][${col}]=${val}`;
    }

    const loopbackquery = `filter[offset]=${(page - 1) * perPage
      }&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
    const url = `${apiUrl}/${resource}?${loopbackquery}`;

    //const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {

      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      let count: string = "10";
      count = headers.get("x-total-count")!.split("/").pop()!;
      return {
        data: json,
        total: parseInt(count, 10),
      };
    });
  },

  update: async (resource, params) => {
    let data = params.data;
    if (data.hasOwnProperty("imageurl")) {
      if (data.imageurl != null && data.imageurl.rawFile != null) {
        let response = await UploadFile("imageurl", data.imageurl);
        if (response.files && response.files.length > 0) {
          data.imageurl = getBaseUrl(response.files[0].url);
          if (data.hasOwnProperty("logo")) {
            data.logo = data.imageurl;
          }
        } else {
          if (data.hasOwnProperty("logo")) {
            data.logo = "";
          }
        }
      }
    }
    if (resource === "paymentmethods" && data.hasOwnProperty("imageurl")) {
      delete data["imageurl"];
    }

    if (resource === "users") {
      if (data.hasOwnProperty("imageurl")) {
        data.imageUrl = data.imageurl;
        delete data["imageurl"];
      }
      if ( data.id === null) {
        data.id = 0;
      }
      // if (data.hasOwnProperty("typeId") && data.typeId === null) {
      //   data.typeId = 0;
      // }
      // if (data.hasOwnProperty("linkId") && data.linkId === null) {
      //   data.linkId = 0;
      // }
      // if (data.hasOwnProperty("hospitalId") && (data.hospitalId === null || data.hospitalId === '')) {
      //   data.hospitalId = 0;
      // }
      // if (data.hasOwnProperty("hospitalId") === false) {
      //   data.hospitalId = 0;
      // }
    }

    if (data.hasOwnProperty("add2") && data.add2 === null) {
      data.add2 = "";
    }
    if (data.hasOwnProperty("phoffice") && data.phoffice === null) {
      data.phoffice = "";
    }

    if (data.hasOwnProperty("phmobile") && data.phmobile === null) {
      data.phmobile = "";
    }
    if (data.hasOwnProperty("cstNo") && data.cstNo === null) {
      data.cstNo = "";
    }
    if (data.hasOwnProperty("tpt") && data.tpt === null) {
      data.tpt = "";
    }
    if (data.hasOwnProperty("licno") && data.licno === null) {
      data.licno = "";
    }

    if (data.hasOwnProperty("panno") && data.panno === null) {
      data.panno = "";
    }
    if (data.hasOwnProperty("email") && data.email === null) {
      data.email = "";
    }
    if (data.hasOwnProperty("tin") && data.tin === null) {
      data.tin = "";
    }



    if (data.hasOwnProperty("add3") && data.add3 === null) {
      data.add3 = "";
    }
    if (data.hasOwnProperty("cpName1") && data.cpName1 === null) {
      data.cpName1 = "";
    }
    if (data.hasOwnProperty("cpName2") && data.cpName2 === null) {
      data.cpName2 = "";
    }
    if (data.hasOwnProperty("cpName3") && data.cpName3 === null) {
      data.cpName3 = "";
    }
    if (data.hasOwnProperty("cpName4") && data.cpName4 === null) {
      data.cpName4 = "";
    }
    if (data.hasOwnProperty("cpName5") && data.cpName5 === null) {
      data.cpName5 = "";
    }

    if (data.hasOwnProperty("cpMob1") && data.cpMob1 === null) {
      data.cpMob1 = "";
    }
    if (data.hasOwnProperty("cpMob2") && data.cpMob2 === null) {
      data.cpMob2 = "";
    }
    if (data.hasOwnProperty("cpMob3") && data.cpMob3 === null) {
      data.cpMob3 = "";
    }
    if (data.hasOwnProperty("cpMob4") && data.cpMob4 === null) {
      data.cpMob4 = "";
    }
    if (data.hasOwnProperty("cpMob5") && data.cpMob5 === null) {
      data.cpMob5 = "";
    }

    if (data.hasOwnProperty("cpRes1") && data.cpRes1 === null) {
      data.cpRes1 = "";
    }
    if (data.hasOwnProperty("cpRes2") && data.cpRes2 === null) {
      data.cpRes2 = "";
    }
    if (data.hasOwnProperty("cpRes3") && data.cpRes3 === null) {
      data.cpRes3 = "";
    }
    if (data.hasOwnProperty("cpRes4") && data.cpRes4 === null) {
      data.cpRes4 = "";
    }
    if (data.hasOwnProperty("cpRes5") && data.cpRes5 === null) {
      data.cpRes5 = "";
    }


    if (data.hasOwnProperty("address1") && data.address1 === null) {
      data.address1 = "";
    }

    if (data.hasOwnProperty("linkedinId") && data.linkedinId === null) {
      data.linkedinId = "";
    }
    if (data.hasOwnProperty("fbid") && data.fbid === null) {
      data.fbid = "";
    }
    if (data.hasOwnProperty("twiterId") && data.twiterId === null) {
      data.twiterId = "";
    }
    if (data.hasOwnProperty("whatsupMobileNo") && data.whatsupMobileNo === null) {
      data.whatsupMobileNo = "";
    }
    if (data.hasOwnProperty("dealStartDate") && data.dealStartDate === null) {
      data.dealStartDate = "";
    }
    if (data.hasOwnProperty("dealEndDate") && data.dealEndDate === null) {
      data.cpRes5 = "";
    }
    if (data.hasOwnProperty("qrCode") && data.qrCode === null) {
      data.qrCode = "";
    }
    if (data.hasOwnProperty("qrId") && data.qrId === null) {
      data.qrId = "";
    }
    if (data.hasOwnProperty("insertedBy") && data.insertedBy === null) {
      data.insertedBy = "";
    }
        if (data.hasOwnProperty("updatedBy") && data.updatedBy === null) {
      data.updatedBy = "";
    }
        if (data.hasOwnProperty("updatedAt") && data.updatedAt === null) {
      data.updatedAt = "";
    }
     
    if (data.hasOwnProperty("logo") && data.logo === null) {
      data.logo = "";
    }
    if (data.hasOwnProperty("isActive") && data.isActive === null) {
      data.isActive = false;
    }
    // if (data.hasOwnProperty("isActive")) {
    //   data.isActive = data.isActive === true ? 1 : 0;
    // }
    if (data.hasOwnProperty("ordernumber")) {
      delete data["ordernumber"];
    }
    if (data.hasOwnProperty("logo")) {
      if (data.logo != null && data.logo.rawFile != null) {
        let response = await UploadFile("logo", data.logo);
        if (response.files && response.files.length > 0) {
          data.logo = getBaseUrl(response.files[0].url);
        } else {
          data.logo = "";
        }
      }
    }

    if (data.hasOwnProperty("clientLogo")) {
      if (data.clientLogo != null && data.clientLogo.rawFile != null) {
        let response = await UploadFile("clientLogo", data.clientLogo);
        if (response.files && response.files.length > 0) {
          data.clientLogo = getBaseUrl(response.files[0].url);
        } else {
          data.clientLogo = "";
        }
      }
    }

    if (data.hasOwnProperty("image")) {
      if (data.image != null && data.image.rawFile != null) {
        let response = await UploadFile("image", data.image);
        if (response.files && response.files.length > 0) {
          data.image = getBaseUrl(response.files[0].url);
        } else {
          data.image = "";
        }
      }
    }
    
    if (data.hasOwnProperty("eventstartdate")) {
      let m = moment(data.eventstartdate);
      data.eventstartdate = m.toISOString();
    }
    if (data.hasOwnProperty("address2") && data.address2 == null) {

      data.address2 = "";
    }
    if (data.hasOwnProperty("otherMobile") && data.otherMobile == null) {

      data.otherMobile = "";
    }

    if (data.hasOwnProperty("eventenddate")) {
      let m = moment(data.eventenddate);
      data.eventenddate = m.toISOString();
    }
    if (data.hasOwnProperty("manufacturerdate")) {
      let m = moment(data.manufacturerdate);
      data.manufacturerdate = m.toISOString();
    }
    if (data.hasOwnProperty("accountId") && data.accountId === null) {
      let accountId: any = localStorage.getItem("accountid");
      if (accountId) {
        data.accountId = parseInt(accountId);
      }
    }
    if (data.hasOwnProperty("daytimeSaving")) {
      data.daytimeSaving = data.dayTimeSaving === true ? 1 : 0;
    }
    if (data.hasOwnProperty("expirydate")) {
      let m = moment(data.expirydate);
      data.expirydate = m.toISOString();
    }
    if (data.hasOwnProperty("expirydate")) {
      let m = moment(data.expirydate);
      data.expirydate = m.toISOString();
    }
    if (data.hasOwnProperty("insertedBy")) {
      let user: any = localStorage.getItem("userId");
      if (user) {
        user = parseInt(user);
      }
    }

    data = setProperDates(data);

    if (
      data.hasOwnProperty("isEmailVerified") &&
      data.isEmailVerified === null
    ) {
      data.isEmailVerified = 0;
    }

    if (data.hasOwnProperty("updatedBy")) {
      let user: any = localStorage.getItem("userId");
      if (user) {
        user = parseInt(user);
      }
    }
    if (data.hasOwnProperty("createdby") && data.createdby===null) {
      let user: any = localStorage.getItem("userId");
      if (user) {
        user = parseInt(user);
      }
      data.createdby=user;
    }
    
     
    if (data.hasOwnProperty("upperRange") && data.upperRange === null) {
      data.upperRange = 0;
    }
    if (data.hasOwnProperty("lowerRange") && data.lowerRange === null) {
      data.lowerRange = 0;
    }

    /* var keys = Object.keys(data);
        keys.forEach((item) => {
            if (data[item] == null) {
                delete data[item];
            }
        }); */
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    if (data.hasOwnProperty("enquiryDate")) {
      let m = moment();
      data.enquiryDate = m.toISOString();
    }

    if (data.hasOwnProperty("createdDate") && data.createdDate == null) {
      data.createdDate = m.toISOString();
    }
    if (data.hasOwnProperty("updatedAt")) {
      data.updatedAt = m.toISOString();
    }
    if (data.hasOwnProperty("createdBy") && data.createdBy == null) {
      data.createdBy = user;
    }
    if (data.hasOwnProperty("updatedBy")) {
      data.updatedBy = user;
    }
    if (data.hasOwnProperty("modifyDate")) {
      data.modifyDate = m.toISOString();
    }
    if (data.hasOwnProperty("modifyBy")) {
      data.modifyBy = user;
    }
    if (data.hasOwnProperty("otp") && data.otp === null) {
      data.otp = 0;
    }
    if (
      data.hasOwnProperty("passwordTokenExpire") &&
      data.passwordTokenExpire === null
    ) {
      data.passwordTokenExpire = 0;
    }
    if (data.hasOwnProperty("uuid") && data.uuid === null) {
      data.uuid = uuid();
    }
    if (data.hasOwnProperty("registerVia") && data.registerVia === null) {
      data.registerVia = "Web";
    }
    if (
      data.hasOwnProperty("passwordResetToken") &&
      data.passwordResetToken === null
    ) {
      data.passwordResetToken = "-";
    }

    if (data.hasOwnProperty("imageUrl") && (data.imageUrl === null || data.imageUrl === undefined)) {
      data.imageUrl = "";
    }


    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: async (resource, params) => {
    debugger
    let data = params.data;
    if (data.hasOwnProperty("imageurl")) {
      if (data.imageurl != null && data.imageurl.rawFile != null) {
        let response = await UploadFile("imageurl", data.imageurl);
        if (response.files && response.files.length > 0) {
          data.imageurl = getBaseUrl(response.files[0].url);
          if (data.hasOwnProperty("logo")) {
            data.logo = data.imageurl;
          }
          if (data.hasOwnProperty("img")) {
            data.logo = data.imageurl;
          }
        } else {
          if (data.hasOwnProperty("logo")) {
            data.logo = "";
          }
          data.imageurl = "";
        }
      }
    }

    if (data.hasOwnProperty("clientLogo")) {
      if (data.clientLogo != null && data.clientLogo.rawFile != null) {
        let response = await UploadFile("clientLogo", data.clientLogo);
        if (response.files && response.files.length > 0) {
          data.clientLogo = getBaseUrl(response.files[0].url);
        } else {
          data.clientLogo = "";
        }
      }
    }

    if (data.hasOwnProperty("image")) {
      if (data.image != null && data.image.rawFile != null) {
        let response = await UploadFile("image", data.image);
        if (response.files && response.files.length > 0) {
          data.image = getBaseUrl(response.files[0].url);
        } else {
          data.image = "";
        }
      }
    }

    if (resource === "users") {
      if (data.hasOwnProperty("imageurl")) {
        data.imageUrl = data.imageurl;
        delete data["imageurl"];
      }
      if (data.hasOwnProperty("imageUrl") && data.imageUrl === null) {
        data.imageUrl = "";
      }
      
      // data.typeId = 0;
      // data.linkId = 0;
      // if (data.hasOwnProperty("hospitalId") && (data.hospitalId === null || data.hospitalId === '')) {
      //   data.hospitalId = 0;
      // }
      // if (data.hasOwnProperty("hospitalId") === false) {
      //   data.hospitalId = 0;
      // }
      // //data.typeId=0;
      // data.linkId=0;
      //  if(data.hasOwnProperty("hospitalId") && (data.hospitalId===null || data.hospitalId===''))
      //   {
      //     data.hospitalId=0;
      //   }
      //   if(data.hasOwnProperty("hospitalId")===false)
      //   {
      //     data.hospitalId=0;
      //   }
    }

    
    if (data.hasOwnProperty("address1") && data.address1 === null) {
      data.address1 = "";
    }

    if (data.hasOwnProperty("linkedinId") && data.linkedinId === null) {
      data.linkedinId = "";
    }
    if (data.hasOwnProperty("fbid") && data.fbid === null) {
      data.fbid = "";
    }
    if (data.hasOwnProperty("twiterId") && data.twiterId === null) {
      data.twiterId = "";
    }
    if (data.hasOwnProperty("whatsupMobileNo") && data.whatsupMobileNo === null) {
      data.whatsupMobileNo = "";
    }
    if (data.hasOwnProperty("dealStartDate") && data.dealStartDate === null) {
      data.dealStartDate = "";
    }
    if (data.hasOwnProperty("dealEndDate") && data.dealEndDate === null) {
      data.cpRes5 = "";
    }
    if (data.hasOwnProperty("qrCode") && data.qrCode === null) {
      data.qrCode = "";
    }
    if (data.hasOwnProperty("qrId") && data.qrId === null) {
      data.qrId = "";
    }
    if (data.hasOwnProperty("insertedBy") && data.insertedBy === null) {
      data.insertedBy = "";
    }
        if (data.hasOwnProperty("updatedBy") && data.updatedBy === null) {
      data.updatedBy = "";
    }
        if (data.hasOwnProperty("updatedAt") && data.updatedAt === null) {
      data.updatedAt = "";
    }
     

    data = setProperDates(data);

    if (data.hasOwnProperty("isactive") && data.isactive == "") {
      data.isactive = true;
    }
    if (data.hasOwnProperty("starttime")) {
      let m = moment(data.starttime);
      data.starttime = m.toISOString();
    }
    if (data.hasOwnProperty("endtime")) {
      let m = moment(data.endtime);
      data.endtime = m.toISOString();
    }

    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();
    if (data.hasOwnProperty("enquiryDate")) {
      let m = moment();
      data.expirydate = m.toISOString();
    }
    if (data.hasOwnProperty("insertedAt")) {
      data.insertedAt = m.toISOString();
    }
    if (data.hasOwnProperty("insertedBy")) {
      data.insertedBy = user;
    }
    if (data.hasOwnProperty("updatedAt")) {
      data.updatedAt = m.toISOString();
    }
    if (data.hasOwnProperty("updatedBy")) {
      data.updatedBy = user;
    }

    // data.id = 0;
   // data.modifyBy = user;
   // data.createdBy = user;
   // data.modifyDate = m.toISOString();
   // data.createdDate = m.toISOString();
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => {
      console.log(json);
      return {
        data: { ...params.data, id: json.id },
      };
    });
  },
  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});
