import React, { useState, useEffect } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
  getFlatDataFromTree
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import * as apiClient from "../../common/apiClient";
import { makeStyles, Drawer } from '@material-ui/core';
import { EditCategoryPopup } from "./EditCategoryPopup";
import { ToastContainer, toast } from 'react-toastify';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 350,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 0,
    width: 650,
    maxWidth: "100%",
    paddingBottom: 50
  },
}));

const firstNames = [
  'Sample Service',
  'Sample Service',
  'Sample Service',
  'Sample Service',
  'Sample Service',
  'Sample Service',
  'Sample Service',
];
export const ServiceCategoryList = props => {
  const myclasses = myStyles({});
  const [categories, setCategories] = useState<any>([]);
  const [treeData, setTreeData] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editNode, setEditNode] = useState<any>(null);
  const [editNodeId, setEditNodeId] = useState<any>(0);
  const [nodeState, setNodeState] = useState<any[]>([]);

  const loadChildCategories = (category, allCategories) => {
    let allChildrens = allCategories.filter(i => i.parentId == category.id);

    allChildrens.forEach(cat => {
      let status = nodeState[category.id];
      let isExpended = false;
      if (status)
        isExpended = true;
      let child = loadChildCategories(cat, allCategories);
      cat.title = cat.serviceName;
      cat.children = child;
      cat.expanded = isExpended;
    });
    return allChildrens;
  }
  const deleteCategory = (node) => {
    //console.log(node);
    if (node.children == null || node.children.length == 0) {
      apiClient.deleteServiceCategory(node).then(res => {
        loadServiceCategories();
      }).catch(err => {
        console.log(err);
      });
    } else {
      toast.error("Validation issue : The category with sub-category cannot be deleted. Please delete all sub ctegories first.", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }
  const addCategory = (parentNode, path) => {
    //console.log(parentNode);
    //console.log(path);
    let parentID = 0;
    if (parentNode) {
      parentID = parentNode.id;
    }
    let data = {
      parentId: parentID,
      serviceName: "Sample Service",
      serviceDetails: "",
      logo: "",
      isActive: true
    };
    apiClient.addServiceCategory(data).then(res => {
      loadServiceCategories();
    }).catch(err => {
      console.log(err);
    });
  }
  const loadServiceCategories = () => {
    apiClient.getServiceCategories().then(res => {
      //console.log(res);
      if (res.length > 0) {
        setCategories(res);
        let parentCategories = res.filter(item => item.parentId == 0);
        //console.log(parentCategories);
//console.log(nodeState, "parentCategories ====== nodeState=====");
        parentCategories.forEach(cat => {
          let status = nodeState[cat.id];
          let isExpended = false;
          if (status)
            isExpended = true;

          let child = loadChildCategories(cat, res);
          cat.title = cat.serviceName;
          cat.children = child;
          cat.expanded = isExpended;
        });
        //console.log(parentCategories);
        setTreeData(parentCategories);

        // setTreeData(
        //   toggleExpandedForAll({
        //     treeData: parentCategories,
        //     expanded: false
        //   }),
        // );
      }
    }).catch(err => {
      console.log("erro : " + err);
    });
  }

  const onCancel = () => {
    setEditNode(null);
    setIsEditing(false);
  }
  const closeSectionForm = () => {
    setEditNode(null);
    setIsEditing(false);
    loadServiceCategories();
  }

  const updateCategoryOrder = (cat, myorder, parent_id) => {
    const {parentId, id, children} = cat;
    apiClient.updateServiceCategoryDisplayOrder(id, myorder, parent_id);
    let order = (myorder * 100) +  1;
    children.forEach(item => {
      updateCategoryOrder(item, order, id);
      order++;
    });
  }
  const onMoveNode = (data: any) => {
    const {treeData,node, nextParentNode, prevPath, prevTreeIndex, nextPath,nextTreeIndex }  = data;
    console.log(treeData, "===treeData===");

    //Update the Order in database//
    let order = 1;
    treeData.forEach(cat => {
      updateCategoryOrder(cat, order, 0);
      order++;
    });
  }
  useEffect(() => {
    loadServiceCategories();
  }, []);


  const getNodeKey = ({ treeIndex }) => treeIndex;
  const getRandomName = () =>
    firstNames[Math.floor(Math.random() * firstNames.length)];
  return (
    <React.Fragment>
      <div>
        <h2 className="page-title">Service Category</h2>
        <div className="category-page">
          <button
            onClick={() => {
              addCategory(null, null);
              // setTreeData(treeData.concat({
              //   title: `${getRandomName()}`,
              // }));
            }
            }
          >
            Add Root category
          </button>
          <SortableTree canDrag={true}
            treeData={treeData}
            onMoveNode={onMoveNode}
            onVisibilityToggle={(params) => {
              let id = params.node.id;
              const nodeStateArr = [...nodeState];
              nodeStateArr[id] = params.expanded;
              setNodeState(nodeStateArr);
              console.log(nodeStateArr, "===nodeStateArr===");
            }}
            onChange={treeData => { setTreeData(treeData); }}
            generateNodeProps={({ node, path }) => ({
              title: node.title,  
              buttons: [
                <img alt="logo" className="category-logo" src={node.logo} />,
                <button
                  onClick={() => {
                    //console.log(node);
                    setEditNode(node);
                    setEditNodeId(node.id)
                    setIsEditing(true);
                  }}>
                  <i className="fa fa-edit"></i>
                </button>,
                <button
                  onClick={() => {
                    addCategory(node, path);
                    setTreeData(addNodeUnderParent({
                      treeData: treeData,
                      parentKey: path[path.length - 1],
                      expandParent: true,
                      getNodeKey,
                      newNode: {
                        title: `${getRandomName()}`,
                      }
                    }).treeData)
                  }

                  }
                >
                  <i className="fa fa-plus"></i>
                </button>,
                <button
                  onClick={() => {
                    deleteCategory(node);
                    // setTreeData(removeNodeAtPath({
                    //   treeData: treeData,
                    //   path,
                    //   getNodeKey,
                    // }))
                  }

                  }
                >
                  <i className="fa fa-trash"></i>
                </button>,
              ],
            })}
          />
        </div>
      </div>
      <Drawer
        anchor="right"
        onClose={onCancel}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isEditing}>
        {isEditing && <EditCategoryPopup
          onClose={closeSectionForm}
          onCancel={onCancel}
          {...props}
          basePath="/service-categories"
          id={editNodeId}
          editNode={editNode} />}
      </Drawer>
    </React.Fragment>
  );
}
