import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

export const AccountClients = props => {
    const classes = useStyles({});
    const initialValues = {
        imagesize: 1, imagetypeid: 1, isverified: true,
        isActive: true, isdefault: true, accountid: props.accountid
    }
    const handleSubmit = async (formdata) => {
        let data = {
            accountId: parseInt(props.accountid),
            name: formdata.name,
            website: formdata.website,
            insertedBy: 0,
            insertedAt: "2021-12-19T09:19:58.890Z",
            updatedBy: 0,
            updatedAt: "2021-12-19T09:19:58.890Z",
            isActive: true 
        }
        apiClient.addEntiyRecord("account-clients", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });


    }
    return (
        <div>
            {<Create {...props} resource="account-clients" redirect="false">
                <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined">
                    <TextInput source="name" label="Name" validate={[required()]} fullWidth={true} />
                    <TextInput source="website" label="Website" validate={[required()]} fullWidth={true} />
                </SimpleForm>
            </Create>

            }
        </div>
    );
}
