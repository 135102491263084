import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";


const Filters = props => (
  <Filter {...props} variant="outlined">
  <SearchInput variant="outlined" label="Corporate Code" source="code~like" alwaysOn />
  <SearchInput variant="outlined" label="Corporate Name" source="name~like" alwaysOn />
  <ReferenceInput label="Area" source="areaId" reference="areas" >
        <SelectInput optionText="name" />
    </ReferenceInput>
</Filter>
);
export const CorporateList = (props) => {
  return (
    <List {...props} title="Corporate List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        
        <TextField source="code" label="Corporate Code" />
        <TextField source="name" label="Corporate Name" />
        <ReferenceField source="areaId" reference="areas" link={false} label="Area" >
                        <TextField source="name" />
                    </ReferenceField>
      
      <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date"/>
            </Datagrid>      
    </List>
  );
};
